body {
  font-family: "Poppins";
  margin: 0px;
  padding: 0px;
  background: #f2f1f8 !important;
  scroll-behavior: smooth;
}

/* <!--------------------- welcome to Revnitro-------------------------------------> */

.comment34344434 {
  width: 16px !important;
  height: 16px !important;
  margin-left: 5px !important;
}

.welcometorevnitro {
  height: 224px;
  background: rgb(11, 0, 77);
  background: linear-gradient(
    90deg,
    rgba(11, 0, 77, 1) 0%,
    rgba(76, 0, 1, 1) 100%
  );
  display: flex;
  justify-content: center;
  align-items: center;
}
.welcometorevnitro h1 {
  font-family: Poppins;
  font-size: 60px;
  font-weight: 600;
  line-height: 90px;
  letter-spacing: 0em;
  text-align: center;
  color: #fff;
  margin-top: 0px;
  margin-bottom: 0px;
}

/* <!--------------------- welcome to Revnitro-------------------------------------> */

/* <!--------------------- Revnitro Topics-------------------------------------> */

.revnitrotopicssss {
  background-color: #fff;
  display: flex;
  justify-content: center;
  column-gap: 215px;
  margin-top: 20px;
  margin-left: 25px;
  margin-right: 25px;
  border-radius: 10px;
  height: 170px;
}

.textforumdynamic {
  font-family: Poppins;
  font-size: 29px;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: 0em;
  color: #282828;
}

.iconsflexss {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* <!--------------------- Revnitro Topics-------------------------------------> */

/* <!--------------------- input and filters-------------------------------------> */

.formflexx {
  display: flex;
}
.formsandfilters {
  margin-top: 50px;
  margin-left: 25px;
  display: flex;
}
.inputformpage form input {
  border: none;
  width: 256px;
  height: 42px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  color: #b5b0b0;
  padding-left: 15px;
}

.inputformpage form input::placeholder {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  color: #b5b0b0;
}

.searchbuttons {
  height: 44px;
  background-color: #2200ff;
  border: none;
  width: 45px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
}

label[for="favcity"] {
  position: relative;
  display: block;
  width: 240px;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  margin-left: 30px;
}

label[for="favcity"]::after {
  content: " ";
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
  height: 100%;
  display: block;
  background: #2200ff
    url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOBAMAAAGq7RFVAAAAJ1BMVEX///////////////////////////////////////////////////9Ruv0SAAAADHRSTlMACAoREoKDt7y9zc5qtZoyAAAAcklEQVQIHQXBsQnCUAAFwFOCCKbIABaWERzi1yaFAzmEpHYEC8GUKVQEkbyhvOOkZqdWt0/QYKqLzQeF7dv6y/RLwa2wYtk4Xxxe+t8yd1WuM/rcUc2gYHEeoP02umSgTR50ybBPRuiSZAS6ZAQ4jgCAPyjYJiRH4fymAAAAAElFTkSuQmCC")
    no-repeat center center;
  pointer-events: none;
}

label[for="favcity"] select {
  height: 42px;
  width: 100%;
  border: none;
  background: #2200ff;
  padding: 7px 20px 7px 20px;
  font-size: 16px;
  font-family: monospace;
  cursor: pointer;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
}

label[for="favcity"] select::-ms-expand {
  display: none;
}

label[for="favcity"] :-moz-any(select) {
  width: 110%;
}

label[for="favcity"].ie9 select {
  width: 110%;
}

label[for="favcity"] select:focus {
  outline: 1px dotted #a9a9a9;
}
.latestfilterbutton:hover {
  cursor: pointer;
  background-color: #2200ff;
  color: #fff;
}
.latestfilterbutton1:hover {
  cursor: pointer;
  background-color: #2200ff;
  color: #fff;
}
.latestfilterbutton {
  width: 127px;
  height: 42px;
  padding: 8px, 26px, 8px, 26px;
  border-radius: 10px;
  gap: 10px;
  background-color: #e1dfe8;
  border: none;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
  color: #121212;
  margin-left: 30px;
}
.latestfilterbutton1 {
  width: 167px;
  height: 42px;
  padding: 8px, 26px, 8px, 26px;
  border-radius: 10px;
  gap: 10px;
  background-color: #e1dfe8;
  border: none;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
  color: #121212;
  margin-left: 30px;
}
.latestfilterbuttonsds {
  width: 167px;
  height: 42px;
  padding: 8px, 26px, 8px, 26px;
  border-radius: 10px;
  gap: 10px;
  border: none;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
  color: #121212;
  margin-left: 30px;
  background-color: #f2f1f8;
}

/* <!--------------------- input and filters-------------------------------------> */

/* <!--------------------- index page-------------------------------------> */

.mainiofsdbfhufdibdshgdff {
  height: 32px;
}

.editimageprofilepicsabsolute {
  position: absolute;
  top: 70px;
  left: 181px;
}

.commentsendbutton {
  margin-top: 2px;
  margin-left: 2px;
}

.uploadname {
  display: flex;
  padding-left: 40px;
  align-items: center;
}

.uploadname:hover {
  cursor: pointer;
}

.uploadname img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
}

.mainiofsdbfhufdibdshgdff .NameoftheTickeruserssasd {
  width: 33px;
  height: 33px;
  object-fit: cover;
}

.uploadpersonname .NameoftheTickeruserssasd {
  width: 33px;
  height: 33px;
  object-fit: cover;
}

.uploadpersonname {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 15px;
}

.uernamepost {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #9b9a9a;
  margin-left: 15px;
  height: 34px;
}

.postedtime {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #9b9a9a;
  padding-right: 43px;
}

.mainPosterssdivvs {
  margin-bottom: 15px;
}

.personposted {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-top: 15px; */
  padding-top: 32px;
  margin-top: 0px;
}

.postimage {
  margin-left: 40px;
  margin-right: 40px;
  margin-bottom: 15px;
  margin-top: 20px;
}
.postimage img {
  width: 894px;
  height: 325px;
  object-fit: cover;
}
.postimagethumb img {
  width: 100%;
}

.postmapfunctionarea {
  display: flex;
  justify-content: center;
  column-gap: 30px;
  margin-top: 30px;
}

.leftnavbarbox {
  background-color: #ffffff;
  width: 322px;
  height: 767px;
  border-radius: 10px;
  margin-top: 15px;
  box-shadow: 0px 4px 4px 0px #0000000d;
  margin-top: 0px;
}

.rightpostbox {
  height: 120vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 20px;
  width: 951px;
  border-radius: 10px;
  border: 2px;
  background: #f2f1f8;
  scrollbar-width: none;
}
.posterss {
  width: 951px;
  border-radius: 10px;
  border: 2px;
  background-color: #ffffff;
  height: auto;
  box-shadow: 0 4px 4px 0 #0000000d;
}
/* .posterss:hover {
  cursor: pointer;
} */
.usernamenavbar {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0em;
  color: #000000;
  margin-right: 0px;
  text-align: center;
  margin-top: 23px;
}

.usernamenavbar h3 {
  margin-top: 0px;
  margin-bottom: 0px;
}

.idnamename {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
  color: #8e8e8e;
  margin-top: 4px;
}

.imageflexleftnavbar {
  display: flex;
  justify-content: center;
  flex-direction: column;
  column-gap: 20px;
  margin-top: 6px;
  align-items: center;
  position: relative;
}

.profilephotosssupate img {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.postimage:hover {
  cursor: pointer;
}
.postimagethumb img {
  width: 100%;
}
.navigatelinks {
  display: flex;
  align-items: center;
  justify-content: start;
  column-gap: 10px;
  margin-left: 34px;
  margin-top: 27px;
}

.navigatenames {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 6px;
  margin-bottom: 5px;
}
.navigatenames:hover {
  /* font-family: Poppins;
        font-size: 24px;
        font-weight: 600;
        line-height: 36px;
        letter-spacing: 0em;
        text-align: left;
        margin-left: 6px;
        margin-bottom: 5px; */
  color: #f00;
}
.navigationbuttontop {
  margin-top: 35px;
}

.navigatelinks:hover {
  cursor: pointer;
}

.postpagaediv p {
  font-family: Poppins;
  font-size: 25px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
  padding-left: 40px;
  margin-bottom: 14px;
}

.postpagaediv:hover {
  cursor: pointer;
}

.postcontent {
  overflow: hidden;
  word-wrap: break-word;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
  padding-left: 40px;
  padding-right: 40px;
}
.postimagethumb img {
  width: 100%;
}

.hastagtext {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: #2200ff;
  font-weight: 600;
  padding-left: 40px;
  margin-top: 0px;
}

.reactionbuttons {
  display: flex;
  padding-left: 40px;
  margin-top: 15px;
}
.reactionbutNeww {
  display: flex;
  padding-left: 40px;
  margin-top: 15px;
}

.likeflex {
  display: flex;
  justify-content: center;
  column-gap: 5px;
}

.commentsflex {
  display: flex;
  justify-content: center;
  column-gap: 5px;
  margin-left: 35px;
}

.shareflex {
  display: flex;
  justify-content: center;
  column-gap: 5px;
  margin-left: 35px;
}

.sharefonts {
  font-family: Poppins;
  font-size: 17px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.sharefonts:hover {
  color: #ea0c0b;
}
.commentsshowmoree button:hover {
  background-color: red;
  cursor: pointer;
}
.linebottom {
  border: 1px solid #c6c6c6;
  margin-left: 40px;
  margin-right: 50px;
  margin-top: 15px;
}

.cinputflex {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  column-gap: 10px;
  margin-top: 20px;
  padding-bottom: 25px;
}

.commentbox {
  display: flex;
  align-items: center;
}

.commentbox input {
  width: 755px;
  height: 43px;
  border-radius: 10px;
  background-color: #f2f2f2;
  border: none;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  padding-left: 20px;
  color: #000;
}
.poatspagecommentsectionimage img {
  width: 43px;
  height: 43px;
  object-fit: cover;
  border-radius: 50%;
}

.lastcommentpicc img {
  width: 43px;
  height: 43px;
  object-fit: cover;
  border-radius: 50%;
}
.commentbox input::placeholder {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #969595;
}
.commentbox button {
  width: 45px;
  height: 43px;
  border-radius: 10px;
  background-color: #2200ff;
  border: none;
  margin-left: 8px;
}

.commentshowsection {
  width: 900px;
  background-color: #fff;
  margin-top: 20px;
  padding-left: 25px;
  padding-right: 25px;
  border-radius: 10px;
  padding-bottom: 30px;
  font-size: 18px !important;
}

.commentsshowmoree {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.commentsshowmoree button {
  width: 122px;
  height: 33px;
  font-size: 16px;
  background: #2200ff;
  border: none;
  border-radius: 5px;
  color: #fff;
  font-weight: 500;
}
.maincomment {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.commentingtime {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #9b9a9a;
}

.usercomment {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
  margin-top: 20px;
  word-wrap: break-word;
}
.commentingarea {
  background-color: #f2f2f2;
  padding: 30px;
  width: 840px;
  border-radius: 10px;
  margin-top: 25px;
  padding-top: 20px;
}
.imageuserforum {
  display: flex;
  justify-content: center;
  column-gap: 15px;
}

.imageuserforum img {
  width: 48px;
  height: 48px;
  object-fit: cover;
  border-radius: 50%;
  margin-top: 5px;
}
.forumcommentuser {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
}

imageuserforum .forumusernameres {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #9b9a9a;
}
.noofcomments {
  font-family: Poppins;
  font-size: 21px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
  padding-left: 10px;
  padding-top: 30px;
}
#heartcoulour {
  font-size: 22px;
  color: #cdcdcd;
  padding-right: 6px;
  margin-top: 1px;
}
/* <!--------------------- index page-------------------------------------> */

/* <!---------------------postpage-------------------------------------> */
.backbuttonnnewpagess {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  column-gap: 9px;
}

.backbuttontext {
  font-family: Poppins;
  font-size: 25px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 2px;
}

.pagesspagemargintop {
  margin-top: 15px;
}

.postpagedeleteandedit {
  display: flex;
  justify-content: center;
  column-gap: 24px;
  align-items: center;
}
/* <!---------------------postpage-------------------------------------> */

/* <!---------------------Welcome to Forum-------------------------------------> */
.forumone {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 43px;
  padding-right: 43px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid #d2d2d2;
  border-left: 1px solid #d2d2d2;
  border-right: 1px solid #d2d2d2;
}

.maindivuserslist {
  overflow: scroll;
  height: 100vh;
  margin-left: 56px;
  margin-right: 56px;
  margin-top: 47px;
}

.forumoneheading {
  display: flex;
  justify-content: space-between;
  background-color: #e1dfe8;
  border-bottom: 1px solid #d2d2d2;
  border-top: 1px solid #e1dfe8;
}

.firstrowheading {
  display: flex;
  justify-content: flex-start;
  width: 160px;
  margin-top: 20px;
  margin-bottom: 20px;
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  padding-left: 25px;
  padding-right: 25px;
}
.firstrow {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 160px;
  margin-top: 20px;
  margin-bottom: 20px;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
}

.rightwelcometoforum {
  width: 951px;
  border-radius: 10px;
  border: 2px;
  background: #fff;
  margin-top: 0px;
  padding-bottom: 50px;
  box-shadow: 0px 4px 4px 0px #0000000d;
}

.dateofpost {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #bcbcbc;
}

.postviewdivflex {
  display: flex;
  justify-content: center;
  column-gap: 20px;
  align-items: center;
}
/* <!---------------------Welcome to Forum-------------------------------------> */

/* <!---------------------CreateYourPost-------------------------------------> */
.file-input__input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.file-input__label {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  font-size: 14px;
  padding: 10px 12px;
  background-color: #eeedf2;
  top: 399px;
  left: 321px;
  height: 17px;
  width: 149px;
}

.file-input__label svg {
  height: 16px;
  margin-right: 4px;
}

.uploadimagecreatepost {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #2200ff;
  margin-left: 5px;
  margin-top: 2px;
}

.CreateYourPost {
  font-family: Poppins;
  font-size: 32px;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
  margin-left: 30px;
}

.createpostfunction {
  display: flex;
  justify-content: center;
  column-gap: 30px;
  margin-top: 30px;
}

.rightcreatepost {
  width: 951px;
  border-radius: 10px;
  border: 2px;
  background: #f2f1f8;
}

.createpostdiv {
  width: 951px;
  border-radius: 10px;
  border: 2px;
  background-color: #fff;
  height: auto;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
}

label[for="createpost"] {
  position: relative;
  display: block;
  width: 586px;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  background-color: #eeedf2;
}

label[for="createpost"]::after {
  content: " ";
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
  height: 100%;
  display: block;
  font-family: Poppins;
  background: #eeedf2
    url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOBAMAAAGq7RFVAAAAJ1BMVEX///////////////////////////////////////////////////9Ruv0SAAAADHRSTlMACAoREoKDt7y9zc5qtZoyAAAAcklEQVQIHQXBsQnCUAAFwFOCCKbIABaWERzi1yaFAzmEpHYEC8GUKVQEkbyhvOOkZqdWt0/QYKqLzQeF7dv6y/RLwa2wYtk4Xxxe+t8yd1WuM/rcUc2gYHEeoP02umSgTR50ybBPRuiSZAS6ZAQ4jgCAPyjYJiRH4fymAAAAAElFTkSuQmCC")
    no-repeat center center;
  pointer-events: none;
  color: black;
}

label[for="createpost"] select {
  height: 43px;
  width: 100%;
  border: none;
  background: #eeedf2;
  padding: 7px 20px 7px 20px;
  font-size: 16px;
  font-family: monospace;
  cursor: pointer;
  font-family: Poppins;
}

label[for="createpost"] select::-ms-expand {
  display: none;
}

label[for="createpost"] :-moz-any(select) {
  width: 110%;
}

label[for="createpost"].ie9 select {
  width: 110%;
}

label[for="createpost"] select:focus {
  outline: 1px dotted #a9a9a9;
}

.cforumtopics {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 50px;
  padding-left: 105px;
  padding-right: 70px;
  margin-top: 30px;
}

.cheadingtopics {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 50px;
  padding-left: 105px;
  padding-right: 70px;
  margin-top: 30px;
}
.ctextareapostcreate {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  column-gap: 50px;
  padding-left: 105px;
  padding-right: 70px;
  margin-top: 30px;
  position: relative;
}
.cinputforumcreatepost input {
  width: 562px;
  height: 43px;
  border-radius: 10px;
  background-color: #eeedf2;
  border: none;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  padding-left: 20px;
  color: #000000;
}
.cinputforumcreatepost input::placeholder {
  color: #000000;
}

.textareacreatepost textarea {
  width: 562px;
  height: 297px;
  background-color: #eeedf2;
  border: none;
  border-radius: 10px;
}

.headingcreatepost {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
}
.textareacreatepost textarea {
  font-family: Poppins;
  padding-left: 20px;
  padding-top: 15px;
  font-size: 16px;
}

.photouploadsimagers {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
  background-color: #ffffff;
  border: none;
  width: 88px;
  height: 33px;
  border-radius: 7px;
  position: absolute;
  top: 411px;
  left: 319px;
}
#file {
  display: none;
}
.formuploadflex {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 5px;
}

.Deleteofthihbsvddg {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 3px;
  margin-left: 28px;
  margin-top: 10px;
}

.deletebuttifdgbshgfdhss {
  height: 21px;
}

.DeleteImageprofilejbhdf {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  color: #ff0000;
}

.uploadtexts {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #2200ff;
}
.postionofimage {
  position: relative;
}

.buttonsubmit button {
  width: 173px;
  height: 40px;
  background-color: #2200ff;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  border: none;
  text-align: center;
  color: #ffffff;
  border-radius: 10px;
  margin-left: 298px;
  margin-top: 30px;
}
.buttonsubmit {
  padding-bottom: 50px;
}

.fileuploadareainput {
  background-color: transparent;
  border: none;
}

/*-----------Recently Added--------------*/

.previewwimagesizee {
  width: 200px;
  height: 78px;
}
.recommenededsizees {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #8c8484;
  margin-top: 10px;
}

.thumbnailtextscreatepost {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
}

.thumbnailsecrionforcreatepost {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  column-gap: 50px;
  padding-left: 105px;
  padding-right: 254px;
  margin-top: 30px;
}

.thumbnailsecrionforcreatepost11 {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  column-gap: 50px;
  padding-left: 105px;
  padding-right: 435px;
  margin-top: 30px;
}

.imagethumbnailpreviewdivtag {
  display: flex;
  justify-content: center;
  column-gap: 20px;
  align-items: flex-start;
}

.recomennededsizeforphoto {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #8c8484;
  margin-left: 303px;
  margin-top: 10px;
}

.breiefdescriptionnamee {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
}

.breifdescriptionzsflex {
  display: flex;
  padding-left: 105px;
  margin-top: 30px;
}

.breifedescriptiontrextareabox {
  padding-left: 35px;
}
.breifedescriptiontrextareabox textarea {
  width: 573px;
  background-color: #eeedf2;
  border: none;
  border-radius: 10px;
  height: 180px;
  padding: 10px;
  padding-left: 20px;
  color: #9d9d9d;
  font-size: 20px;
}

.breifedescriptiontrextareabox textarea::placeholder {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
}

/*-----------Recently Added--------------*/

/* <!---------------------CreateYourPost-------------------------------------> */

/* <!---------------------Login Page-------------------------------------> */

.loginpageuserididv input {
  width: 600px;
  height: 46px;
  background-color: #eeedf2;
  border: none;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  color: #000;
  padding-left: 20px;
  border-radius: 7px;
  margin-left: 165px;
  margin-top: 40px;
}

.loginpageuserididv input::placeholder {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  color: #a3a2a2;
}

.loginpagepassworddiv input {
  width: 600px;
  height: 46px;
  background-color: #eeedf2;
  border: none;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  color: #000;
  padding-left: 20px;
  border-radius: 7px;
  margin-top: 40px;
  margin-left: 165px;
}

.loginpagepassworddiv input::placeholder {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  color: #a3a2a2;
}

.loginbuttonpagediv {
  width: 623px;
  height: 46px;
  background-color: #011ae3;
  color: #fff;
  border: none;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
  border-radius: 7px;
  margin-top: 40px;
  margin-left: 165px;
}

.gggedindeatilstext {
  font-family: Poppins;
  font-size: 30px;
  font-weight: 500;
  line-height: 45px;
  letter-spacing: 0em;
  text-align: left;
  color: #282828;
  margin-left: 335px;
  margin-top: 0px;
  padding-top: 56px;
}

.loginpagessforgotpasswordtext {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  color: #282828;
  margin-left: 404px;
  margin-top: 42px;
}

.donthaveaxxcountpoassword {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 298px;
  margin-top: 30px;
}

.donthaveaxxcountpoassword span {
  color: #ea0c0b;
}

.registerhere:hover {
  cursor: pointer;
}

.formwidthpaddings {
  padding-bottom: 40px;
}

.registerpageidalreadytaken {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #f44336;
  margin-left: 169px;
  margin-top: 10px;
}

/*---OTP Div---*/
.form-control--otp {
  line-height: 2.5;
  height: 3.5rem;
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  padding-left: 2px;
  padding-right: 2px;
  width: 60px;
  height: 60px;
}

@media (min-width: 375px) {
  .comment34344434 {
    width: 13px !important;
    height: 12px !important;
    margin-left: 3px !important;
  }
  .form-control--otp {
    line-height: 3;
    height: 4.5rem;
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    max-width: 3.5rem;
    width: 60px;
    height: 60px;
    border: none;
    background-color: #eeedf2;
    border-radius: 5px;
    margin-left: 10px;
    margin-right: 10px;
  }
}

.input-group-seperator {
  width: 2rem;
  margin-left: 0.75rem;
  margin-right: 0.75rem;
  height: 0.3rem;
  background: #ced4da;
  border-radius: 0.1rem;
}

.otpdivmarginleft {
  margin-top: 78px;
  margin-left: 224px;
}

.enterotp {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  color: #a3a2a2;
  margin-left: 434px;
  margin-top: 18px;
}

/*---OTP Div---*/

/* <!---------------------Login Page-------------------------------------> */

/* <!--------------------My Profile-------------------------------------> */

.myprofilephotouploaddiv img {
  width: 175px;
  height: 175px;
  object-fit: cover;
  border-radius: 50%;
}
.myprofilefile-input__input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
  top: 0px;
}

.myprofilefile-input__label {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  font-size: 14px;
  background-color: #eeedf2;
  top: 399px;
  left: 321px;
  height: 51px;
  width: 39px;
  padding-left: 11px;
  border-radius: 50px;
  border: 5px solid white;
}

.myprofilefile-input__label svg {
  height: 16px;
  margin-right: 4px;
}

.profilephototattachdiv {
  position: relative;
}

.postionabsoluteofprofile {
  position: absolute;
  top: 106px;
  left: 134px;
}

.profilephototattachdiv {
  margin-left: 404px;
}

.myprofileidalreadytaken {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #f44336;
  margin-left: 271px;
  margin-top: 8px;
}

.updatechangesbuttondiv {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  width: 581px;
  background-color: #011ae3;
  border: none;
  height: 46px;
  border-radius: 7px;
  margin-left: 270px;
  margin-top: 21px;
}
.myprofileforogtopassword {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
  margin-left: 173px;
  margin-top: 20px;
}

.myprofilepagerightsidediv {
  padding-bottom: 100px;
  padding-top: 45px;
  margin-top: 0px;
}

.myheadingtopics {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 0px;
  padding-left: 98px;
  padding-right: 101px;
  margin-top: 30px;
}
/* <!--------------------My Profile-------------------------------------> */

/*-------------------------Notification------------------------------*/

.notificationnumberofmessage {
  background-color: #ff0000;
  width: 19px;
  height: 19px;
  border-radius: 50%;
  color: #fff;
  font-family: Poppins;
  font-size: 10px;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: 0em;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -2px;
  border: 4px solid #fff;
  left: 277px;
}
.notificationareapostion {
  position: relative;
  display: flex;
  justify-content: end;
  margin-right: 30px;
  margin-top: 30px;
}

.notificationareapostion:hover {
  cursor: pointer;
}

.notoficationflexx {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 24px;
  margin-left: 27px;
  margin-right: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
}

.commentedtextss {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
}
.notificationpagedivv {
  background-color: #fff;
  border-radius: 7px;
  box-shadow: 0px 4px 4px 0px #0000000d;
  margin-top: 14px;
  position: relative;
}

.imageofthenotifier img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.timeofthecommented {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  color: #8c8c8c;
  margin-left: 10px;
}

.foruumstopicsss {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  color: #2200ff;
}

.seenornotdiv {
  width: 11px;
  height: 11px;
  background-color: #ff0000;
  border-radius: 50%;
  position: absolute;
  top: 17px;
  left: 923px;
}

/*-------------------------Notification------------------------------*/

/*----------------------------------------------------------------------------------------------------
      ----------------------------------------------Media Queries-------------------------------------------
      --------------------------------------------------------------------------------------------------*/

/* <!--------------------- Index page (Media Query)-------------------------------------> */

/* <!-----Main Index Page-----------------> */
@media (max-width: 358px) {
  .comment34344434 {
    width: 13px !important;
    height: 12px !important;
    margin-left: 3px !important;
  }
  .mainiofsdbfhufdibdshgdff {
    height: 18px;
  }
  .NameoftheTickeruserssasd {
    width: 20px !important;
    height: 20px !important;
  }
  [type="checkbox"]:checked,
  [type="checkbox"]:not(:checked) {
    position: absolute;
    left: -9999px;
    opacity: 0;
    pointer-events: none;
  }
  .indexpagemediaqueryflex {
    display: flex;
    justify-content: center;
  }
  .mediaqueryindex {
    width: 320px;
    border: none;
  }

  /*-------------------------Notification------------------------------*/

  .notificationnumberofmessage {
    background-color: #ff0000;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    color: #fff;
    font-family: Poppins;
    font-size: 10px;
    font-weight: 600;
    line-height: 15px;
    letter-spacing: 0em;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 75px;
    border: 4px solid #fff;
    left: 258px;
  }
  .notificationareapostion {
    position: relative;
    display: flex;
    justify-content: end;
    margin-right: 30px;
    margin-top: 30px;
  }

  .notificationareapostion:hover {
    cursor: pointer;
  }

  .notoficationflexx {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    column-gap: 14px;
    margin-left: 10px;
    margin-right: 10px;
    padding-top: 15px;
    padding-bottom: 1px;
  }

  .imageofthenotifier img {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    object-fit: cover;
  }
  .commentedtextss {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
  }
  .notificationpagedivv {
    background-color: #fff;
    border-radius: 7px;
    box-shadow: 0px 4px 4px 0px #0000000d;
    margin-top: 14px;
    position: relative;
    margin-left: 7px;
    margin-right: 7px;
  }

  .timeofthecommented {
    font-family: Poppins;
    font-size: 10px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0em;
    color: #8c8c8c;
    margin-left: 1px;
  }

  .foruumstopicsss {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    color: #2200ff;
  }

  .seenornotdiv {
    width: 8px;
    height: 8px;
    background-color: #ff0000;
    border-radius: 50%;
    position: absolute;
    top: 19px;
    left: 279px;
  }
  .deleteoptionimage {
    margin-top: 54px;
  }
  .deleteoptionimage img {
    width: 24px;
  }
  /*-----------Notification------------------------*/
  /* <!--------- index page----------------------> */

  .mobileelastestbuttonflexoption {
    display: flex;
    align-items: baseline;
    justify-content: end;
    column-gap: 19px;
    margin-right: 24px;
  }

  #heartcoulour {
    font-size: 18px;
    color: #cdcdcd;
    padding-right: 2px;
    margin-top: 0px;
  }

  .lastcommentpicc img {
    width: 28px;
    height: 28px;
    object-fit: cover;
  }

  .uploadname img {
    width: 32px;
    height: 32px;
    object-fit: contain;
  }

  .editimageprofilepicsabsolute {
    position: absolute;
    top: 70px;
    left: 181px;
  }

  .commentsendbutton {
    margin-top: 3px;
    margin-left: 0px;
    width: 20px;
  }

  .uploadname {
    display: flex;
    padding-left: 20px;
  }

  .Uplaodnamelenhdtgjhfhjbfdn {
    height: 32px;
  }

  .uploadpersonname {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: 10px;
  }

  .uernamepost {
    font-family: Poppins;
    font-size: 10px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: #9b9a9a;
    margin-left: 10px;
    height: 16px;
  }

  .postedtime {
    font-family: Poppins;
    font-size: 10px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: #9b9a9a;
    padding-right: 23px;
  }

  .personposted {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    padding-top: 32px;
  }

  .postimage {
    margin-left: 19px;
    margin-right: 0px;
    margin-bottom: 15px;
    margin-top: 20px;
  }
  .postimage img {
    width: 281px;
    height: 120px;
    object-fit: cover;
  }

  .postmapfunctionarea {
    display: flex;
    justify-content: center;
    column-gap: 30px;
    margin-top: 30px;
  }

  .leftnavbarbox {
    background-color: #ffffff;
    width: 322px;
    height: 746px;
    border-radius: 10px;
    margin-top: 15px;
    box-shadow: 0px 4px 4px 0px #0000000d;
  }

  .rightpostbox {
    width: 951px;
    border-radius: 10px;
    border: 2px;
    background: #f2f1f8;
    box-shadow: 0 4px 4px 0 #0000000d;
  }
  .posterss {
    width: 320px;
    border-radius: 5px;
    border: 2px;
    background-color: #ffffff;
    height: auto;
  }

  .usernamenavbar {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0em;
    color: #000000;
    margin-right: 0px;
    text-align: center;
    margin-top: 0px;
  }

  .usernamenavbar h3 {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .idnamename {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
    color: #8e8e8e;
    margin-top: 4px;
  }

  .imageflexleftnavbar {
    display: flex;
    justify-content: center;
    flex-direction: column;
    column-gap: 20px;
    margin-top: 6px;
    align-items: center;
    position: relative;
  }

  .profilephotosssupate img {
    border-radius: 50%;
    display: flex;
    justify-content: center;
  }

  .navigatelinks {
    display: flex;
    align-items: center;
    justify-content: start;
    column-gap: 10px;
    margin-left: 34px;
    margin-top: 27px;
  }

  .navigatenames {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: 6px;
    margin-bottom: 5px;
  }
  .navigatenames:hover {
    /* font-family: Poppins;
        font-size: 24px;
        font-weight: 600;
        line-height: 36px;
        letter-spacing: 0em;
        text-align: left;
        margin-left: 6px;
        margin-bottom: 5px; */
    color: #f00;
  }
  .navigationbuttontop {
    margin-top: 35px;
  }

  .navigatelinks:hover {
    cursor: pointer;
  }

  .postpagaediv p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    padding-left: 20px;
    margin-bottom: 14px;
  }

  .postcontent {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    padding-left: 20px;
    padding-right: 16px;
  }

  .hastagtext {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #2200ff;
    font-weight: 600;
    padding-left: 20px;
    margin-top: 7px;
  }

  .reactionbuttons {
    display: flex;
    padding-left: 20px;
    margin-top: 10px;
  }

  .reactionbutNeww {
    display: flex;
    padding-left: 20px;
    margin-top: 10px;
  }

  .likeflex {
    display: flex;
    justify-content: center;
    column-gap: 5px;
  }

  .commentsflex {
    display: flex;
    justify-content: center;
    column-gap: 5px;
    margin-left: 10px;
    align-items: flex-start;
  }

  .shareflex {
    display: flex;
    justify-content: center;
    column-gap: 5px;
    margin-left: 10px;
  }

  .sharefonts {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }

  .linebottom {
    border: 1px solid #c6c6c6;
    margin-left: 20px;
    margin-right: 19px;
    margin-top: 10px;
  }

  .cinputflex {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    column-gap: 5px;
    margin-top: 23px;
    padding-bottom: 25px;
  }

  .commentbox {
    display: flex;
    align-items: center;
  }

  .commentbox input {
    width: 209px;
    height: 28px;
    border-radius: 5px;
    background-color: #f2f2f2;
    border: none;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    padding-left: 5px;
    color: #969595;
  }
  .commentbox input::placeholder {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #969595;
  }
  .commentbox button {
    width: 29px;
    height: 29px;
    border-radius: 5px;
    background-color: #2200ff;
    border: none;
    margin-left: 5px;
  }

  .commentshowsection {
    width: 300px;
    background-color: #fff;
    margin-top: 15px;
    padding-left: 8px;
    padding-right: 5px;
    border-radius: 10px;
    padding-bottom: 30px;
  }

  .commentsshowmoree {
    width: 300px;
    display: flex;
    justify-content: center;
    margin-top: 22px;
  }
  .commentsshowmoree button {
    width: 78px;
    height: 22px;
    font-size: 10px;
    background: #2200ff;
    border: none;
    border-radius: 3px;
    color: #fff;
    font-weight: 500;
    line-height: 14px;
  }
  .maincomment {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .commentingtime {
    font-family: Poppins;
    font-size: 10px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: #9b9a9a;
  }

  .usercomment {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    margin-top: 13px;
  }
  .commentingarea {
    width: 273px;
    background-color: #f2f2f2;
    border-radius: 5px;
    margin-top: 13px;
    padding-top: 19px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
  }
  .imageuserforum {
    display: flex;
    justify-content: center;
    column-gap: 7px;
    align-items: flex-start;
  }

  .imageuserforum img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-top: 0px;
    object-fit: contain;
  }
  .forumcommentuser {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
  }
  .forumusernameres {
    font-family: Poppins;
    font-size: 10px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: #9b9a9a;
  }
  .noofcomments {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    padding-left: 10px;
    padding-top: 15px;
  }
  /* <!--------- index page----------------------> */

  /* <!------- input and filters------------> */
  .formflexx {
    display: flex;
  }
  .formsandfilters {
    margin-top: 23px;
    margin-left: 7px;
    display: flex;
    flex-direction: column;
  }
  .inputformpage form input {
    border: none;
    width: 256px;
    height: 30px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #b5b0b0;
    padding-left: 15px;
  }

  .inputformpage form input::placeholder {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #b5b0b0;
  }

  .searchbuttons {
    height: 30px;
    background-color: #2200ff;
    border: none;
    width: 30px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  .searchbuttons img {
    width: 16px;
  }
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0;
  }

  label[for="favcity"] {
    position: relative;
    display: block;
    width: 136px;
    height: 28px;
    border-radius: 5px;
    overflow: hidden;
    cursor: pointer;
    margin-left: 0px;
    margin-top: 20px;
  }

  label[for="favcity"]::after {
    content: " ";
    position: absolute;
    right: 0;
    top: 0;
    width: 29px;
    height: 29px;
    display: block;
    background: #2200ff
      url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOBAMAAAGq7RFVAAAAJ1BMVEX///////////////////////////////////////////////////9Ruv0SAAAADHRSTlMACAoREoKDt7y9zc5qtZoyAAAAcklEQVQIHQXBsQnCUAAFwFOCCKbIABaWERzi1yaFAzmEpHYEC8GUKVQEkbyhvOOkZqdWt0/QYKqLzQeF7dv6y/RLwa2wYtk4Xxxe+t8yd1WuM/rcUc2gYHEeoP02umSgTR50ybBPRuiSZAS6ZAQ4jgCAPyjYJiRH4fymAAAAAElFTkSuQmCC")
      no-repeat center center;
    pointer-events: none;
  }

  label[for="favcity"] select {
    height: 42px;
    width: 100%;
    border: none;
    background: #fff;
    padding: 1px 6px 14px 10px;
    font-size: 16px;
    font-family: monospace;
    cursor: pointer;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
  }

  label[for="favcity"] select::-ms-expand {
    display: none;
  }

  label[for="favcity"] :-moz-any(select) {
    width: 110%;
  }

  label[for="favcity"].ie9 select {
    width: 110%;
  }

  label[for="favcity"] select:focus {
    outline: 1px dotted #a9a9a9;
  }

  .latestfilterbutton {
    display: none;
    width: 127px;
    height: 42px;
    padding: 8px, 26px, 8px, 26px;
    border-radius: 10px;
    gap: 10px;
    background-color: #e1dfe8;
    border: none;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
    color: #121212;
    margin-left: 30px;
  }
  .latestfilterbutton1 {
    display: none;
    width: 167px;
    height: 42px;
    padding: 8px, 26px, 8px, 26px;
    border-radius: 10px;
    gap: 10px;
    background-color: #e1dfe8;
    border: none;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
    color: #121212;
    margin-left: 30px;
  }
  .latestfilterbuttonsds {
    display: none;
    width: 167px;
    height: 42px;
    padding: 8px, 26px, 8px, 26px;
    border-radius: 10px;
    gap: 10px;
    border: none;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
    color: #121212;
    margin-left: 30px;
    background-color: #f2f1f8;
  }
  /* <!------- input and filters------------> */

  /* <!------------- welcome to Revnitro-----------------------> */
  .welcometorevnitro {
    height: 85px;
    background: rgb(11, 0, 77);
    background: linear-gradient(
      90deg,
      rgba(11, 0, 77, 1) 0%,
      rgba(76, 0, 1, 1) 100%
    );
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .welcometorevnitro h1 {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
    color: #fff;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  /* <!------------- welcome to Revnitro-----------------------> */
  /* <!------- Revnitro Topics------------------------> */
  .iconsflexss img {
    width: 25px;
    height: 25px;
    object-fit: cover;
  }

  .revnitrotopicssss {
    background-color: #fff;
    display: flex;
    justify-content: center;
    column-gap: 50px;
    margin-top: 8px;
    margin-left: 0px;
    margin-right: 0px;
    border-radius: 5px;
    height: 70px;
  }

  .textforumdynamic {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0em;
    color: #282828;
  }

  .iconsflexss {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  /* <!------- Revnitro Topics------------------------> */

  .rightpostbox {
    height: auto !important;
    padding-right: 0px !important;
    width: 951px;
    border-radius: 10px;
    border: 2px;
    background: #f2f1f8;
  }
}
@media (min-width: 359px) and (max-width: 700px) {
  [type="checkbox"]:checked,
  [type="checkbox"]:not(:checked) {
    position: absolute;
    left: -9999px;
    opacity: 0;
    pointer-events: none;
  }
  .indexpagemediaqueryflex {
    display: flex;
    justify-content: center;
  }
  .mediaqueryindex {
    width: 360px;
    border: none;
  }

  /*-------------------------Notification------------------------------*/

  .notificationnumberofmessage {
    background-color: #ff0000;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    color: #fff;
    font-family: Poppins;
    font-size: 10px;
    font-weight: 600;
    line-height: 15px;
    letter-spacing: 0em;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 76px;
    border: 4px solid #fff;
    left: 294px;
  }
  .notificationareapostion {
    position: relative;
    display: flex;
    justify-content: end;
    margin-right: 30px;
    margin-top: 30px;
  }

  .notificationareapostion:hover {
    cursor: pointer;
  }

  .notoficationflexx {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    column-gap: 14px;
    margin-left: 10px;
    margin-right: 10px;
    padding-top: 15px;
    padding-bottom: 1px;
  }

  .imageofthenotifier img {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    object-fit: cover;
  }
  .commentedtextss {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
  }
  .notificationpagedivv {
    background-color: #fff;
    border-radius: 7px;
    box-shadow: 0px 4px 4px 0px #0000000d;
    margin-top: 14px;
    position: relative;
    margin-left: 7px;
    margin-right: 7px;
  }

  .timeofthecommented {
    font-family: Poppins;
    font-size: 10px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0em;
    color: #8c8c8c;
    margin-left: 1px;
  }

  .foruumstopicsss {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    color: #2200ff;
  }

  .seenornotdiv {
    width: 8px;
    height: 8px;
    background-color: #ff0000;
    border-radius: 50%;
    position: absolute;
    top: 19px;
    left: 319px;
  }
  .deleteoptionimage {
    margin-top: 54px;
  }
  .deleteoptionimage img {
    width: 24px;
  }
  /*-----------Notification------------------------*/
  /* <!--------- index page----------------------> */

  .mobileelastestbuttonflexoption {
    display: flex;
    align-items: baseline;
    justify-content: end;
    column-gap: 19px;
    margin-right: 24px;
  }

  #heartcoulour {
    font-size: 18px;
    color: #cdcdcd;
    padding-right: 2px;
    margin-top: 0px;
  }

  .lastcommentpicc img {
    width: 28px;
    height: 28px;
    object-fit: cover;
  }

  .uploadname img {
    width: 32px;
    height: 32px;
    object-fit: contain;
  }

  .editimageprofilepicsabsolute {
    position: absolute;
    top: 70px;
    left: 181px;
  }

  .commentsendbutton {
    margin-top: 3px;
    margin-left: 0px;
    width: 20px;
  }

  .uploadname {
    display: flex;
    padding-left: 20px;
  }

  .Uplaodnamelenhdtgjhfhjbfdn {
    height: 32px;
  }

  .uploadpersonname {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: 10px;
  }

  .uernamepost {
    font-family: Poppins;
    font-size: 10px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: #9b9a9a;
    margin-left: 10px;
    height: 16px;
  }

  .NameoftheTickeruserssasd {
    width: 20px !important;
    height: 20px !important;
  }

  .mainiofsdbfhufdibdshgdff {
    height: 18px;
  }

  .postedtime {
    font-family: Poppins;
    font-size: 10px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: #9b9a9a;
    padding-right: 23px;
  }

  .personposted {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    padding-top: 32px;
  }

  .postimage {
    margin-left: 10px;
    margin-right: 9px;
    margin-bottom: 15px;
    margin-top: 15px;
  }
  .postimage img {
    width: 333px;
    height: 165px;
    object-fit: cover;
  }

  .postmapfunctionarea {
    display: flex;
    justify-content: center;
    column-gap: 30px;
    margin-top: 30px;
  }

  .leftnavbarbox {
    background-color: #ffffff;
    width: 322px;
    height: 746px;
    border-radius: 10px;
    margin-top: 15px;
    box-shadow: 0px 4px 4px 0px #0000000d;
  }

  .rightpostbox {
    width: 951px;
    border-radius: 10px;
    border: 2px;
    background: #f2f1f8;
    box-shadow: 0 4px 4px 0 #0000000d;
  }
  .posterss {
    width: 350px;
    border-radius: 5px;
    border: 2px;
    background-color: #ffffff;
    height: auto;
  }

  .usernamenavbar {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0em;
    color: #000000;
    margin-right: 0px;
    text-align: center;
    margin-top: 0px;
  }

  .usernamenavbar h3 {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .idnamename {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
    color: #8e8e8e;
    margin-top: 4px;
  }

  .imageflexleftnavbar {
    display: flex;
    justify-content: center;
    flex-direction: column;
    column-gap: 20px;
    margin-top: 6px;
    align-items: center;
    position: relative;
  }

  .profilephotosssupate img {
    border-radius: 50%;
    display: flex;
    justify-content: center;
  }

  .navigatelinks {
    display: flex;
    align-items: center;
    justify-content: start;
    column-gap: 10px;
    margin-left: 34px;
    margin-top: 27px;
  }

  .navigatenames {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: 6px;
    margin-bottom: 5px;
  }
  .navigatenames:hover {
    /* font-family: Poppins;
        font-size: 24px;
        font-weight: 600;
        line-height: 36px;
        letter-spacing: 0em;
        text-align: left;
        margin-left: 6px;
        margin-bottom: 5px; */
    color: #f00;
  }
  .navigationbuttontop {
    margin-top: 35px;
  }

  .navigatelinks:hover {
    cursor: pointer;
  }

  .postpagaediv p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    padding-left: 20px;
    margin-bottom: 14px;
  }

  .postcontent {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    padding-left: 20px;
    padding-right: 16px;
  }

  .hastagtext {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #2200ff;
    font-weight: 600;
    padding-left: 20px;
    margin-top: 7px;
  }

  .reactionbuttons {
    display: flex;
    padding-left: 20px;
    margin-top: 10px;
  }

  .reactionbutNeww {
    display: flex;
    padding-left: 20px;
    margin-top: 10px;
  }

  .likeflex {
    display: flex;
    justify-content: center;
    column-gap: 5px;
  }

  .commentsflex {
    display: flex;
    justify-content: center;
    column-gap: 5px;
    margin-left: 10px;
    align-items: flex-start;
  }

  .shareflex {
    display: flex;
    justify-content: center;
    column-gap: 5px;
    margin-left: 10px;
  }

  .sharefonts {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }

  .linebottom {
    border: 1px solid #c6c6c6;
    margin-left: 20px;
    margin-right: 19px;
    margin-top: 10px;
  }

  .cinputflex {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    column-gap: 5px;
    margin-top: 23px;
    padding-bottom: 25px;
  }

  .commentbox {
    display: flex;
    align-items: center;
  }

  .commentbox input {
    width: 226px;
    height: 30px;
    border-radius: 5px;
    background-color: #f2f2f2;
    border: none;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    padding-left: 5px;
    color: #969595;
  }
  .commentbox input::placeholder {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #969595;
  }
  .commentbox button {
    width: 31px;
    height: 32px;
    border-radius: 8px;
    background-color: #2200ff;
    border: none;
    margin-left: 5px;
  }

  .commentshowsection {
    width: 338px;
    background-color: #fff;
    margin-top: 15px;
    padding-left: 8px;
    padding-right: 5px;
    border-radius: 10px;
    padding-bottom: 30px;
  }

  .commentsshowmoree {
    width: 350px;
    display: flex;
    justify-content: center;
    margin-top: 22px;
  }
  .commentsshowmoree button {
    width: 78px;
    height: 22px;
    font-size: 10px;
    background: #2200ff;
    border: none;
    border-radius: 3px;
    color: #fff;
    font-weight: 500;
    line-height: 14px;
  }
  .maincomment {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .commentingtime {
    font-family: Poppins;
    font-size: 10px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: #9b9a9a;
  }

  .usercomment {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    margin-top: 13px;
  }
  .commentingarea {
    width: 304px;
    background-color: #f2f2f2;
    border-radius: 5px;
    margin-top: 13px;
    padding-top: 19px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 26px;
  }
  .imageuserforum {
    display: flex;
    justify-content: center;
    column-gap: 7px;
  }

  .imageuserforum img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-top: 0px;
    object-fit: contain;
  }
  .forumcommentuser {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
  }
  .forumusernameres {
    font-family: Poppins;
    font-size: 10px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: #9b9a9a;
  }
  .noofcomments {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    padding-left: 10px;
    padding-top: 15px;
  }
  /* <!--------- index page----------------------> */

  /* <!------- input and filters------------> */
  .formflexx {
    display: flex;
  }
  .formsandfilters {
    margin-top: 23px;
    margin-left: 7px;
    display: flex;
    flex-direction: column;
  }
  .inputformpage form input {
    border: none;
    width: 292px;
    height: 35px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #b5b0b0;
    padding-left: 15px;
  }

  .inputformpage form input::placeholder {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #b5b0b0;
  }

  .searchbuttons {
    height: 36px;
    background-color: #2200ff;
    border: none;
    width: 36px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  .searchbuttons img {
    width: 16px;
  }
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0;
  }

  label[for="favcity"] {
    position: relative;
    display: block;
    width: 187px;
    height: 35px;
    border-radius: 5px;
    overflow: hidden;
    cursor: pointer;
    margin-left: 0px;
    margin-top: 20px;
  }

  label[for="favcity"]::after {
    content: " ";
    position: absolute;
    right: 0;
    top: 0;
    width: 34px;
    height: 34px;
    display: block;
    background: #2200ff
      url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOBAMAAAGq7RFVAAAAJ1BMVEX///////////////////////////////////////////////////9Ruv0SAAAADHRSTlMACAoREoKDt7y9zc5qtZoyAAAAcklEQVQIHQXBsQnCUAAFwFOCCKbIABaWERzi1yaFAzmEpHYEC8GUKVQEkbyhvOOkZqdWt0/QYKqLzQeF7dv6y/RLwa2wYtk4Xxxe+t8yd1WuM/rcUc2gYHEeoP02umSgTR50ybBPRuiSZAS6ZAQ4jgCAPyjYJiRH4fymAAAAAElFTkSuQmCC)
      no-repeat center center;
    pointer-events: none;
  }

  label[for="favcity"] select {
    height: 34px;
    width: 100%;
    border: none;
    background: #fff;
    padding: 1px 6px 8px 10px;
    font-size: 16px;
    font-family: monospace;
    cursor: pointer;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
  }

  label[for="favcity"] select::-ms-expand {
    display: none;
  }

  label[for="favcity"] :-moz-any(select) {
    width: 110%;
  }

  label[for="favcity"].ie9 select {
    width: 110%;
  }

  label[for="favcity"] select:focus {
    outline: 1px dotted #a9a9a9;
  }

  .dropdown:checked + label,
  .dropdown:not(:checked) + label {
    display: flex;
    font-family: Poppins;
    font-weight: 500;
    font-size: 16px;
    line-height: -1;
    height: 36px;
    transition: all 200ms linear;
    border-radius: 4px;
    width: 71px;
    letter-spacing: 0px;
    display: -ms-inline-flexbox;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
    text-align: center;
    border: none;
    background-color: #2200ff;
    cursor: pointer;
    color: #ffffff;
    box-shadow: 0 12px 35px 0 rgba(255, 235, 167, 0.15);
  }

  .latestfilterbutton {
    display: none;
    width: 127px;
    height: 42px;
    padding: 8px, 26px, 8px, 26px;
    border-radius: 10px;
    gap: 10px;
    background-color: #e1dfe8;
    border: none;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
    color: #121212;
    margin-left: 30px;
  }
  .latestfilterbutton1 {
    display: none;
    width: 167px;
    height: 42px;
    padding: 8px, 26px, 8px, 26px;
    border-radius: 10px;
    gap: 10px;
    background-color: #e1dfe8;
    border: none;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
    color: #121212;
    margin-left: 30px;
  }
  .latestfilterbuttonsds {
    display: none;
    width: 167px;
    height: 42px;
    padding: 8px, 26px, 8px, 26px;
    border-radius: 10px;
    gap: 10px;
    border: none;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
    color: #121212;
    margin-left: 30px;
    background-color: #f2f1f8;
  }
  /* <!------- input and filters------------> */

  /* <!------------- welcome to Revnitro-----------------------> */
  .welcometorevnitro {
    height: 85px;
    background: rgb(11, 0, 77);
    background: linear-gradient(
      90deg,
      rgba(11, 0, 77, 1) 0%,
      rgba(76, 0, 1, 1) 100%
    );
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .welcometorevnitro h1 {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
    color: #fff;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  /* <!------------- welcome to Revnitro-----------------------> */
  /* <!------- Revnitro Topics------------------------> */
  .iconsflexss img {
    width: 25px;
    height: 25px;
    object-fit: cover;
  }

  .revnitrotopicssss {
    background-color: #fff;
    display: flex;
    justify-content: center;
    column-gap: 50px;
    margin-top: 8px;
    margin-left: 0px;
    margin-right: 0px;
    border-radius: 5px;
    height: 70px;
  }

  .textforumdynamic {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0em;
    color: #282828;
  }

  .iconsflexss {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  /* <!------- Revnitro Topics------------------------> */

  .rightpostbox {
    height: auto !important;
    padding-right: 0px !important;
    width: 360px;
    border-radius: 10px;
    border: 2px;
    background: #f2f1f8;
    margin-left: 5px;
  }
}
@media (min-width: 700px) and (max-width: 1000px) {
  [type="checkbox"]:checked,
  [type="checkbox"]:not(:checked) {
    position: absolute;
    left: -9999px;
    opacity: 0;
    pointer-events: none;
  }
  .indexpagemediaqueryflex {
    display: flex;
    justify-content: center;
  }
  .mediaqueryindex {
    width: 700px;
    border: none;
  }

  /*-------------------------Notification------------------------------*/

  .notificationnumberofmessage {
    background-color: #ff0000;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    color: #fff;
    font-family: Poppins;
    font-size: 10px;
    font-weight: 600;
    line-height: 15px;
    letter-spacing: 0em;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 76px;
    border: 4px solid #fff;
    left: 294px;
  }
  .notificationareapostion {
    position: relative;
    display: flex;
    justify-content: end;
    margin-right: 30px;
    margin-top: 30px;
  }

  .notificationareapostion:hover {
    cursor: pointer;
  }

  .notoficationflexx {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    column-gap: 14px;
    margin-left: 10px;
    margin-right: 10px;
    padding-top: 15px;
    padding-bottom: 1px;
  }

  .imageofthenotifier img {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    object-fit: cover;
  }
  .commentedtextss {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
  }
  .notificationpagedivv {
    background-color: #fff;
    border-radius: 7px;
    box-shadow: 0px 4px 4px 0px #0000000d;
    margin-top: 14px;
    position: relative;
    margin-left: 7px;
    margin-right: 7px;
  }

  .timeofthecommented {
    font-family: Poppins;
    font-size: 10px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0em;
    color: #8c8c8c;
    margin-left: 1px;
  }

  .foruumstopicsss {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    color: #2200ff;
  }

  .seenornotdiv {
    width: 8px;
    height: 8px;
    background-color: #ff0000;
    border-radius: 50%;
    position: absolute;
    top: 19px;
    left: 279px;
  }
  .deleteoptionimage {
    margin-top: 54px;
  }
  .deleteoptionimage img {
    width: 24px;
  }
  /*-----------Notification------------------------*/
  /* <!--------- index page----------------------> */

  .mobileelastestbuttonflexoption {
    display: flex;
    align-items: baseline;
    justify-content: end;
    column-gap: 19px;
    margin-right: 24px;
  }

  #heartcoulour {
    font-size: 18px;
    color: #cdcdcd;
    padding-right: 2px;
    margin-top: 0px;
  }

  .lastcommentpicc img {
    width: 43px;
    height: 43px;
    object-fit: cover;
  }

  .uploadname img {
    width: 50px;
    height: 50px;
    object-fit: cover;
  }

  .editimageprofilepicsabsolute {
    position: absolute;
    top: 70px;
    left: 181px;
  }

  .commentsendbutton {
    margin-top: 3px;
    margin-left: 0px;
    width: 23px;
    height: 24px;
  }

  .uploadname {
    display: flex;
    padding-left: 20px;
  }

  .Uplaodnamelenhdtgjhfhjbfdn {
    height: 42px;
  }

  .uploadpersonname {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: 10px;
  }

  .uernamepost {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #9b9a9a;
    margin-left: 10px;
  }

  .postedtime {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #9b9a9a;
    padding-right: 23px;
  }

  .personposted {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    padding-top: 32px;
  }

  .postimage {
    margin-left: 19px;
    margin-right: 0px;
    margin-bottom: 15px;
    margin-top: 20px;
  }
  .postimage img {
    width: 646px;
    height: 301px;
    object-fit: cover;
  }

  .postmapfunctionarea {
    display: flex;
    justify-content: center;
    column-gap: 30px;
    margin-top: 30px;
  }

  .leftnavbarbox {
    background-color: #ffffff;
    width: 322px;
    height: 746px;
    border-radius: 10px;
    margin-top: 15px;
    box-shadow: 0px 4px 4px 0px #0000000d;
  }

  .rightpostbox {
    width: 951px;
    border-radius: 10px;
    border: 2px;
    background: #f2f1f8;
    box-shadow: 0 4px 4px 0 #0000000d;
  }
  .posterss {
    width: 692px;
    border-radius: 5px;
    border: 2px;
    background-color: #ffffff;
    height: auto;
  }

  .usernamenavbar {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0em;
    color: #000000;
    margin-right: 0px;
    text-align: center;
    margin-top: 0px;
  }

  .usernamenavbar h3 {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .idnamename {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
    color: #8e8e8e;
    margin-top: 4px;
  }

  .imageflexleftnavbar {
    display: flex;
    justify-content: center;
    flex-direction: column;
    column-gap: 20px;
    margin-top: 6px;
    align-items: center;
    position: relative;
  }

  .profilephotosssupate img {
    border-radius: 50%;
    display: flex;
    justify-content: center;
  }

  .navigatelinks {
    display: flex;
    align-items: center;
    justify-content: start;
    column-gap: 10px;
    margin-left: 34px;
    margin-top: 27px;
  }

  .navigatenames {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: 6px;
    margin-bottom: 5px;
  }
  .navigatenames:hover {
    /* font-family: Poppins;
        font-size: 24px;
        font-weight: 600;
        line-height: 36px;
        letter-spacing: 0em;
        text-align: left;
        margin-left: 6px;
        margin-bottom: 5px; */
    color: #f00;
  }
  .navigationbuttontop {
    margin-top: 35px;
  }

  .navigatelinks:hover {
    cursor: pointer;
  }

  .postpagaediv p {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    padding-left: 20px;
    margin-bottom: 14px;
  }

  .postcontent {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    padding-left: 20px;
    padding-right: 16px;
  }

  .hastagtext {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #2200ff;
    font-weight: 600;
    padding-left: 20px;
    margin-top: 7px;
  }

  .reactionbuttons {
    display: flex;
    padding-left: 20px;
    margin-top: 10px;
  }

  .reactionbutNeww {
    display: flex;
    padding-left: 20px;
    margin-top: 13px;
    column-gap: 24px;
  }

  .likeflex {
    display: flex;
    justify-content: center;
    column-gap: 5px;
  }

  .commentsflex {
    display: flex;
    justify-content: center;
    column-gap: 5px;
    margin-left: 10px;
    align-items: flex-start;
  }

  .shareflex {
    display: flex;
    justify-content: center;
    column-gap: 5px;
    margin-left: 10px;
  }

  .sharefonts {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }

  .linebottom {
    border: 1px solid #c6c6c6;
    margin-left: 20px;
    margin-right: 19px;
    margin-top: 10px;
  }

  .cinputflex {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    column-gap: 9px;
    margin-top: 23px;
    padding-bottom: 25px;
  }

  .commentbox {
    display: flex;
    align-items: center;
    column-gap: 10px;
  }

  .commentbox input {
    width: 507px;
    height: 43px;
    border-radius: 5px;
    background-color: #f2f2f2;
    border: none;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    padding-left: 5px;
    color: #000000;
    padding-left: 15px;
  }
  .commentbox input::placeholder {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #969595;
  }
  .commentbox button {
    width: 45px;
    height: 43px;
    border-radius: 7px;
    background-color: #2200ff;
    border: none;
    margin-left: 5px;
  }

  .commentshowsection {
    width: 677px;
    background-color: #fff;
    margin-top: 15px;
    padding-left: 8px;
    padding-right: 5px;
    border-radius: 10px;
    padding-bottom: 30px;
  }

  .commentsshowmoree {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 22px;
  }
  .commentsshowmoree button {
    width: 93px;
    height: 30px;
    font-size: 12px;
    background: #2200ff;
    border: none;
    border-radius: 5px;
    color: #fff;
    font-weight: 500;
    line-height: 17px;
  }
  .maincomment {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .commentingtime {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #9b9a9a;
  }

  .usercomment {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    margin-top: 13px;
  }
  .commentingarea {
    width: 647px;
    background-color: #f2f2f2;
    border-radius: 5px;
    margin-top: 13px;
    padding-top: 19px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 26px;
  }
  .imageuserforum {
    display: flex;
    justify-content: center;
    column-gap: 7px;
  }

  .imageuserforum img {
    width: 42px;
    height: 43px;
    border-radius: 50%;
    margin-top: 0px;
    object-fit: contain;
  }
  .forumcommentuser {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
  }
  .forumusernameres {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #9b9a9a;
  }
  .noofcomments {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    padding-left: 10px;
    padding-top: 20px;
  }
  /* <!--------- index page----------------------> */

  /* <!------- input and filters------------> */
  .formflexx {
    display: flex;
    justify-content: center;
  }
  .formsandfilters {
    margin-top: 23px;
    margin-left: 7px;
    display: flex;
    flex-direction: column;
  }
  .inputformpage form input {
    border: none;
    width: 625px;
    height: 43px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #000;
    padding-left: 15px;
  }

  .inputformpage form input::placeholder {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #b5b0b0;
  }

  .searchbuttons {
    height: 45px;
    background-color: #2200ff;
    border: none;
    width: 43px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  .searchbuttons img {
    width: 20px;
  }
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0;
  }

  label[for="favcity"] {
    position: relative;
    display: block;
    width: 187px;
    height: 40px;
    border-radius: 5px;
    overflow: hidden;
    cursor: pointer;
    margin-left: 0px;
    margin-top: 20px;
  }

  label[for="favcity"]::after {
    content: " ";
    position: absolute;
    right: 0;
    top: 0;
    width: 36px;
    height: 39px;
    display: block;
    background: #2200ff
      url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOBAMAAAGq7RFVAAAAJ1BMVEX///////////////////////////////////////////////////9Ruv0SAAAADHRSTlMACAoREoKDt7y9zc5qtZoyAAAAcklEQVQIHQXBsQnCUAAFwFOCCKbIABaWERzi1yaFAzmEpHYEC8GUKVQEkbyhvOOkZqdWt0/QYKqLzQeF7dv6y/RLwa2wYtk4Xxxe+t8yd1WuM/rcUc2gYHEeoP02umSgTR50ybBPRuiSZAS6ZAQ4jgCAPyjYJiRH4fymAAAAAElFTkSuQmCC)
      no-repeat center center;
    pointer-events: none;
  }

  label[for="favcity"] select {
    height: 40px;
    width: 100%;
    border: none;
    background: #fff;
    padding: 1px 6px 8px 10px;
    font-size: 16px;
    font-family: monospace;
    cursor: pointer;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 39px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
  }

  label[for="favcity"] select::-ms-expand {
    display: none;
  }

  label[for="favcity"] :-moz-any(select) {
    width: 110%;
  }

  label[for="favcity"].ie9 select {
    width: 110%;
  }

  label[for="favcity"] select:focus {
    outline: 1px dotted #a9a9a9;
  }

  .dropdown:checked + label,
  .dropdown:not(:checked) + label {
    display: flex;
    font-family: Poppins;
    font-weight: 500;
    font-size: 16px;
    line-height: -1;
    height: 36px;
    transition: all 200ms linear;
    border-radius: 4px;
    width: 71px;
    letter-spacing: 0px;
    display: -ms-inline-flexbox;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
    text-align: center;
    border: none;
    background-color: #2200ff;
    cursor: pointer;
    color: #ffffff;
    box-shadow: 0 12px 35px 0 rgba(255, 235, 167, 0.15);
  }

  .latestfilterbutton {
    display: none;
    width: 127px;
    height: 42px;
    padding: 8px, 26px, 8px, 26px;
    border-radius: 10px;
    gap: 10px;
    background-color: #e1dfe8;
    border: none;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
    color: #121212;
    margin-left: 30px;
  }
  .latestfilterbutton1 {
    display: none;
    width: 167px;
    height: 42px;
    padding: 8px, 26px, 8px, 26px;
    border-radius: 10px;
    gap: 10px;
    background-color: #e1dfe8;
    border: none;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
    color: #121212;
    margin-left: 30px;
  }
  .latestfilterbuttonsds {
    display: none;
    width: 167px;
    height: 42px;
    padding: 8px, 26px, 8px, 26px;
    border-radius: 10px;
    gap: 10px;
    border: none;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
    color: #121212;
    margin-left: 30px;
    background-color: #f2f1f8;
  }
  /* <!------- input and filters------------> */

  /* <!------------- welcome to Revnitro-----------------------> */
  .welcometorevnitro {
    height: 116px;
    background: rgb(11, 0, 77);
    background: linear-gradient(
      90deg,
      rgba(11, 0, 77, 1) 0%,
      rgba(76, 0, 1, 1) 100%
    );
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .welcometorevnitro h1 {
    font-family: Poppins;
    font-size: 35px;
    font-weight: 600;
    line-height: 41px;
    letter-spacing: 0em;
    text-align: center;
    color: #fff;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  /* <!------------- welcome to Revnitro-----------------------> */
  /* <!------- Revnitro Topics------------------------> */
  .iconsflexss img {
    width: 36px;
    height: 36px;
    object-fit: cover;
  }

  .revnitrotopicssss {
    background-color: #fff;
    display: flex;
    justify-content: center;
    column-gap: 50px;
    margin-top: 8px;
    margin-left: 0px;
    margin-right: 0px;
    border-radius: 5px;
    height: 102px;
  }

  .textforumdynamic {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0em;
    color: #282828;
  }

  .iconsflexss {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  /* <!------- Revnitro Topics------------------------> */

  .rightpostbox {
    height: auto !important;
    padding-right: 0px !important;
    width: 700px;
    border-radius: 10px;
    border: 2px;
    background: #f2f1f8;
    margin-left: 5px;
  }
}
/* <!-----Main Index Page-----------------> */

@media (min-width: 1051px) and (max-width: 1150px) {
  /*-------------------------Notification------------------------------*/
  .commentedtextss {
    font-family: Poppins;
    font-size: 15px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
  }

  .foruumstopicsss {
    font-family: Poppins;
    font-size: 15px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0em;
    color: #2200ff;
  }

  .seenornotdiv {
    width: 11px;
    height: 11px;
    background-color: #ff0000;
    border-radius: 50%;
    position: absolute;
    top: 19px;
    left: 577px;
  }

  .notificationnumberofmessage {
    background-color: #ff0000;
    width: 19px;
    height: 19px;
    border-radius: 50%;
    color: #fff;
    font-family: Poppins;
    font-size: 10px;
    font-weight: 600;
    line-height: 15px;
    letter-spacing: 0em;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -2px;
    border: 4px solid #fff;
    left: 160px;
  }
  .notificationareapostion {
    position: relative;
    display: flex;
    justify-content: end;
    margin-right: 30px;
    margin-top: 30px;
  }

  .notificationareapostion:hover {
    cursor: pointer;
  }
  /*-------------------------Notification------------------------------*/
  .indexpagemediaqueryflex {
    display: flex;
    justify-content: center;
  }
  .mediaqueryindex {
    width: 900px;
    border: none;
  }

  /* <!---------- welcome to Revnitro------------> */
  .welcometorevnitro {
    height: 120px;
    background: rgb(11, 0, 77);
    background: linear-gradient(
      90deg,
      rgba(11, 0, 77, 1) 0%,
      rgba(76, 0, 1, 1) 100%
    );
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .welcometorevnitro h1 {
    font-family: Poppins;
    font-size: 45px;
    font-weight: 600;
    line-height: 90px;
    letter-spacing: 0em;
    text-align: center;
    color: #fff;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  /* <!---------- welcome to Revnitro------------> */

  /* <!----------- Revnitro Topics---------------> */
  .revnitrotopicssss {
    background-color: #fff;
    display: flex;
    justify-content: center;
    column-gap: 195px;
    margin-top: 20px;
    margin-left: 42px;
    margin-right: 40px;
    border-radius: 10px;
    height: 130px;
  }

  .textforumdynamic {
    font-family: Poppins;
    font-size: 17px;
    font-weight: 700;
    line-height: 44px;
    letter-spacing: 0em;
    color: #282828;
  }

  .iconsflexss {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  /* <!----------- Revnitro Topics---------------> */

  /* <!----------- input and filters-------------> */

  .formflexx {
    display: flex;
  }
  .formsandfilters {
    margin-top: 30px;
    margin-left: 40px;
    display: flex;
  }
  .inputformpage form input {
    border: none;
    width: 184px;
    height: 34px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color: #b5b0b0;
    padding-left: 15px;
  }

  .inputformpage form input::placeholder {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color: #b5b0b0;
  }

  .searchbuttons {
    height: 35px;
    background-color: #2200ff;
    border: none;
    width: 35px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .searchbuttons img {
    width: 18px;
  }
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0;
  }

  label[for="favcity"] {
    position: relative;
    display: block;
    width: 131px;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
    margin-left: 30px;
  }

  label[for="favcity"]::after {
    content: " ";
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
    height: 100%;
    display: block;
    background: #2200ff
      url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOBAMAAAGq7RFVAAAAJ1BMVEX///////////////////////////////////////////////////9Ruv0SAAAADHRSTlMACAoREoKDt7y9zc5qtZoyAAAAcklEQVQIHQXBsQnCUAAFwFOCCKbIABaWERzi1yaFAzmEpHYEC8GUKVQEkbyhvOOkZqdWt0/QYKqLzQeF7dv6y/RLwa2wYtk4Xxxe+t8yd1WuM/rcUc2gYHEeoP02umSgTR50ybBPRuiSZAS6ZAQ4jgCAPyjYJiRH4fymAAAAAElFTkSuQmCC")
      no-repeat center center;
    pointer-events: none;
  }

  label[for="favcity"] select {
    height: 34px;
    width: 100%;
    border: none;
    background: #2200ff;
    padding: 7px 20px 7px 20px;
    font-size: 16px;
    font-family: monospace;
    cursor: pointer;
    font-family: Poppins;
    font-size: 15px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff;
  }

  label[for="favcity"] select::-ms-expand {
    display: none;
  }

  label[for="favcity"] :-moz-any(select) {
    width: 110%;
  }

  label[for="favcity"].ie9 select {
    width: 110%;
  }

  label[for="favcity"] select:focus {
    outline: 1px dotted #a9a9a9;
  }

  .latestfilterbutton {
    width: 96px;
    height: 34px;
    padding: 8px, 26px, 8px, 26px;
    border-radius: 10px;
    gap: 10px;
    background-color: #e1dfe8;
    border: none;
    font-family: Poppins;
    font-size: 15px;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
    color: #121212;
    margin-left: 30px;
  }
  .latestfilterbutton1 {
    width: 135px;
    height: 35px;
    padding: 8px, 26px, 8px, 26px;
    border-radius: 10px;
    gap: 10px;
    background-color: #e1dfe8;
    border: none;
    font-family: Poppins;
    font-size: 15px;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
    color: #121212;
    margin-left: 30px;
  }
  .latestfilterbuttonsds {
    width: 167px;
    height: 42px;
    padding: 8px, 26px, 8px, 26px;
    border-radius: 10px;
    gap: 10px;
    border: none;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
    color: #121212;
    margin-left: 30px;
    background-color: #f2f1f8;
  }

  /* <!----------- input and filters-------------> */

  /* <!---------- index page---------------------> */

  .editimageprofilepicsabsolute {
    position: absolute;
    top: 58px;
    left: 119px;
  }

  .profilephotosssupate {
    margin-right: 23px;
  }

  .editimageprofilepicsabsolute {
    position: absolute;
    top: 58px;
    left: 119px;
  }

  .commentsendbutton {
    margin-top: 2px;
    margin-left: 0px;
  }

  .uploadname {
    display: flex;
    padding-left: 40px;
  }

  .uploadpersonname {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: 15px;
  }

  .uernamepost {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #9b9a9a;
    margin-left: 15px;
  }

  .postedtime {
    font-family: Poppins;
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #9b9a9a;
    padding-right: 43px;
  }

  .personposted {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    padding-top: 32px;
  }

  .postimage {
    margin-left: 40px;
    margin-right: 40px;
    margin-bottom: 15px;
    margin-top: 20px;
  }
  .postimage img {
    width: 456px;
  }
  .postimagethumb img {
    width: 100%;
  }
  .postmapfunctionarea {
    display: flex;
    justify-content: center;
    column-gap: 30px;
    margin-top: 30px;
  }

  .leftnavbarbox {
    padding-left: 20px;
    margin-left: 40px;
    background-color: #ffffff;
    width: 319px;
    height: 615px;
    border-radius: 10px;
    margin-top: 15px;
    box-shadow: 0px 4px 4px 0px #0000000d;
  }

  .rightpostbox {
    height: 120vh;

    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 20px;
    width: 951px;
    border-radius: 10px;
    border: 2px;
    background: #f2f1f8;
  }
  .posterss {
    width: 555px;
    border-radius: 10px;
    border: 2px;
    background-color: #ffffff;
    height: auto;
  }

  .usernamenavbar {
    font-family: Poppins;
    font-size: 15px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    margin-right: 34px;
  }

  .usernamenavbar h3 {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .idnamename {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #8e8e8e;
  }

  /* .imageflexleftnavbar {
            display: flex;
            justify-content: center;
            column-gap: 20px;
            margin-top: 20px;
          } */

  .navigatelinks {
    display: flex;
    align-items: center;
    justify-content: start;
    column-gap: 6px;
    margin-left: 0px;
    margin-top: 10px;
  }

  .navigatenames {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: 6px;
    margin-bottom: 5px;
  }
  .navigatenames:hover {
    color: #f00;
  }
  .navigationbuttontop {
    margin-top: 20px;
  }

  .navigatelinks:hover {
    cursor: pointer;
  }

  .postpagaediv p {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    padding-left: 40px;
    margin-bottom: 14px;
  }

  .postcontent {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    padding-left: 40px;
    padding-right: 40px;
  }

  .hastagtext {
    font-family: Poppins;
    font-size: 15px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    color: #2200ff;
    font-weight: 600;
    padding-left: 40px;
    margin-top: 0px;
  }

  .reactionbuttons {
    display: flex;
    padding-left: 40px;
    margin-top: 15px;
  }

  .reactionbutNeww {
    display: flex;
    padding-left: 40px;
    margin-top: 15px;
  }

  .likeflex {
    display: flex;
    justify-content: center;
    column-gap: 5px;
  }

  .commentsflex {
    display: flex;
    justify-content: center;
    column-gap: 5px;
    margin-left: 20px;
  }

  .shareflex {
    display: flex;
    justify-content: center;
    column-gap: 5px;
    margin-left: 20px;
  }

  .sharefonts {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }

  .linebottom {
    border: 1px solid #c6c6c6;
    margin-left: 40px;
    margin-right: 50px;
    margin-top: 15px;
  }

  .cinputflex {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
    margin-top: 20px;
    padding-bottom: 25px;
  }

  .lastcommentpicc img {
    width: 37px;
  }

  .commentbox {
    display: flex;
    align-items: center;
  }

  .commentbox input {
    width: 334px;
    height: 37px;
    border-radius: 10px;
    background-color: #f2f2f2;
    border: none;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    padding-left: 20px;
    color: #969595;
  }
  .commentbox input::placeholder {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #969595;
  }
  .commentbox button {
    width: 39px;
    height: 37px;
    border-radius: 10px;
    background-color: #2200ff;
    border: none;
    margin-left: 8px;
  }

  .commentshowsection {
    background-color: #fff;
    margin-top: 12px;
    padding-left: 19px;
    padding-right: 25px;
    border-radius: 10px;
    padding-bottom: 30px;
    width: 506px;
  }

  .commentsshowmoree {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
  .commentsshowmoree button {
    width: 122px;
    height: 33px;
    font-size: 16px;
    background: #2200ff;
    border: none;
    border-radius: 5px;
    color: #fff;
    font-weight: 500;
  }
  .maincomment {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .commentingtime {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #9b9a9a;
  }

  .usercomment {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    margin-top: 13px;
  }
  .commentingarea {
    background-color: #f2f2f2;
    padding: 30px;
    width: 453px;
    border-radius: 10px;
    margin-top: 25px;
    padding-top: 20px;
  }
  .imageuserforum {
    display: flex;
    justify-content: center;
    column-gap: 15px;
  }
  .forumcommentuser {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
  }
  .forumusernameres {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: left;
    color: #9b9a9a;
  }
  .noofcomments {
    font-family: Poppins;
    font-size: 21px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    padding-left: 10px;
    padding-top: 30px;
  }
  /* <!---------- index page---------------------> */
}
@media (min-width: 1151px) and (max-width: 1250px) {
  /*-------------------------Notification------------------------------*/
  .commentedtextss {
    font-family: Poppins;
    font-size: 15px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
  }

  .foruumstopicsss {
    font-family: Poppins;
    font-size: 15px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0em;
    color: #2200ff;
  }
  .seenornotdiv {
    width: 11px;
    height: 11px;
    background-color: #ff0000;
    border-radius: 50%;
    position: absolute;
    top: 19px;
    left: 577px;
  }

  .notificationnumberofmessage {
    background-color: #ff0000;
    width: 19px;
    height: 19px;
    border-radius: 50%;
    color: #fff;
    font-family: Poppins;
    font-size: 10px;
    font-weight: 600;
    line-height: 15px;
    letter-spacing: 0em;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -2px;
    border: 4px solid #fff;
    left: 160px;
  }
  .notificationareapostion {
    position: relative;
    display: flex;
    justify-content: end;
    margin-right: 30px;
    margin-top: 30px;
  }

  .notificationareapostion:hover {
    cursor: pointer;
  }
  /*-------------------------Notification------------------------------*/
  .indexpagemediaqueryflex {
    display: flex;
    justify-content: center;
  }
  .mediaqueryindex {
    width: 900px;
    border: none;
  }

  /* <!---------- welcome to Revnitro------------> */
  .welcometorevnitro {
    height: 120px;
    background: rgb(11, 0, 77);
    background: linear-gradient(
      90deg,
      rgba(11, 0, 77, 1) 0%,
      rgba(76, 0, 1, 1) 100%
    );
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .welcometorevnitro h1 {
    font-family: Poppins;
    font-size: 45px;
    font-weight: 600;
    line-height: 90px;
    letter-spacing: 0em;
    text-align: center;
    color: #fff;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  /* <!---------- welcome to Revnitro------------> */

  /* <!----------- Revnitro Topics---------------> */
  .revnitrotopicssss {
    background-color: #fff;
    display: flex;
    justify-content: center;
    column-gap: 195px;
    margin-top: 20px;
    margin-left: 42px;
    margin-right: 40px;
    border-radius: 10px;
    height: 130px;
  }

  .textforumdynamic {
    font-family: Poppins;
    font-size: 17px;
    font-weight: 700;
    line-height: 44px;
    letter-spacing: 0em;
    color: #282828;
  }

  .iconsflexss {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  /* <!----------- Revnitro Topics---------------> */

  /* <!----------- input and filters-------------> */

  .formflexx {
    display: flex;
  }
  .formsandfilters {
    margin-top: 30px;
    margin-left: 40px;
    display: flex;
  }
  .inputformpage form input {
    border: none;
    width: 184px;
    height: 34px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color: #b5b0b0;
    padding-left: 15px;
  }

  .inputformpage form input::placeholder {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color: #b5b0b0;
  }

  .searchbuttons {
    height: 35px;
    background-color: #2200ff;
    border: none;
    width: 35px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .searchbuttons img {
    width: 18px;
  }
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0;
  }

  label[for="favcity"] {
    position: relative;
    display: block;
    width: 131px;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
    margin-left: 30px;
  }

  label[for="favcity"]::after {
    content: " ";
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
    height: 100%;
    display: block;
    background: #2200ff
      url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOBAMAAAGq7RFVAAAAJ1BMVEX///////////////////////////////////////////////////9Ruv0SAAAADHRSTlMACAoREoKDt7y9zc5qtZoyAAAAcklEQVQIHQXBsQnCUAAFwFOCCKbIABaWERzi1yaFAzmEpHYEC8GUKVQEkbyhvOOkZqdWt0/QYKqLzQeF7dv6y/RLwa2wYtk4Xxxe+t8yd1WuM/rcUc2gYHEeoP02umSgTR50ybBPRuiSZAS6ZAQ4jgCAPyjYJiRH4fymAAAAAElFTkSuQmCC")
      no-repeat center center;
    pointer-events: none;
  }

  label[for="favcity"] select {
    height: 34px;
    width: 100%;
    border: none;
    background: #2200ff;
    padding: 7px 20px 7px 20px;
    font-size: 16px;
    font-family: monospace;
    cursor: pointer;
    font-family: Poppins;
    font-size: 15px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff;
  }

  label[for="favcity"] select::-ms-expand {
    display: none;
  }

  label[for="favcity"] :-moz-any(select) {
    width: 110%;
  }

  label[for="favcity"].ie9 select {
    width: 110%;
  }

  label[for="favcity"] select:focus {
    outline: 1px dotted #a9a9a9;
  }

  .latestfilterbutton {
    width: 96px;
    height: 34px;
    padding: 8px, 26px, 8px, 26px;
    border-radius: 10px;
    gap: 10px;
    background-color: #e1dfe8;
    border: none;
    font-family: Poppins;
    font-size: 15px;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
    color: #121212;
    margin-left: 30px;
  }
  .latestfilterbutton1 {
    width: 135px;
    height: 35px;
    padding: 8px, 26px, 8px, 26px;
    border-radius: 10px;
    gap: 10px;
    background-color: #e1dfe8;
    border: none;
    font-family: Poppins;
    font-size: 15px;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
    color: #121212;
    margin-left: 30px;
  }
  .latestfilterbuttonsds {
    width: 167px;
    height: 42px;
    padding: 8px, 26px, 8px, 26px;
    border-radius: 10px;
    gap: 10px;
    border: none;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
    color: #121212;
    margin-left: 30px;
    background-color: #f2f1f8;
  }

  /* <!----------- input and filters-------------> */

  /* <!---------- index page---------------------> */

  .editimageprofilepicsabsolute {
    position: absolute;
    top: 58px;
    left: 119px;
  }

  .profilephotosssupate {
    margin-right: 23px;
  }

  .commentsendbutton {
    margin-top: 2px;
    margin-left: 0px;
  }

  .uploadname {
    display: flex;
    padding-left: 40px;
  }

  .uploadpersonname {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: 15px;
  }

  .uernamepost {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #9b9a9a;
    margin-left: 15px;
  }

  .postedtime {
    font-family: Poppins;
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #9b9a9a;
    padding-right: 43px;
  }

  .personposted {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    padding-top: 32px;
  }

  .postimage {
    margin-left: 40px;
    margin-right: 40px;
    margin-bottom: 15px;
    margin-top: 20px;
  }
  .postimage img {
    width: 456px;
  }
  .postimagethumb img {
    width: 100%;
  }

  .postmapfunctionarea {
    display: flex;
    justify-content: center;
    column-gap: 30px;
    margin-top: 30px;
  }

  .leftnavbarbox {
    padding-left: 20px;
    margin-left: 40px;
    background-color: #ffffff;
    width: 319px;
    height: 615px;
    border-radius: 10px;
    margin-top: 15px;
    box-shadow: 0px 4px 4px 0px #0000000d;
  }

  .rightpostbox {
    height: 120vh;

    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 20px;
    width: 951px;
    border-radius: 10px;
    border: 2px;
    background: #f2f1f8;
  }
  .posterss {
    width: 555px;
    border-radius: 10px;
    border: 2px;
    background-color: #ffffff;
    height: auto;
  }

  .usernamenavbar {
    font-family: Poppins;
    font-size: 15px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    margin-right: 34px;
  }

  .usernamenavbar h3 {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .idnamename {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #8e8e8e;
  }

  /* .imageflexleftnavbar {
            display: flex;
            justify-content: center;
            column-gap: 20px;
            margin-top: 20px;
          } */

  .navigatelinks {
    display: flex;
    align-items: center;
    justify-content: start;
    column-gap: 6px;
    margin-left: 0px;
    margin-top: 10px;
  }

  .navigatenames {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: 6px;
    margin-bottom: 5px;
  }
  .navigatenames:hover {
    color: #f00;
  }
  .navigationbuttontop {
    margin-top: 20px;
  }

  .navigatelinks:hover {
    cursor: pointer;
  }

  .postpagaediv p {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    padding-left: 40px;
    margin-bottom: 14px;
  }

  .postcontent {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    padding-left: 40px;
    padding-right: 40px;
  }

  .hastagtext {
    font-family: Poppins;
    font-size: 15px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    color: #2200ff;
    font-weight: 600;
    padding-left: 40px;
    margin-top: 0px;
  }

  .reactionbuttons {
    display: flex;
    padding-left: 40px;
    margin-top: 15px;
  }

  .reactionbutNeww {
    display: flex;
    padding-left: 40px;
    margin-top: 15px;
  }

  .likeflex {
    display: flex;
    justify-content: center;
    column-gap: 5px;
  }

  .commentsflex {
    display: flex;
    justify-content: center;
    column-gap: 5px;
    margin-left: 20px;
  }

  .shareflex {
    display: flex;
    justify-content: center;
    column-gap: 5px;
    margin-left: 20px;
  }

  .sharefonts {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }

  .linebottom {
    border: 1px solid #c6c6c6;
    margin-left: 40px;
    margin-right: 50px;
    margin-top: 15px;
  }

  .cinputflex {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
    margin-top: 20px;
    padding-bottom: 25px;
  }

  .lastcommentpicc img {
    width: 37px;
  }

  .commentbox {
    display: flex;
    align-items: center;
  }

  .commentbox input {
    width: 334px;
    height: 37px;
    border-radius: 10px;
    background-color: #f2f2f2;
    border: none;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    padding-left: 20px;
    color: #969595;
  }
  .commentbox input::placeholder {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #969595;
  }
  .commentbox button {
    width: 39px;
    height: 37px;
    border-radius: 10px;
    background-color: #2200ff;
    border: none;
    margin-left: 8px;
  }

  .commentshowsection {
    background-color: #fff;
    margin-top: 12px;
    padding-left: 19px;
    padding-right: 25px;
    border-radius: 10px;
    padding-bottom: 30px;
    width: 506px;
  }

  .commentsshowmoree {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
  .commentsshowmoree button {
    width: 122px;
    height: 33px;
    font-size: 16px;
    background: #2200ff;
    border: none;
    border-radius: 5px;
    color: #fff;
    font-weight: 500;
  }
  .maincomment {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .commentingtime {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #9b9a9a;
  }

  .usercomment {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    margin-top: 13px;
  }
  .commentingarea {
    background-color: #f2f2f2;
    padding: 30px;
    width: 453px;
    border-radius: 10px;
    margin-top: 25px;
    padding-top: 20px;
  }
  .imageuserforum {
    display: flex;
    justify-content: center;
    column-gap: 15px;
  }
  .forumcommentuser {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
  }
  .forumusernameres {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: left;
    color: #9b9a9a;
  }
  .noofcomments {
    font-family: Poppins;
    font-size: 21px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    padding-left: 10px;
    padding-top: 30px;
  }
  /* <!---------- index page---------------------> */
}
@media (min-width: 1251px) and (max-width: 1350px) {
  /*-------------------------Notification------------------------------*/
  .commentedtextss {
    font-family: Poppins;
    font-size: 15px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
  }

  .foruumstopicsss {
    font-family: Poppins;
    font-size: 15px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0em;
    color: #2200ff;
  }
  .seenornotdiv {
    width: 11px;
    height: 11px;
    background-color: #ff0000;
    border-radius: 50%;
    position: absolute;
    top: 19px;
    left: 577px;
  }

  .notificationnumberofmessage {
    background-color: #ff0000;
    width: 19px;
    height: 19px;
    border-radius: 50%;
    color: #fff;
    font-family: Poppins;
    font-size: 10px;
    font-weight: 600;
    line-height: 15px;
    letter-spacing: 0em;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -2px;
    border: 4px solid #fff;
    left: 160px;
  }
  .notificationareapostion {
    position: relative;
    display: flex;
    justify-content: end;
    margin-right: 30px;
    margin-top: 30px;
  }

  .notificationareapostion:hover {
    cursor: pointer;
  }
  /*-------------------------Notification------------------------------*/
  .indexpagemediaqueryflex {
    display: flex;
    justify-content: center;
  }
  .mediaqueryindex {
    width: 900px;
    border: none;
  }

  /* <!---------- welcome to Revnitro------------> */
  .welcometorevnitro {
    height: 120px;
    background: rgb(11, 0, 77);
    background: linear-gradient(
      90deg,
      rgba(11, 0, 77, 1) 0%,
      rgba(76, 0, 1, 1) 100%
    );
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .welcometorevnitro h1 {
    font-family: Poppins;
    font-size: 45px;
    font-weight: 600;
    line-height: 90px;
    letter-spacing: 0em;
    text-align: center;
    color: #fff;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  /* <!---------- welcome to Revnitro------------> */

  /* <!----------- Revnitro Topics---------------> */
  .revnitrotopicssss {
    background-color: #fff;
    display: flex;
    justify-content: center;
    column-gap: 195px;
    margin-top: 20px;
    margin-left: 42px;
    margin-right: 40px;
    border-radius: 10px;
    height: 130px;
  }

  .textforumdynamic {
    font-family: Poppins;
    font-size: 17px;
    font-weight: 700;
    line-height: 44px;
    letter-spacing: 0em;
    color: #282828;
  }

  .iconsflexss {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  /* <!----------- Revnitro Topics---------------> */

  /* <!----------- input and filters-------------> */

  .formflexx {
    display: flex;
  }
  .formsandfilters {
    margin-top: 30px;
    margin-left: 40px;
    display: flex;
  }
  .inputformpage form input {
    border: none;
    width: 184px;
    height: 34px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color: #b5b0b0;
    padding-left: 15px;
  }

  .inputformpage form input::placeholder {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color: #b5b0b0;
  }

  .searchbuttons {
    height: 35px;
    background-color: #2200ff;
    border: none;
    width: 35px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .searchbuttons img {
    width: 18px;
  }
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0;
  }

  label[for="favcity"] {
    position: relative;
    display: block;
    width: 131px;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
    margin-left: 30px;
  }

  label[for="favcity"]::after {
    content: " ";
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
    height: 100%;
    display: block;
    background: #2200ff
      url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOBAMAAAGq7RFVAAAAJ1BMVEX///////////////////////////////////////////////////9Ruv0SAAAADHRSTlMACAoREoKDt7y9zc5qtZoyAAAAcklEQVQIHQXBsQnCUAAFwFOCCKbIABaWERzi1yaFAzmEpHYEC8GUKVQEkbyhvOOkZqdWt0/QYKqLzQeF7dv6y/RLwa2wYtk4Xxxe+t8yd1WuM/rcUc2gYHEeoP02umSgTR50ybBPRuiSZAS6ZAQ4jgCAPyjYJiRH4fymAAAAAElFTkSuQmCC")
      no-repeat center center;
    pointer-events: none;
  }

  label[for="favcity"] select {
    height: 34px;
    width: 100%;
    border: none;
    background: #2200ff;
    padding: 7px 20px 7px 20px;
    font-size: 16px;
    font-family: monospace;
    cursor: pointer;
    font-family: Poppins;
    font-size: 15px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff;
  }

  label[for="favcity"] select::-ms-expand {
    display: none;
  }

  label[for="favcity"] :-moz-any(select) {
    width: 110%;
  }

  label[for="favcity"].ie9 select {
    width: 110%;
  }

  label[for="favcity"] select:focus {
    outline: 1px dotted #a9a9a9;
  }

  .latestfilterbutton {
    width: 96px;
    height: 34px;
    padding: 8px, 26px, 8px, 26px;
    border-radius: 10px;
    gap: 10px;
    background-color: #e1dfe8;
    border: none;
    font-family: Poppins;
    font-size: 15px;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
    color: #121212;
    margin-left: 30px;
  }
  .latestfilterbutton1 {
    width: 135px;
    height: 35px;
    padding: 8px, 26px, 8px, 26px;
    border-radius: 10px;
    gap: 10px;
    background-color: #e1dfe8;
    border: none;
    font-family: Poppins;
    font-size: 15px;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
    color: #121212;
    margin-left: 30px;
  }
  .latestfilterbuttonsds {
    width: 167px;
    height: 42px;
    padding: 8px, 26px, 8px, 26px;
    border-radius: 10px;
    gap: 10px;
    border: none;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
    color: #121212;
    margin-left: 30px;
    background-color: #f2f1f8;
  }

  /* <!----------- input and filters-------------> */

  /* <!---------- index page---------------------> */

  .editimageprofilepicsabsolute {
    position: absolute;
    top: 58px;
    left: 119px;
  }

  .profilephotosssupate {
    margin-right: 23px;
  }

  .commentsendbutton {
    margin-top: 2px;
    margin-left: 0px;
  }

  .uploadname {
    display: flex;
    padding-left: 40px;
  }

  .uploadpersonname {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: 15px;
  }

  .uernamepost {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #9b9a9a;
    margin-left: 15px;
  }

  .postedtime {
    font-family: Poppins;
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #9b9a9a;
    padding-right: 43px;
  }

  .personposted {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    padding-top: 32px;
  }

  .postimage {
    margin-left: 40px;
    margin-right: 40px;
    margin-bottom: 15px;
    margin-top: 20px;
  }
  .postimage img {
    width: 456px;
  }
  .postimagethumb img {
    width: 100%;
  }

  .postmapfunctionarea {
    display: flex;
    justify-content: center;
    column-gap: 30px;
    margin-top: 30px;
  }

  .leftnavbarbox {
    padding-left: 20px;
    margin-left: 40px;
    background-color: #ffffff;
    width: 319px;
    height: 615px;
    border-radius: 10px;
    margin-top: 15px;
    box-shadow: 0px 4px 4px 0px #0000000d;
  }

  .rightpostbox {
    height: 120vh;

    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 20px;
    width: 951px;

    border-radius: 10px;
    border: 2px;
    background: #f2f1f8;
  }
  .posterss {
    width: 555px;
    border-radius: 10px;
    border: 2px;
    background-color: #ffffff;
    height: auto;
  }

  .usernamenavbar {
    font-family: Poppins;
    font-size: 15px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    margin-right: 34px;
  }

  .usernamenavbar h3 {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .idnamename {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #8e8e8e;
  }

  /* .imageflexleftnavbar {
            display: flex;
            justify-content: center;
            column-gap: 20px;
            margin-top: 20px;
          } */

  .navigatelinks {
    display: flex;
    align-items: center;
    justify-content: start;
    column-gap: 6px;
    margin-left: 0px;
    margin-top: 10px;
  }

  .navigatenames {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: 6px;
    margin-bottom: 5px;
  }
  .navigatenames:hover {
    color: #f00;
  }
  .navigationbuttontop {
    margin-top: 20px;
  }

  .navigatelinks:hover {
    cursor: pointer;
  }

  .postpagaediv p {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    padding-left: 40px;
    margin-bottom: 14px;
  }

  .postcontent {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    padding-left: 40px;
    padding-right: 40px;
  }

  .hastagtext {
    font-family: Poppins;
    font-size: 15px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    color: #2200ff;
    font-weight: 600;
    padding-left: 40px;
    margin-top: 0px;
  }

  .reactionbuttons {
    display: flex;
    padding-left: 40px;
    margin-top: 15px;
  }

  .reactionbutNeww {
    display: flex;
    padding-left: 40px;
    margin-top: 15px;
  }

  .likeflex {
    display: flex;
    justify-content: center;
    column-gap: 5px;
  }

  .commentsflex {
    display: flex;
    justify-content: center;
    column-gap: 5px;
    margin-left: 20px;
  }

  .shareflex {
    display: flex;
    justify-content: center;
    column-gap: 5px;
    margin-left: 20px;
  }

  .sharefonts {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }

  .linebottom {
    border: 1px solid #c6c6c6;
    margin-left: 40px;
    margin-right: 50px;
    margin-top: 15px;
  }

  .cinputflex {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
    margin-top: 20px;
    padding-bottom: 25px;
  }

  .lastcommentpicc img {
    width: 37px;
  }

  .commentbox {
    display: flex;
    align-items: center;
  }

  .commentbox input {
    width: 334px;
    height: 37px;
    border-radius: 10px;
    background-color: #f2f2f2;
    border: none;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    padding-left: 20px;
    color: #969595;
  }
  .commentbox input::placeholder {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #969595;
  }
  .commentbox button {
    width: 39px;
    height: 37px;
    border-radius: 10px;
    background-color: #2200ff;
    border: none;
    margin-left: 8px;
  }

  .commentshowsection {
    background-color: #fff;
    margin-top: 12px;
    padding-left: 19px;
    padding-right: 25px;
    border-radius: 10px;
    padding-bottom: 30px;
    width: 506px;
  }

  .commentsshowmoree {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
  .commentsshowmoree button {
    width: 122px;
    height: 33px;
    font-size: 16px;
    background: #2200ff;
    border: none;
    border-radius: 5px;
    color: #fff;
    font-weight: 500;
  }
  .maincomment {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .commentingtime {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #9b9a9a;
  }

  .usercomment {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    margin-top: 13px;
  }
  .commentingarea {
    background-color: #f2f2f2;
    padding: 30px;
    width: 453px;
    border-radius: 10px;
    margin-top: 25px;
    padding-top: 20px;
  }
  .imageuserforum {
    display: flex;
    justify-content: center;
    column-gap: 15px;
  }
  .forumcommentuser {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
  }
  .forumusernameres {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: left;
    color: #9b9a9a;
  }
  .noofcomments {
    font-family: Poppins;
    font-size: 21px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    padding-left: 10px;
    padding-top: 30px;
  }
  /* <!---------- index page---------------------> */
}
@media (min-width: 1351px) {
  .indexpagemediaqueryflex {
    display: flex;
    justify-content: center;
  }

  .mediaqueryindex {
    width: 1350px;
    border: none;
  }
}

/* <!--------------------- Index page (Media Query)-------------------------------------> */

/* <!--------------------- Post page (Media Query)-------------------------------------> */

@media (max-width: 358px) {
  .postpageflexconcept {
    display: flex;
    justify-content: center;
  }
  .postpagewidth {
    width: 320px;
    border: none;
  }
  .poatspagecommentsectionimage img {
    width: 28px;
    height: 28px;
    object-fit: cover;
  }
  .postpageseditsectiondivimage img {
    width: 24px;
  }

  .postpagedeletesectiondivimage img {
    width: 24px;
  }
  .postpagedeleteandedit {
    display: flex;
    justify-content: center;
    column-gap: 12px;
    align-items: center;
  }
}
@media (min-width: 359px) and (max-width: 600px) {
  .postpageflexconcept {
    display: flex;
    justify-content: center;
  }
  .postpagewidth {
    width: 360px;
    border: none;
  }
  .postpageflexconcept {
    display: flex;
    justify-content: center;
  }
  .postpagewidth {
    width: 360px;
    border: none;
  }
  .poatspagecommentsectionimage img {
    width: 28px;
    height: 28px;
    object-fit: cover;
  }
  .postpageseditsectiondivimage img {
    width: 24px;
  }

  .postpagedeletesectiondivimage img {
    width: 24px;
  }
  .postpagedeleteandedit {
    display: flex;
    justify-content: center;
    column-gap: 12px;
    align-items: center;
  }
}
@media (max-width: 900px) {
  .poatspagecommentsectionimage img {
    width: 28px;
    height: 28px;
  }
  .postpageseditsectiondivimage img {
    width: 24px;
  }

  .postpagedeletesectiondivimage img {
    width: 24px;
  }
  .postpagedeleteandedit {
    display: flex;
    justify-content: center;
    column-gap: 12px;
    align-items: center;
  }
}

@media (min-width: 951px) and (max-width: 1050px) {
  .postpagewidth {
    width: 900px;
    border: none;
  }
  .postpageflexconcept {
    display: flex;
    justify-content: center;
  }
}
@media (min-width: 1051px) and (max-width: 1150px) {
  .postpagewidth {
    width: 900px;
    border: none;
  }
  .postpageflexconcept {
    display: flex;
    justify-content: center;
  }
}
@media (min-width: 1151px) and (max-width: 1250px) {
  .postpagewidth {
    width: 900px;
    border: none;
  }
  .postpageflexconcept {
    display: flex;
    justify-content: center;
  }
}
@media (min-width: 1251px) and (max-width: 1350px) {
  .postpagewidth {
    width: 900px;
    border: none;
  }
  .postpageflexconcept {
    display: flex;
    justify-content: center;
  }
}
@media (min-width: 1351px) and (max-width: 1450px) {
  .postpagewidth {
    width: 1350px;
    border: none;
  }
  .postpageflexconcept {
    display: flex;
    justify-content: center;
  }
}
@media (min-width: 1451px) and (max-width: 1550px) {
  .postpagewidth {
    width: 1350px;
    border: none;
  }
  .postpageflexconcept {
    display: flex;
    justify-content: center;
  }
}

@media (min-width: 1551px) and (max-width: 1750px) {
  .postpagewidth {
    width: 1350px;
    border: none;
  }
  .postpageflexconcept {
    display: flex;
    justify-content: center;
  }
}

@media (min-width: 1751px) and (max-width: 2150px) {
  .postpagewidth {
    width: 1350px;
    border: none;
  }
  .postpageflexconcept {
    display: flex;
    justify-content: center;
  }
}
@media (min-width: 2051px) {
  .postpagewidth {
    width: 1350px;
    border: none;
  }
  .postpageflexconcept {
    display: flex;
    justify-content: center;
  }
}

/* <!--------------------- Post page (Media Query)-------------------------------------> */

/* <!--------------------- Welcome to Forum (Media Query)-------------------------------------> */

@media (max-width: 358px) {
  .welcometoforumflexconcept {
    display: flex;
    justify-content: center;
  }
  .forumdivmainnwidth {
    width: 320px;
    border: none;
  }
  /* <!---------------------Welcome to Forum-------------------------------------> */
  .forumone {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #d2d2d2;
    border-left: 1px solid #d2d2d2;
    border-right: 1px solid #d2d2d2;
    column-gap: 20px;
  }

  .maindivuserslist {
    margin-left: 1px;
    margin-right: 1px;
    margin-top: 0px;
  }

  .forumoneheading {
    display: flex;
    justify-content: center;
    background-color: #e1dfe8;
    border-bottom: 1px solid #d2d2d2;
    border-top: 1px solid #e1dfe8;
    column-gap: 70px;
  }

  .firstrowheading {
    display: flex;
    justify-content: flex-start;
    width: auto;
    margin-top: 25px;
    margin-bottom: 25px;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    padding-left: 0px;
    padding-right: 0px;
  }
  .firstrow {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: 82px;
    margin-top: 1px;
    margin-bottom: 1px;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
  }

  .rightwelcometoforum {
    width: 320px;
    border-radius: 10px;
    border: 2px;
    background: #fff;
    margin-top: 15px;
    padding-bottom: 50px;
    box-shadow: 0px 4px 4px 0px #0000000d;
  }

  .dateofpost {
    font-family: Poppins;
    font-size: 8px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: #bcbcbc;
  }

  .postviewdivflex {
    display: flex;
    justify-content: center;
    column-gap: 5px;
    align-items: center;
  }
  /* <!---------------------Welcome to Forum-------------------------------------> */
}
@media (min-width: 359px) and (max-width: 700px) {
  .welcometoforumflexconcept {
    display: flex;
    justify-content: center;
  }
  .forumdivmainnwidth {
    width: 360px;
    border: none;
  }
  /* <!---------------------Welcome to Forum-------------------------------------> */
  .forumone {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #d2d2d2;
    border-left: 1px solid #d2d2d2;
    border-right: 1px solid #d2d2d2;
    column-gap: 33px;
  }

  .maindivuserslist {
    margin-left: 1px;
    margin-right: 1px;
    margin-top: 0px;
  }

  .forumoneheading {
    display: flex;
    justify-content: center;
    background-color: #e1dfe8;
    border-bottom: 1px solid #d2d2d2;
    border-top: 1px solid #e1dfe8;
    column-gap: 82px;
  }

  .firstrowheading {
    display: flex;
    justify-content: flex-start;
    width: auto;
    margin-top: 25px;
    margin-bottom: 25px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    padding-left: 0px;
    padding-right: 0px;
  }
  .firstrow {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: 93px;
    margin-top: 1px;
    margin-bottom: 1px;
    font-family: Poppins;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
  }

  .rightwelcometoforum {
    width: 360px;
    border-radius: 10px;
    border: 2px;
    background: #fff;
    margin-top: 15px;
    padding-bottom: 50px;
    box-shadow: 0px 4px 4px 0px #0000000d;
  }

  .dateofpost {
    font-family: Poppins;
    font-size: 8px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: #bcbcbc;
  }

  .postviewdivflex {
    display: flex;
    justify-content: center;
    column-gap: 5px;
    align-items: center;
  }
  /* <!---------------------Welcome to Forum-------------------------------------> */
}
@media (min-width: 700px) and (max-width: 1000px) {
  .welcometoforumflexconcept {
    display: flex;
    justify-content: center;
  }
  .forumdivmainnwidth {
    width: 700px;
    border: none;
  }
  /* <!---------------------Welcome to Forum-------------------------------------> */
  .forumone {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #d2d2d2;
    border-left: 1px solid #d2d2d2;
    border-right: 1px solid #d2d2d2;
    column-gap: 33px;
  }

  .maindivuserslist {
    margin-left: 1px;
    margin-right: 1px;
    margin-top: 0px;
  }

  .forumoneheading {
    display: flex;
    justify-content: center;
    background-color: #e1dfe8;
    border-bottom: 1px solid #d2d2d2;
    border-top: 1px solid #e1dfe8;
    column-gap: 53px;
  }

  .firstrowheading {
    display: flex;
    justify-content: flex-start;
    width: 133px;
    margin-top: 25px;
    margin-bottom: 25px;
    font-family: Poppins;
    font-size: 15px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    padding-left: 0px;
    padding-right: 0px;
  }
  .firstrow {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: 150px;
    margin-top: 1px;
    margin-bottom: 1px;
    font-family: Poppins;
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
  }

  .rightwelcometoforum {
    width: 600px;
    border-radius: 10px;
    border: 2px;
    background: #fff;
    margin-top: 15px;
    padding-bottom: 50px;
    box-shadow: 0px 4px 4px 0px #0000000d;
  }

  .dateofpost {
    font-family: Poppins;
    font-size: 8px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: #bcbcbc;
  }

  .postviewdivflex {
    display: flex;
    justify-content: center;
    column-gap: 5px;
    align-items: center;
  }
  /* <!---------------------Welcome to Forum-------------------------------------> */
}

@media (min-width: 951px) and (max-width: 1050px) {
  .forumdivmainnwidth {
    width: 900px;
    border: none;
  }

  .welcometoforumflexconcept {
    display: flex;
    justify-content: center;
  }
  /* <!---------------------Welcome to Forum-------------------------------------> */
  .forumone {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid #d2d2d2;
    border-left: 1px solid #d2d2d2;
    border-right: 1px solid #d2d2d2;
  }

  .maindivuserslist {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
  }

  .forumoneheading {
    display: flex;
    justify-content: space-between;
    background-color: #e1dfe8;
    border-bottom: 1px solid #d2d2d2;
    border-top: 1px solid #e1dfe8;
  }

  .firstrowheading {
    display: flex;
    justify-content: flex-start;
    width: 160px;
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: Poppins;
    font-size: 19px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    padding-left: 25px;
    padding-right: 25px;
  }
  .firstrow {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: 137px;
    margin-top: 12px;
    margin-bottom: 12px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
  }

  .rightwelcometoforum {
    width: 572px;
    border-radius: 10px;
    border: 2px;
    background: #fff;
    margin-top: 15px;
    padding-bottom: 50px;
    box-shadow: 0px 4px 4px 0px #0000000d;
  }

  .dateofpost {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #bcbcbc;
  }

  .postviewdivflex {
    display: flex;
    justify-content: center;
    column-gap: 20px;
    align-items: center;
  }
  /* <!---------------------Welcome to Forum-------------------------------------> */

  /* <!---------------------CreateYourPost-------------------------------------> */

  .file-input__input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  /* .file-input__label {
          cursor: pointer;
          display: inline-flex;
          align-items: center;
          border-radius: 4px;
          font-size: 14px;
          font-weight: 600;
          color: #fff;
          font-size: 14px;
          padding: 10px 12px;
          background-color: #fff;
          position: absolute;
          top: 399px;
          left: 321px;
        } */

  .file-input__label svg {
    height: 16px;
    margin-right: 4px;
  }

  .uploadimagecreatepost {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #2200ff;
    margin-left: 5px;
    margin-top: 2px;
  }

  .CreateYourPost {
    font-family: Poppins;
    font-size: 32px;
    font-weight: 600;
    line-height: 48px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    margin-left: 50px;
  }

  .createpostfunction {
    display: flex;
    justify-content: center;
    column-gap: 30px;
    margin-top: 15px;
  }

  .rightcreatepost {
    width: 951px;
    border-radius: 10px;
    border: 2px;
    background: #f2f1f8;
  }

  .createpostdiv {
    width: 555px;
    border-radius: 10px;
    border: 2px;
    background-color: #fff;
    height: auto;
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0;
  }

  label[for="createpost"] {
    position: relative;
    display: block;
    width: 320px;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
    background-color: #eeedf2;
  }

  label[for="createpost"]::after {
    content: " ";
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
    height: 100%;
    display: block;
    font-family: Poppins;
    background: #eeedf2
      url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOBAMAAAGq7RFVAAAAJ1BMVEX///////////////////////////////////////////////////9Ruv0SAAAADHRSTlMACAoREoKDt7y9zc5qtZoyAAAAcklEQVQIHQXBsQnCUAAFwFOCCKbIABaWERzi1yaFAzmEpHYEC8GUKVQEkbyhvOOkZqdWt0/QYKqLzQeF7dv6y/RLwa2wYtk4Xxxe+t8yd1WuM/rcUc2gYHEeoP02umSgTR50ybBPRuiSZAS6ZAQ4jgCAPyjYJiRH4fymAAAAAElFTkSuQmCC")
      no-repeat center center;
    pointer-events: none;
    color: black;
  }

  label[for="createpost"] select {
    height: 43px;
    width: 100%;
    border: none;
    background: #eeedf2;
    padding: 7px 20px 7px 20px;
    font-size: 16px;
    font-family: monospace;
    cursor: pointer;
    font-family: Poppins;
  }

  label[for="createpost"] select::-ms-expand {
    display: none;
  }

  label[for="createpost"] :-moz-any(select) {
    width: 110%;
  }

  label[for="createpost"].ie9 select {
    width: 110%;
  }

  label[for="createpost"] select:focus {
    outline: 1px dotted #a9a9a9;
  }

  .cforumtopics {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 0px;
    padding-left: 50px;
    padding-right: 54px;
    margin-top: 30px;
  }

  .cheadingtopics {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 0px;
    padding-left: 50px;
    padding-right: 54px;
    margin-top: 30px;
  }
  .ctextareapostcreate {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    column-gap: 50px;
    padding-left: 105px;
    padding-right: 70px;
    margin-top: 30px;
    position: relative;
  }
  .cinputforumcreatepost input {
    width: 297px;
    height: 43px;
    border-radius: 10px;
    background-color: #eeedf2;
    border: none;
    font-family: Poppins;
    font-size: 15px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    padding-left: 20px;
    color: #000000;
  }
  .cinputforumcreatepost input::placeholder {
    color: #000000;
  }

  .textareacreatepost textarea {
    width: 562px;
    height: 297px;
    background-color: #eeedf2;
    border: none;
    border-radius: 10px;
  }

  .headingcreatepost {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
  }
  .textareacreatepost textarea {
    font-family: Poppins;
    padding-left: 20px;
    padding-top: 15px;
    font-size: 16px;
  }

  .photouploadsimagers {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
    background-color: #ffffff;
    border: none;
    width: 88px;
    height: 33px;
    border-radius: 7px;
    position: absolute;
    top: 411px;
    left: 319px;
  }
  #file {
    display: none;
  }
  .formuploadflex {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 5px;
  }

  .uploadtexts {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #2200ff;
  }
  .postionofimage {
    position: relative;
  }

  .buttonsubmit button {
    width: 149px;
    height: 40px;
    background-color: #2200ff;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    border: none;
    text-align: center;
    color: #ffffff;
    border-radius: 10px;
    margin-left: 191px;
    margin-top: 20px;
  }
  .buttonsubmit {
    padding-bottom: 50px;
  }

  .fileuploadareainput {
    background-color: transparent;
    border: none;
  }

  /* <!---------------------CreateYourPost-------------------------------------> */
}

@media (min-width: 1051px) and (max-width: 1150px) {
  .forumdivmainnwidth {
    width: 900px;
    border: none;
  }

  .welcometoforumflexconcept {
    display: flex;
    justify-content: center;
  }
  /* <!---------------------Welcome to Forum-------------------------------------> */
  .forumone {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid #d2d2d2;
    border-left: 1px solid #d2d2d2;
    border-right: 1px solid #d2d2d2;
  }

  .maindivuserslist {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
  }

  .forumoneheading {
    display: flex;
    justify-content: space-between;
    background-color: #e1dfe8;
    border-bottom: 1px solid #d2d2d2;
    border-top: 1px solid #e1dfe8;
  }

  .firstrowheading {
    display: flex;
    justify-content: flex-start;
    width: 160px;
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: Poppins;
    font-size: 19px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    padding-left: 25px;
    padding-right: 25px;
  }
  .firstrow {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: 137px;
    margin-top: 12px;
    margin-bottom: 12px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
  }

  .rightwelcometoforum {
    width: 572px;
    border-radius: 10px;
    border: 2px;
    background: #fff;
    margin-top: 15px;
    padding-bottom: 50px;
    box-shadow: 0px 4px 4px 0px #0000000d;
  }

  .dateofpost {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #bcbcbc;
  }

  .postviewdivflex {
    display: flex;
    justify-content: center;
    column-gap: 20px;
    align-items: center;
  }
  /* <!---------------------Welcome to Forum-------------------------------------> */

  /* <!---------------------CreateYourPost-------------------------------------> */
  .file-input__input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  /* .file-input__label {
          cursor: pointer;
          display: inline-flex;
          align-items: center;
          border-radius: 4px;
          font-size: 14px;
          font-weight: 600;
          color: #fff;
          font-size: 14px;
          padding: 10px 12px;
          background-color: #fff;
          position: absolute;
          top: 399px;
          left: 321px;
        } */

  .file-input__label svg {
    height: 16px;
    margin-right: 4px;
  }

  .uploadimagecreatepost {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #2200ff;
    margin-left: 5px;
    margin-top: 2px;
  }

  .CreateYourPost {
    font-family: Poppins;
    font-size: 32px;
    font-weight: 600;
    line-height: 48px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    margin-left: 50px;
  }

  .createpostfunction {
    display: flex;
    justify-content: center;
    column-gap: 30px;
    margin-top: 15px;
  }

  .rightcreatepost {
    width: 951px;
    border-radius: 10px;
    border: 2px;
    background: #f2f1f8;
  }

  .createpostdiv {
    width: 555px;
    border-radius: 10px;
    border: 2px;
    background-color: #fbfbfb;
    height: auto;
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0;
  }

  label[for="createpost"] {
    position: relative;
    display: block;
    width: 320px;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
    background-color: #eeedf2;
  }

  label[for="createpost"]::after {
    content: " ";
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
    height: 100%;
    display: block;
    font-family: Poppins;
    background: #eeedf2
      url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOBAMAAAGq7RFVAAAAJ1BMVEX///////////////////////////////////////////////////9Ruv0SAAAADHRSTlMACAoREoKDt7y9zc5qtZoyAAAAcklEQVQIHQXBsQnCUAAFwFOCCKbIABaWERzi1yaFAzmEpHYEC8GUKVQEkbyhvOOkZqdWt0/QYKqLzQeF7dv6y/RLwa2wYtk4Xxxe+t8yd1WuM/rcUc2gYHEeoP02umSgTR50ybBPRuiSZAS6ZAQ4jgCAPyjYJiRH4fymAAAAAElFTkSuQmCC")
      no-repeat center center;
    pointer-events: none;
    color: black;
  }

  label[for="createpost"] select {
    height: 43px;
    width: 100%;
    border: none;
    background: #eeedf2;
    padding: 7px 20px 7px 20px;
    font-size: 16px;
    font-family: monospace;
    cursor: pointer;
    font-family: Poppins;
  }

  label[for="createpost"] select::-ms-expand {
    display: none;
  }

  label[for="createpost"] :-moz-any(select) {
    width: 110%;
  }

  label[for="createpost"].ie9 select {
    width: 110%;
  }

  label[for="createpost"] select:focus {
    outline: 1px dotted #a9a9a9;
  }

  .cforumtopics {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 0px;
    padding-left: 50px;
    padding-right: 54px;
    margin-top: 30px;
  }

  .cheadingtopics {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 0px;
    padding-left: 50px;
    padding-right: 54px;
    margin-top: 30px;
  }
  .ctextareapostcreate {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    column-gap: 50px;
    padding-left: 105px;
    padding-right: 70px;
    margin-top: 30px;
    position: relative;
  }
  .cinputforumcreatepost input {
    width: 297px;
    height: 43px;
    border-radius: 10px;
    background-color: #eeedf2;
    border: none;
    font-family: Poppins;
    font-size: 15px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    padding-left: 20px;
    color: #000000;
  }
  .cinputforumcreatepost input::placeholder {
    color: #000000;
  }

  .textareacreatepost textarea {
    width: 562px;
    height: 297px;
    background-color: #eeedf2;
    border: none;
    border-radius: 10px;
  }

  .headingcreatepost {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
  }
  .textareacreatepost textarea {
    font-family: Poppins;
    padding-left: 20px;
    padding-top: 15px;
    font-size: 16px;
  }

  .photouploadsimagers {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
    background-color: #ffffff;
    border: none;
    width: 88px;
    height: 33px;
    border-radius: 7px;
    position: absolute;
    top: 411px;
    left: 319px;
  }
  #file {
    display: none;
  }
  .formuploadflex {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 5px;
  }

  .uploadtexts {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #2200ff;
  }
  .postionofimage {
    position: relative;
  }

  .buttonsubmit button {
    width: 149px;
    height: 40px;
    background-color: #2200ff;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    border: none;
    text-align: center;
    color: #ffffff;
    border-radius: 10px;
    margin-left: 191px;
    margin-top: 20px;
  }
  .buttonsubmit {
    padding-bottom: 50px;
  }

  .fileuploadareainput {
    background-color: transparent;
    border: none;
  }

  /* <!---------------------CreateYourPost-------------------------------------> */
}
@media (min-width: 1151px) and (max-width: 1250px) {
  .forumdivmainnwidth {
    width: 900px;
    border: none;
  }

  .welcometoforumflexconcept {
    display: flex;
    justify-content: center;
  }
  /* <!---------------------Welcome to Forum-------------------------------------> */
  .forumone {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid #d2d2d2;
    border-left: 1px solid #d2d2d2;
    border-right: 1px solid #d2d2d2;
  }

  .maindivuserslist {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
  }

  .forumoneheading {
    display: flex;
    justify-content: space-between;
    background-color: #e1dfe8;
    border-bottom: 1px solid #d2d2d2;
    border-top: 1px solid #e1dfe8;
  }

  .firstrowheading {
    display: flex;
    justify-content: flex-start;
    width: 160px;
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: Poppins;
    font-size: 19px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    padding-left: 25px;
    padding-right: 25px;
  }
  .firstrow {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: 137px;
    margin-top: 12px;
    margin-bottom: 12px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
  }

  .rightwelcometoforum {
    width: 572px;
    border-radius: 10px;
    border: 2px;
    background: #fff;
    margin-top: 15px;
    padding-bottom: 50px;
    box-shadow: 0px 4px 4px 0px #0000000d;
  }

  .dateofpost {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #bcbcbc;
  }

  .postviewdivflex {
    display: flex;
    justify-content: center;
    column-gap: 20px;
    align-items: center;
  }
  /* <!---------------------Welcome to Forum-------------------------------------> */

  /* <!---------------------CreateYourPost-------------------------------------> */
  .file-input__input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  /* .file-input__label {
          cursor: pointer;
          display: inline-flex;
          align-items: center;
          border-radius: 4px;
          font-size: 14px;
          font-weight: 600;
          color: #fff;
          font-size: 14px;
          padding: 10px 12px;
          background-color: #fff;
          position: absolute;
          top: 399px;
          left: 321px;
        } */

  .file-input__label svg {
    height: 16px;
    margin-right: 4px;
  }

  .uploadimagecreatepost {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #2200ff;
    margin-left: 5px;
    margin-top: 2px;
  }

  .CreateYourPost {
    font-family: Poppins;
    font-size: 32px;
    font-weight: 600;
    line-height: 48px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    margin-left: 50px;
  }

  .createpostfunction {
    display: flex;
    justify-content: center;
    column-gap: 30px;
    margin-top: 15px;
  }

  .rightcreatepost {
    width: 951px;
    border-radius: 10px;
    border: 2px;
    background: #f2f1f8;
  }

  .createpostdiv {
    width: 555px;
    border-radius: 10px;
    border: 2px;
    background-color: #fbfbfb;
    height: auto;
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0;
  }

  label[for="createpost"] {
    position: relative;
    display: block;
    width: 320px;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
    background-color: #eeedf2;
  }

  label[for="createpost"]::after {
    content: " ";
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
    height: 100%;
    display: block;
    font-family: Poppins;
    background: #eeedf2
      url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOBAMAAAGq7RFVAAAAJ1BMVEX///////////////////////////////////////////////////9Ruv0SAAAADHRSTlMACAoREoKDt7y9zc5qtZoyAAAAcklEQVQIHQXBsQnCUAAFwFOCCKbIABaWERzi1yaFAzmEpHYEC8GUKVQEkbyhvOOkZqdWt0/QYKqLzQeF7dv6y/RLwa2wYtk4Xxxe+t8yd1WuM/rcUc2gYHEeoP02umSgTR50ybBPRuiSZAS6ZAQ4jgCAPyjYJiRH4fymAAAAAElFTkSuQmCC")
      no-repeat center center;
    pointer-events: none;
    color: black;
  }

  label[for="createpost"] select {
    height: 43px;
    width: 100%;
    border: none;
    background: #eeedf2;
    padding: 7px 20px 7px 20px;
    font-size: 16px;
    font-family: monospace;
    cursor: pointer;
    font-family: Poppins;
  }

  label[for="createpost"] select::-ms-expand {
    display: none;
  }

  label[for="createpost"] :-moz-any(select) {
    width: 110%;
  }

  label[for="createpost"].ie9 select {
    width: 110%;
  }

  label[for="createpost"] select:focus {
    outline: 1px dotted #a9a9a9;
  }

  .cforumtopics {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 0px;
    padding-left: 50px;
    padding-right: 54px;
    margin-top: 30px;
  }

  .cheadingtopics {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 0px;
    padding-left: 50px;
    padding-right: 54px;
    margin-top: 30px;
  }
  .ctextareapostcreate {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    column-gap: 50px;
    padding-left: 105px;
    padding-right: 70px;
    margin-top: 30px;
    position: relative;
  }
  .cinputforumcreatepost input {
    width: 297px;
    height: 43px;
    border-radius: 10px;
    background-color: #eeedf2;
    border: none;
    font-family: Poppins;
    font-size: 15px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    padding-left: 20px;
    color: #000000;
  }
  .cinputforumcreatepost input::placeholder {
    color: #000000;
  }

  .textareacreatepost textarea {
    width: 562px;
    height: 297px;
    background-color: #eeedf2;
    border: none;
    border-radius: 10px;
  }

  .headingcreatepost {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
  }
  .textareacreatepost textarea {
    font-family: Poppins;
    padding-left: 20px;
    padding-top: 15px;
    font-size: 16px;
  }

  .photouploadsimagers {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
    background-color: #ffffff;
    border: none;
    width: 88px;
    height: 33px;
    border-radius: 7px;
    position: absolute;
    top: 411px;
    left: 319px;
  }
  #file {
    display: none;
  }
  .formuploadflex {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 5px;
  }

  .uploadtexts {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #2200ff;
  }
  .postionofimage {
    position: relative;
  }

  .buttonsubmit button {
    width: 149px;
    height: 40px;
    background-color: #2200ff;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    border: none;
    text-align: center;
    color: #ffffff;
    border-radius: 10px;
    margin-left: 191px;
    margin-top: 20px;
  }
  .buttonsubmit {
    padding-bottom: 50px;
  }

  .fileuploadareainput {
    background-color: transparent;
    border: none;
  }

  /* <!---------------------CreateYourPost-------------------------------------> */
}
@media (min-width: 1251px) and (max-width: 1350px) {
  .forumdivmainnwidth {
    width: 900px;
    border: none;
  }

  .welcometoforumflexconcept {
    display: flex;
    justify-content: center;
  }
  /* <!---------------------Welcome to Forum-------------------------------------> */
  .forumone {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid #d2d2d2;
    border-left: 1px solid #d2d2d2;
    border-right: 1px solid #d2d2d2;
  }

  .maindivuserslist {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
  }

  .forumoneheading {
    display: flex;
    justify-content: space-between;
    background-color: #e1dfe8;
    border-bottom: 1px solid #d2d2d2;
    border-top: 1px solid #e1dfe8;
  }

  .firstrowheading {
    display: flex;
    justify-content: flex-start;
    width: 160px;
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: Poppins;
    font-size: 19px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    padding-left: 25px;
    padding-right: 25px;
  }
  .firstrow {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: 137px;
    margin-top: 12px;
    margin-bottom: 12px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
  }

  .rightwelcometoforum {
    width: 572px;
    border-radius: 10px;
    border: 2px;
    background: #fff;
    margin-top: 15px;
    padding-bottom: 50px;
    box-shadow: 0px 4px 4px 0px #0000000d;
  }

  .dateofpost {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #bcbcbc;
  }

  .postviewdivflex {
    display: flex;
    justify-content: center;
    column-gap: 20px;
    align-items: center;
  }
  /* <!---------------------Welcome to Forum-------------------------------------> */

  /* <!---------------------CreateYourPost-------------------------------------> */
  .file-input__input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  /* .file-input__label {
          cursor: pointer;
          display: inline-flex;
          align-items: center;
          border-radius: 4px;
          font-size: 14px;
          font-weight: 600;
          color: #fff;
          font-size: 14px;
          padding: 10px 12px;
          background-color: #fff;
          position: absolute;
          top: 399px;
          left: 321px;
        } */

  .file-input__label svg {
    height: 16px;
    margin-right: 4px;
  }

  .uploadimagecreatepost {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #2200ff;
    margin-left: 5px;
    margin-top: 2px;
  }

  .CreateYourPost {
    font-family: Poppins;
    font-size: 32px;
    font-weight: 600;
    line-height: 48px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    margin-left: 50px;
  }

  .createpostfunction {
    display: flex;
    justify-content: center;
    column-gap: 30px;
    margin-top: 15px;
  }

  .rightcreatepost {
    width: 951px;
    border-radius: 10px;
    border: 2px;
    background: #f2f1f8;
  }

  .createpostdiv {
    width: 555px;
    border-radius: 10px;
    border: 2px;
    background-color: #fbfbfb;
    height: auto;
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0;
  }

  label[for="createpost"] {
    position: relative;
    display: block;
    width: 320px;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
    background-color: #eeedf2;
  }

  label[for="createpost"]::after {
    content: " ";
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
    height: 100%;
    display: block;
    font-family: Poppins;
    background: #eeedf2
      url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOBAMAAAGq7RFVAAAAJ1BMVEX///////////////////////////////////////////////////9Ruv0SAAAADHRSTlMACAoREoKDt7y9zc5qtZoyAAAAcklEQVQIHQXBsQnCUAAFwFOCCKbIABaWERzi1yaFAzmEpHYEC8GUKVQEkbyhvOOkZqdWt0/QYKqLzQeF7dv6y/RLwa2wYtk4Xxxe+t8yd1WuM/rcUc2gYHEeoP02umSgTR50ybBPRuiSZAS6ZAQ4jgCAPyjYJiRH4fymAAAAAElFTkSuQmCC")
      no-repeat center center;
    pointer-events: none;
    color: black;
  }

  label[for="createpost"] select {
    height: 43px;
    width: 100%;
    border: none;
    background: #eeedf2;
    padding: 7px 20px 7px 20px;
    font-size: 16px;
    font-family: monospace;
    cursor: pointer;
    font-family: Poppins;
  }

  label[for="createpost"] select::-ms-expand {
    display: none;
  }

  label[for="createpost"] :-moz-any(select) {
    width: 110%;
  }

  label[for="createpost"].ie9 select {
    width: 110%;
  }

  label[for="createpost"] select:focus {
    outline: 1px dotted #a9a9a9;
  }

  .cforumtopics {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 0px;
    padding-left: 50px;
    padding-right: 54px;
    margin-top: 30px;
  }

  .cheadingtopics {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 0px;
    padding-left: 50px;
    padding-right: 54px;
    margin-top: 30px;
  }
  .ctextareapostcreate {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    column-gap: 50px;
    padding-left: 105px;
    padding-right: 70px;
    margin-top: 30px;
    position: relative;
  }
  .cinputforumcreatepost input {
    width: 297px;
    height: 43px;
    border-radius: 10px;
    background-color: #eeedf2;
    border: none;
    font-family: Poppins;
    font-size: 15px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    padding-left: 20px;
    color: #000000;
  }
  .cinputforumcreatepost input::placeholder {
    color: #000000;
  }

  .textareacreatepost textarea {
    width: 562px;
    height: 297px;
    background-color: #eeedf2;
    border: none;
    border-radius: 10px;
  }

  .headingcreatepost {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
  }
  .textareacreatepost textarea {
    font-family: Poppins;
    padding-left: 20px;
    padding-top: 15px;
    font-size: 16px;
  }

  .photouploadsimagers {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
    background-color: #ffffff;
    border: none;
    width: 88px;
    height: 33px;
    border-radius: 7px;
    position: absolute;
    top: 411px;
    left: 319px;
  }
  #file {
    display: none;
  }
  .formuploadflex {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 5px;
  }

  .uploadtexts {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #2200ff;
  }
  .postionofimage {
    position: relative;
  }

  .buttonsubmit button {
    width: 149px;
    height: 40px;
    background-color: #2200ff;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    border: none;
    text-align: center;
    color: #ffffff;
    border-radius: 10px;
    margin-left: 191px;
    margin-top: 20px;
  }
  .buttonsubmit {
    padding-bottom: 50px;
  }

  .fileuploadareainput {
    background-color: transparent;
    border: none;
  }

  /* <!---------------------CreateYourPost-------------------------------------> */
}

@media (min-width: 1351px) {
  .forumdivmainnwidth {
    width: 1350px;
    border: none;
  }

  .welcometoforumflexconcept {
    display: flex;
    justify-content: center;
  }
}

/* <!--------------------- Welcome to Forum (Media Query)-------------------------------------> */

/* <!--------------------- Create Post (Media Query)-------------------------------------> */

@media (max-width: 358px) {
  .flexofcreatepost {
    display: flex;
    justify-content: center;
  }
  .widthofcreatepost {
    width: 320px;
    border: none;
  }
  /* <!---------------------CreateYourPost-------------------------------------> */
  .file-input__input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  .file-input__label {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    font-size: 14px;
    padding: 8px 4px;
    background-color: #eeedf2;
    top: 399px;
    left: 321px;
    height: 17px;
    width: 118px;
    margin-top: 10px;
  }

  .file-input__label svg {
    height: 16px;
    margin-right: 4px;
  }

  .uploadimagecreatepost {
    font-family: Poppins;
    font-size: 10px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: #2200ff;
    margin-left: 5px;
    margin-top: 2px;
  }

  .createpostdivwithnavigationflex {
    display: flex;
    align-items: baseline;
    margin-top: 20px;
  }
  .CreateYourPost {
    font-family: Poppins;
    font-size: 23px;
    font-weight: 600;
    line-height: 48px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    margin-left: 30px;
  }

  .createpostfunction {
    display: flex;
    justify-content: center;
    column-gap: 30px;
    margin-top: 30px;
  }

  .rightcreatepost {
    width: 951px;
    border-radius: 10px;
    border: 2px;
    background: #f2f1f8;
  }

  .createpostdiv {
    width: 320px;
    border-radius: 10px;
    border: 2px;
    background-color: #fff;
    height: auto;
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0;
  }

  label[for="createpost"] {
    position: relative;
    display: block;
    width: 252px;
    border-radius: 3px;
    overflow: hidden;
    cursor: pointer;
    background-color: #eeedf2;
    margin-top: 12px;
  }

  label[for="createpost"]::after {
    content: " ";
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
    height: 100%;
    display: block;
    font-family: Poppins;
    background: #eeedf2
      url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOBAMAAAGq7RFVAAAAJ1BMVEX///////////////////////////////////////////////////9Ruv0SAAAADHRSTlMACAoREoKDt7y9zc5qtZoyAAAAcklEQVQIHQXBsQnCUAAFwFOCCKbIABaWERzi1yaFAzmEpHYEC8GUKVQEkbyhvOOkZqdWt0/QYKqLzQeF7dv6y/RLwa2wYtk4Xxxe+t8yd1WuM/rcUc2gYHEeoP02umSgTR50ybBPRuiSZAS6ZAQ4jgCAPyjYJiRH4fymAAAAAElFTkSuQmCC")
      no-repeat center center;
    pointer-events: none;
    color: black;
  }

  label[for="createpost"] select {
    height: 30px;
    width: 100%;
    border: none;
    background: #eeedf2;
    padding: 3px 20px 3px 20px;
    font-size: 12px;
    font-family: monospace;
    cursor: pointer;
    font-family: Poppins;
  }

  label[for="createpost"] select::-ms-expand {
    display: none;
  }

  label[for="createpost"] :-moz-any(select) {
    width: 110%;
  }

  label[for="createpost"].ie9 select {
    width: 110%;
  }

  label[for="createpost"] select:focus {
    outline: 1px dotted #a9a9a9;
  }

  .cforumtopics {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    column-gap: 50px;
    padding-left: 28px;
    padding-right: 10px;
    margin-top: 30px;
    flex-direction: column;
  }

  .cheadingtopics {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    column-gap: 50px;
    padding-left: 28px;
    padding-right: 10px;
    margin-top: 25px;
    flex-direction: column;
  }
  .ctextareapostcreate {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    column-gap: 50px;
    padding-left: 10px;
    padding-right: 70px;
    margin-top: 30px;
    position: relative;
  }
  .cinputforumcreatepost input {
    width: 230px;
    height: 30px;
    border-radius: 3px;
    background-color: #eeedf2;
    border: none;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    padding-left: 20px;
    color: #000000;
    margin-top: 10px;
  }
  .cinputforumcreatepost input::placeholder {
    color: #000000;
    font-size: 12px;
    line-height: 18px;
  }

  .textareacreatepost textarea {
    width: 252px;
    height: 297px;
    background-color: #eeedf2;
    border: none;
    border-radius: 10px;
  }

  .headingcreatepost {
    font-family: Poppins;
    font-size: 10px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0em;
    color: #000000;
  }
  .textareacreatepost textarea {
    font-family: Poppins;
    padding-left: 20px;
    padding-top: 15px;
    font-size: 16px;
  }

  .photouploadsimagers {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
    background-color: #ffffff;
    border: none;
    width: 88px;
    height: 33px;
    border-radius: 7px;
    position: absolute;
    top: 411px;
    left: 319px;
  }
  #file {
    display: none;
  }
  .formuploadflex {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 5px;
  }

  .uploadtexts {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #2200ff;
  }
  .postionofimage {
    position: relative;
  }

  .buttonsubmit button {
    width: 173px;
    height: 40px;
    background-color: #2200ff;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    border: none;
    text-align: center;
    color: #ffffff;
    border-radius: 5px;
    margin-left: 27px;
    margin-top: 18px;
  }
  .buttonsubmit {
    padding-bottom: 50px;
  }

  .fileuploadareainput {
    background-color: transparent;
    border: none;
  }

  /*-----------Recently Added--------------*/

  .previewwimagesizee {
    width: 200px;
    height: 78px;
    margin-top: 9px;
  }
  .recommenededsizees {
    font-family: Poppins;
    font-size: 8px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #8c8484;
    margin-top: 8px;
  }

  .thumbnailtextscreatepost {
    font-family: Poppins;
    font-size: 10px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
  }

  .thumbnailsecrionforcreatepost {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    column-gap: 50px;
    padding-left: 28px;
    padding-right: 20px;
    margin-top: 30px;
    flex-direction: column;
  }

  .thumbnailsecrionforcreatepost11 {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    column-gap: 50px;
    padding-left: 28px;
    padding-right: 0px;
    margin-top: 20px;
    flex-direction: column;
  }

  .imagethumbnailpreviewdivtag {
    display: flex;
    justify-content: center;
    column-gap: 20px;
    align-items: flex-start;
    flex-direction: column;
  }

  .recomennededsizeforphoto {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #8c8484;
    margin-left: 303px;
    margin-top: 10px;
  }

  .breiefdescriptionnamee {
    font-family: Poppins;
    font-size: 10px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
  }

  .breifdescriptionzsflex {
    display: flex;
    padding-left: 28px;
    margin-top: 30px;
    flex-direction: column;
  }

  .breifedescriptiontrextareabox {
    padding-left: 0px;
  }
  .breifedescriptiontrextareabox textarea {
    width: 235px;
    background-color: #eeedf2;
    border: none;
    border-radius: 5px;
    height: 100px;
    padding: 10px;
    padding-left: 20px;
    color: #9d9d9d;
    font-size: 10px;
    line-height: 15px;
    margin-top: 10px;
  }

  .breifedescriptiontrextareabox textarea::placeholder {
    font-family: Poppins;
    font-size: 10px;
    line-height: 15px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
  }

  /*-----------Recently Added--------------*/

  /* <!---------------------CreateYourPost-------------------------------------> */
}
@media (min-width: 359px) and (max-width: 700px) {
  .flexofcreatepost {
    display: flex;
    justify-content: center;
  }
  .widthofcreatepost {
    width: 360px;
    border: none;
  }
  /* <!---------------------CreateYourPost-------------------------------------> */
  .file-input__input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  .file-input__label {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    font-size: 14px;
    padding: 8px 4px;
    background-color: #eeedf2;
    top: 399px;
    left: 321px;
    height: 17px;
    width: 118px;
    margin-top: 10px;
  }

  .file-input__label svg {
    height: 16px;
    margin-right: 4px;
  }

  .uploadimagecreatepost {
    font-family: Poppins;
    font-size: 10px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: #2200ff;
    margin-left: 5px;
    margin-top: 2px;
  }

  .createpostdivwithnavigationflex {
    display: flex;
    align-items: baseline;
    margin-top: 20px;
  }
  .CreateYourPost {
    font-family: Poppins;
    font-size: 23px;
    font-weight: 600;
    line-height: 48px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    margin-left: 30px;
  }

  .createpostfunction {
    display: flex;
    justify-content: center;
    column-gap: 30px;
    margin-top: 30px;
  }

  .rightcreatepost {
    width: 951px;
    border-radius: 10px;
    border: 2px;
    background: #f2f1f8;
  }

  .UploadNamegdfhfubdif {
    height: 32px;
  }

  .createpostdiv {
    width: 360px;
    border-radius: 10px;
    border: 2px;
    background-color: #fff;
    height: auto;
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0;
  }

  label[for="createpost"] {
    position: relative;
    display: block;
    width: 307px;
    border-radius: 3px;
    overflow: hidden;
    cursor: pointer;
    background-color: #eeedf2;
    margin-top: 12px;
  }

  label[for="createpost"]::after {
    content: " ";
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
    height: 100%;
    display: block;
    font-family: Poppins;
    background: #eeedf2
      url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOBAMAAAGq7RFVAAAAJ1BMVEX///////////////////////////////////////////////////9Ruv0SAAAADHRSTlMACAoREoKDt7y9zc5qtZoyAAAAcklEQVQIHQXBsQnCUAAFwFOCCKbIABaWERzi1yaFAzmEpHYEC8GUKVQEkbyhvOOkZqdWt0/QYKqLzQeF7dv6y/RLwa2wYtk4Xxxe+t8yd1WuM/rcUc2gYHEeoP02umSgTR50ybBPRuiSZAS6ZAQ4jgCAPyjYJiRH4fymAAAAAElFTkSuQmCC")
      no-repeat center center;
    pointer-events: none;
    color: black;
  }

  label[for="createpost"] select {
    height: 34px;
    width: 100%;
    border: none;
    background: #eeedf2;
    padding: 3px 20px 3px 20px;
    font-size: 12px;
    font-family: monospace;
    cursor: pointer;
    font-family: Poppins;
  }

  label[for="createpost"] select::-ms-expand {
    display: none;
  }

  label[for="createpost"] :-moz-any(select) {
    width: 110%;
  }

  label[for="createpost"].ie9 select {
    width: 110%;
  }

  label[for="createpost"] select:focus {
    outline: 1px dotted #a9a9a9;
  }

  .cforumtopics {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    column-gap: 50px;
    padding-left: 20px;
    padding-right: 10px;
    margin-top: 30px;
    flex-direction: column;
  }

  .cheadingtopics {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    column-gap: 50px;
    padding-left: 20px;
    padding-right: 10px;
    margin-top: 25px;
    flex-direction: column;
  }
  .ctextareapostcreate {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    column-gap: 50px;
    padding-left: 10px;
    padding-right: 70px;
    margin-top: 30px;
    position: relative;
  }
  .cinputforumcreatepost input {
    width: 286px;
    height: 34px;
    border-radius: 3px;
    background-color: #eeedf2;
    border: none;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    padding-left: 20px;
    color: #000000;
    margin-top: 10px;
  }
  .cinputforumcreatepost input::placeholder {
    color: #000000;
    font-size: 12px;
    line-height: 18px;
  }

  .textareacreatepost textarea {
    width: 252px;
    height: 297px;
    background-color: #eeedf2;
    border: none;
    border-radius: 10px;
  }

  .headingcreatepost {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0em;
    color: #000000;
  }
  .textareacreatepost textarea {
    font-family: Poppins;
    padding-left: 20px;
    padding-top: 15px;
    font-size: 16px;
  }

  .photouploadsimagers {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
    background-color: #ffffff;
    border: none;
    width: 88px;
    height: 33px;
    border-radius: 7px;
    position: absolute;
    top: 411px;
    left: 319px;
  }
  #file {
    display: none;
  }
  .formuploadflex {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 5px;
  }

  .uploadtexts {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #2200ff;
  }
  .postionofimage {
    position: relative;
  }

  .buttonsubmit button {
    width: 173px;
    height: 40px;
    background-color: #2200ff;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    border: none;
    text-align: center;
    color: #ffffff;
    border-radius: 5px;
    margin-left: 27px;
    margin-top: 18px;
  }
  .buttonsubmit {
    padding-bottom: 50px;
  }

  .fileuploadareainput {
    background-color: transparent;
    border: none;
  }

  /*-----------Recently Added--------------*/

  .previewwimagesizee {
    width: 200px;
    height: 78px;
    margin-top: 9px;
  }
  .recommenededsizees {
    font-family: Poppins;
    font-size: 8px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #8c8484;
    margin-top: 8px;
  }

  .thumbnailtextscreatepost {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
  }

  .thumbnailsecrionforcreatepost {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    column-gap: 50px;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 30px;
    flex-direction: column;
  }

  .thumbnailsecrionforcreatepost11 {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    column-gap: 50px;
    padding-left: 20px;
    padding-right: 0px;
    margin-top: 20px;
    flex-direction: column;
  }

  .imagethumbnailpreviewdivtag {
    display: flex;
    justify-content: center;
    column-gap: 20px;
    align-items: flex-start;
    flex-direction: column;
  }

  .recomennededsizeforphoto {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #8c8484;
    margin-left: 303px;
    margin-top: 10px;
  }

  .breiefdescriptionnamee {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
  }

  .breifdescriptionzsflex {
    display: flex;
    padding-left: 20px;
    margin-top: 30px;
    flex-direction: column;
  }

  .breifedescriptiontrextareabox {
    padding-left: 0px;
  }
  .breifedescriptiontrextareabox textarea {
    width: 280px;
    background-color: #eeedf2;
    border: none;
    border-radius: 5px;
    height: 130px;
    padding: 11px;
    padding-left: 20px;
    color: black;
    font-size: 12px;
    line-height: 18px;
    margin-top: 10px;
  }

  .breifedescriptiontrextareabox textarea::placeholder {
    font-family: Poppins;
    font-size: 9px;
    line-height: 18px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    padding-top: 0px;
  }

  /*-----------Recently Added--------------*/

  /* <!---------------------CreateYourPost-------------------------------------> */
}
@media (min-width: 700px) and (max-width: 951px) {
  .flexofcreatepost {
    display: flex;
    justify-content: center;
  }
  .widthofcreatepost {
    width: 700px;
    border: none;
  }
  /* <!---------------------CreateYourPost-------------------------------------> */
  .file-input__input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  .file-input__label {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    font-size: 14px;
    padding: 8px 4px;
    background-color: #eeedf2;
    top: 399px;
    left: 321px;
    height: 30px;
    width: 200px;
    margin-top: 10px;
  }

  .file-input__label svg {
    height: 16px;
    margin-right: 4px;
  }

  .uploadimagecreatepost {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #2200ff;
    margin-left: 5px;
    margin-top: 2px;
  }

  .createpostdivwithnavigationflex {
    display: flex;
    align-items: baseline;
    margin-top: 20px;
  }
  .CreateYourPost {
    font-family: Poppins;
    font-size: 23px;
    font-weight: 600;
    line-height: 48px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    margin-left: 30px;
  }

  .createpostfunction {
    display: flex;
    justify-content: center;
    column-gap: 30px;
    margin-top: 30px;
  }

  .rightcreatepost {
    width: 100%;
    border-radius: 10px;
    border: 2px;
    background: #f2f1f8;
  }

  .UploadNamegdfhfubdif {
    height: 49px;
  }

  .createpostdiv {
    width: 700px;
    border-radius: 10px;
    border: 2px;
    background-color: #fff;
    height: auto;
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0;
  }

  label[for="createpost"] {
    position: relative;
    display: block;
    width: 572px;
    border-radius: 3px;
    overflow: hidden;
    cursor: pointer;
    background-color: #eeedf2;
    margin-top: 12px;
  }

  label[for="createpost"]::after {
    content: " ";
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
    height: 100%;
    display: block;
    font-family: Poppins;
    background: #eeedf2
      url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOBAMAAAGq7RFVAAAAJ1BMVEX///////////////////////////////////////////////////9Ruv0SAAAADHRSTlMACAoREoKDt7y9zc5qtZoyAAAAcklEQVQIHQXBsQnCUAAFwFOCCKbIABaWERzi1yaFAzmEpHYEC8GUKVQEkbyhvOOkZqdWt0/QYKqLzQeF7dv6y/RLwa2wYtk4Xxxe+t8yd1WuM/rcUc2gYHEeoP02umSgTR50ybBPRuiSZAS6ZAQ4jgCAPyjYJiRH4fymAAAAAElFTkSuQmCC")
      no-repeat center center;
    pointer-events: none;
    color: black;
  }

  label[for="createpost"] select {
    height: 50px;
    width: 100%;
    border: none;
    background: #eeedf2;
    padding: 3px 20px 3px 20px;
    font-size: 17px;
    font-family: monospace;
    cursor: pointer;
    font-family: Poppins;
  }

  label[for="createpost"] select::-ms-expand {
    display: none;
  }

  label[for="createpost"] :-moz-any(select) {
    width: 110%;
  }

  label[for="createpost"].ie9 select {
    width: 110%;
  }

  label[for="createpost"] select:focus {
    outline: 1px dotted #a9a9a9;
  }

  .cforumtopics {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    column-gap: 50px;
    padding-left: 20px;
    padding-right: 10px;
    margin-top: 30px;
    flex-direction: column;
  }

  .cheadingtopics {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    column-gap: 50px;
    padding-left: 20px;
    padding-right: 10px;
    margin-top: 25px;
    flex-direction: column;
  }
  .ctextareapostcreate {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    column-gap: 50px;
    padding-left: 10px;
    padding-right: 70px;
    margin-top: 30px;
    position: relative;
  }
  .cinputforumcreatepost input {
    width: 551px;
    height: 50px;
    border-radius: 3px;
    background-color: #eeedf2;
    border: none;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    padding-left: 20px;
    color: #000000;
    margin-top: 10px;
  }
  .cinputforumcreatepost input::placeholder {
    font-size: 20px;
    font-weight: 400;
    color: #000000;
    line-height: 30px;
  }

  .textareacreatepost textarea {
    width: 252px;
    height: 297px;
    background-color: #eeedf2;
    border: none;
    border-radius: 10px;
  }

  .headingcreatepost {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    color: #000000;
  }
  .textareacreatepost textarea {
    font-family: Poppins;
    padding-left: 20px;
    padding-top: 15px;
    font-size: 16px;
  }

  .photouploadsimagers {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
    background-color: #ffffff;
    border: none;
    width: 88px;
    height: 33px;
    border-radius: 7px;
    position: absolute;
    top: 411px;
    left: 319px;
  }
  #file {
    display: none;
  }
  .formuploadflex {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 5px;
  }

  .uploadtexts {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #2200ff;
  }
  .postionofimage {
    position: relative;
  }

  .buttonsubmit button {
    width: 173px;
    height: 40px;
    background-color: #2200ff;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    border: none;
    text-align: center;
    color: #ffffff;
    border-radius: 5px;
    margin-left: 27px;
    margin-top: 18px;
  }
  .buttonsubmit {
    padding-bottom: 50px;
  }

  .fileuploadareainput {
    background-color: transparent;
    border: none;
  }

  /*-----------Recently Added--------------*/

  .previewwimagesizee {
    width: 200px;
    height: 78px;
    margin-top: 9px;
  }
  .recommenededsizees {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #8c8484;
    margin-top: 8px;
  }

  .thumbnailtextscreatepost {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
  }

  .thumbnailsecrionforcreatepost {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    column-gap: 50px;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 30px;
    flex-direction: column;
  }

  .thumbnailsecrionforcreatepost11 {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    column-gap: 50px;
    padding-left: 20px;
    padding-right: 0px;
    margin-top: 20px;
    flex-direction: column;
  }

  .imagethumbnailpreviewdivtag {
    display: flex;
    justify-content: center;
    column-gap: 40px;
    align-items: center;
  }

  .recomennededsizeforphoto {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #8c8484;
    margin-left: 303px;
    margin-top: 10px;
  }

  .breiefdescriptionnamee {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
  }

  .breifdescriptionzsflex {
    display: flex;
    padding-left: 20px;
    margin-top: 30px;
    flex-direction: column;
  }

  .breifedescriptiontrextareabox {
    padding-left: 0px;
  }
  .breifedescriptiontrextareabox textarea {
    width: 549px;
    background-color: #eeedf2;
    border: none;
    border-radius: 5px;
    height: 98px;
    padding: 11px;
    padding-left: 20px;
    color: black;
    font-size: 16px;
    line-height: 30px;
    margin-top: 10px;
    padding-bottom: 5px;
  }

  .breifedescriptiontrextareabox textarea::placeholder {
    font-family: Poppins;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    padding-top: 0px;
  }

  /*-----------Recently Added--------------*/

  /* <!---------------------CreateYourPost-------------------------------------> */
}

@media (min-width: 951px) and (max-width: 1050px) {
  /*-----------Recently Added--------------*/

  .previewwimagesizee {
    width: 200px;
    height: 78px;
  }
  .recommenededsizees {
    font-family: Poppins;
    font-size: 10px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #8c8484;
    margin-top: 10px;
  }

  .thumbnailtextscreatepost {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
  }

  .thumbnailsecrionforcreatepost {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    column-gap: 50px;
    padding-left: 50px;
    padding-right: 254px;
    margin-top: 30px;
  }

  .thumbnailsecrionforcreatepost11 {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    column-gap: 50px;
    padding-left: 105px;
    padding-right: 435px;
    margin-top: 30px;
  }

  .imagethumbnailpreviewdivtag {
    display: flex;
    justify-content: center;
    column-gap: 20px;
    align-items: flex-start;
    flex-direction: column;
  }

  .recomennededsizeforphoto {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #8c8484;
    margin-left: 303px;
    margin-top: 10px;
  }

  .breiefdescriptionnamee {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
  }

  .breifdescriptionzsflex {
    display: flex;
    padding-left: 50px;
    margin-top: 30px;
    padding-right: 60px;
  }

  .breifedescriptiontrextareabox {
    padding-left: 35px;
  }
  .breifedescriptiontrextareabox textarea {
    width: 320px;
    background-color: #eeedf2;
    border: none;
    border-radius: 10px;
    height: 250px;
    padding: 10px;
    padding-left: 20px;
    color: #9d9d9d;
    font-size: 20px;
  }

  .breifedescriptiontrextareabox textarea::placeholder {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
  }

  .file-input__label {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    font-size: 14px;
    padding: 10px 12px;
    background-color: #eeedf2;
    top: 399px;
    left: 321px;
    height: 17px;
    width: 149px;
  }

  /*-----------Recently Added--------------*/
  .widthofcreatepost {
    width: 900px;
    border: none;
  }
  .flexofcreatepost {
    display: flex;
    justify-content: center;
  }
}
@media (min-width: 1051px) and (max-width: 1150px) {
  /*-----------Recently Added--------------*/

  .previewwimagesizee {
    width: 200px;
    height: 78px;
  }
  .recommenededsizees {
    font-family: Poppins;
    font-size: 10px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #8c8484;
    margin-top: 10px;
  }

  .thumbnailtextscreatepost {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
  }

  .thumbnailsecrionforcreatepost {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    column-gap: 50px;
    padding-left: 50px;
    padding-right: 254px;
    margin-top: 30px;
  }

  .thumbnailsecrionforcreatepost11 {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    column-gap: 50px;
    padding-left: 105px;
    padding-right: 435px;
    margin-top: 30px;
  }

  .imagethumbnailpreviewdivtag {
    display: flex;
    justify-content: center;
    column-gap: 20px;
    align-items: flex-start;
    flex-direction: column;
  }

  .recomennededsizeforphoto {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #8c8484;
    margin-left: 303px;
    margin-top: 10px;
  }

  .breiefdescriptionnamee {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
  }

  .breifdescriptionzsflex {
    display: flex;
    padding-left: 50px;
    margin-top: 30px;
    padding-right: 60px;
  }

  .breifedescriptiontrextareabox {
    padding-left: 35px;
  }
  .breifedescriptiontrextareabox textarea {
    width: 320px;
    background-color: #eeedf2;
    border: none;
    border-radius: 10px;
    height: 250px;
    padding: 10px;
    padding-left: 20px;
    color: #9d9d9d;
    font-size: 20px;
  }

  .breifedescriptiontrextareabox textarea::placeholder {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
  }

  .file-input__label {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    font-size: 14px;
    padding: 10px 12px;
    background-color: #eeedf2;
    top: 399px;
    left: 321px;
    height: 17px;
    width: 149px;
  }

  /*-----------Recently Added--------------*/
  .widthofcreatepost {
    width: 900px;
    border: none;
  }
  .flexofcreatepost {
    display: flex;
    justify-content: center;
  }
}
@media (min-width: 1151px) and (max-width: 1250px) {
  /*-----------Recently Added--------------*/

  .previewwimagesizee {
    width: 200px;
    height: 78px;
  }
  .recommenededsizees {
    font-family: Poppins;
    font-size: 10px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #8c8484;
    margin-top: 10px;
  }

  .thumbnailtextscreatepost {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
  }

  .thumbnailsecrionforcreatepost {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    column-gap: 50px;
    padding-left: 50px;
    padding-right: 254px;
    margin-top: 30px;
  }

  .thumbnailsecrionforcreatepost11 {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    column-gap: 50px;
    padding-left: 105px;
    padding-right: 435px;
    margin-top: 30px;
  }

  .imagethumbnailpreviewdivtag {
    display: flex;
    justify-content: center;
    column-gap: 20px;
    align-items: flex-start;
    flex-direction: column;
  }

  .recomennededsizeforphoto {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #8c8484;
    margin-left: 303px;
    margin-top: 10px;
  }

  .breiefdescriptionnamee {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
  }

  .breifdescriptionzsflex {
    display: flex;
    padding-left: 50px;
    margin-top: 30px;
    padding-right: 60px;
  }

  .breifedescriptiontrextareabox {
    padding-left: 35px;
  }
  .breifedescriptiontrextareabox textarea {
    width: 320px;
    background-color: #eeedf2;
    border: none;
    border-radius: 10px;
    height: 250px;
    padding: 10px;
    padding-left: 20px;
    color: #9d9d9d;
    font-size: 20px;
  }

  .breifedescriptiontrextareabox textarea::placeholder {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
  }

  .file-input__label {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    font-size: 14px;
    padding: 10px 12px;
    background-color: #eeedf2;
    top: 399px;
    left: 321px;
    height: 17px;
    width: 149px;
  }
  /*-----------Recently Added--------------*/
  .widthofcreatepost {
    width: 900px;
    border: none;
  }
  .flexofcreatepost {
    display: flex;
    justify-content: center;
  }
}
@media (min-width: 1251px) and (max-width: 1350px) {
  /*-----------Recently Added--------------*/

  .previewwimagesizee {
    width: 200px;
    height: 78px;
  }
  .recommenededsizees {
    font-family: Poppins;
    font-size: 10px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #8c8484;
    margin-top: 10px;
  }

  .thumbnailtextscreatepost {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
  }

  .thumbnailsecrionforcreatepost {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    column-gap: 50px;
    padding-left: 50px;
    padding-right: 254px;
    margin-top: 30px;
  }

  .thumbnailsecrionforcreatepost11 {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    column-gap: 50px;
    padding-left: 105px;
    padding-right: 435px;
    margin-top: 30px;
  }

  .imagethumbnailpreviewdivtag {
    display: flex;
    justify-content: center;
    column-gap: 20px;
    align-items: flex-start;
    flex-direction: column;
  }

  .recomennededsizeforphoto {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #8c8484;
    margin-left: 303px;
    margin-top: 10px;
  }

  .breiefdescriptionnamee {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
  }

  .breifdescriptionzsflex {
    display: flex;
    padding-left: 50px;
    margin-top: 30px;
    padding-right: 60px;
  }

  .breifedescriptiontrextareabox {
    padding-left: 35px;
  }
  .breifedescriptiontrextareabox textarea {
    width: 320px;
    background-color: #eeedf2;
    border: none;
    border-radius: 10px;
    height: 250px;
    padding: 10px;
    padding-left: 20px;
    color: #9d9d9d;
    font-size: 20px;
  }

  .breifedescriptiontrextareabox textarea::placeholder {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
  }

  .file-input__label {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    font-size: 14px;
    padding: 10px 12px;
    background-color: #eeedf2;
    top: 399px;
    left: 321px;
    height: 17px;
    width: 149px;
  }
  /*-----------Recently Added--------------*/
  .widthofcreatepost {
    width: 900px;
    border: none;
  }
  .flexofcreatepost {
    display: flex;
    justify-content: center;
  }
}
@media (min-width: 1351px) and (max-width: 1450px) {
  .widthofcreatepost {
    width: 1350px;
    border: none;
  }
  .flexofcreatepost {
    display: flex;
    justify-content: center;
  }
}
@media (min-width: 1451px) and (max-width: 1550px) {
  .widthofcreatepost {
    width: 1350px;
    border: none;
  }
  .flexofcreatepost {
    display: flex;
    justify-content: center;
  }
}
@media (min-width: 1551px) and (max-width: 1650px) {
  .widthofcreatepost {
    width: 1350px;
    border: none;
  }
  .flexofcreatepost {
    display: flex;
    justify-content: center;
  }
}
@media (min-width: 1651px) and (max-width: 1750px) {
  .widthofcreatepost {
    width: 1350px;
    border: none;
  }
  .flexofcreatepost {
    display: flex;
    justify-content: center;
  }
}
@media (min-width: 1751px) and (max-width: 1850px) {
  .widthofcreatepost {
    width: 1350px;
    border: none;
  }
  .flexofcreatepost {
    display: flex;
    justify-content: center;
  }
}
@media (min-width: 1851px) and (max-width: 1950px) {
  .widthofcreatepost {
    width: 1350px;
    border: none;
  }
  .flexofcreatepost {
    display: flex;
    justify-content: center;
  }
}
@media (min-width: 1951px) and (max-width: 2050px) {
  .widthofcreatepost {
    width: 1350px;
    border: none;
  }
  .flexofcreatepost {
    display: flex;
    justify-content: center;
  }
}
@media (min-width: 2051px) and (max-width: 2150px) {
  .widthofcreatepost {
    width: 1350px;
    border: none;
  }
  .flexofcreatepost {
    display: flex;
    justify-content: center;
  }
}
@media (min-width: 2051px) {
  .widthofcreatepost {
    width: 1350px;
    border: none;
  }
  .flexofcreatepost {
    display: flex;
    justify-content: center;
  }
}

/* <!--------------------- Create Post (Media Query)-------------------------------------> */

/* <!---------------------Login Page (Media Query)-------------------------------------> */

@media (max-width: 359px) {
  .indexpagemediaqueryflex {
    display: flex;
    justify-content: center;
  }

  /* .mediaqueryindex {
    width: 320px;
    border: none;
  } */

  /* <!---------------------Login Page-------------------------------------> */

  .loginpageuserididv input {
    width: 264px;
    height: 28px;
    background-color: #eeedf2;
    border: none;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #000;
    padding-left: 8px;
    border-radius: 3px;
    margin-left: 20px;
    margin-top: 20px;
  }

  .loginpageuserididv input::placeholder {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #a3a2a2;
  }

  .loginpagepassworddiv input {
    width: 264px;
    height: 28px;
    background-color: #eeedf2;
    border: none;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #000;
    padding-left: 8px;
    border-radius: 3px;
    margin-top: 20px;
    margin-left: 20px;
  }

  .loginpagepassworddiv input::placeholder {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #a3a2a2;
  }

  .loginbuttonpagediv {
    width: 273px;
    height: 28px;
    background-color: #011ae3;
    color: #fff;
    border: none;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    border-radius: 3px;
    margin-top: 20px;
    margin-left: 20px;
  }

  .gggedindeatilstext {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: #282828;
    margin-left: 0px;
    margin-top: 15px;
    padding-top: 56px;
  }

  .loginpagessforgotpasswordtext {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
    color: #282828;
    margin-left: 0px;
    margin-top: 25px;
  }

  .donthaveaxxcountpoassword {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
    margin-left: 0px;
    margin-top: 13px;
  }

  .donthaveaxxcountpoassword span {
    color: #ea0c0b;
  }

  .formwidthpaddings {
    padding-bottom: 30px;
  }

  .registerpageidalreadytaken {
    font-family: Poppins;
    font-size: 9px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: #f44336;
    margin-left: 23px;
    margin-top: 5px;
  }

  /*---OTP Div---*/
  .form-control--otp {
    background: #eeedf2;
    line-height: 2.5;
    height: 3.5rem;
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    padding-left: 2px;
    padding-right: 2px;
    width: 30px;
    height: 30px;
    border: none;
    border-radius: 2px;
    margin-right: 7px;
  }

  @media (min-width: 375px) {
    .form-control--otp {
      background: #eeedf2;
      line-height: 2.5;
      height: 3.5rem;
      font-size: 1.5rem;
      font-weight: bold;
      text-align: center;
      padding-left: 2px;
      padding-right: 2px;
      width: 30px;
      height: 30px;
      border: none;
      border-radius: 2px;
      margin-left: 0px;
      margin-right: 8px;
      margin-top: 0px;
    }
  }

  .input-group-seperator {
    width: 2rem;
    margin-left: 0.75rem;
    margin-right: 0.75rem;
    height: 0.3rem;
    background: #ced4da;
    border-radius: 0.1rem;
  }

  .otpdivmarginleft {
    margin-top: 35px;
    margin-left: 40px;
  }

  .enterotp {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
    color: #a3a2a2;
    margin-left: 132px;
    margin-top: 8px;
  }

  /*---OTP Div---*/

  /* <!---------------------Login Page-------------------------------------> */
}

@media (min-width: 360px) and (max-width: 700px) {
  .indexpagemediaqueryflex {
    display: flex;
    justify-content: center;
  }

  /* .mediaqueryindex {
    width: 320px;
    border: none;
  } */

  /* <!---------------------Login Page-------------------------------------> */

  .loginpageuserididv input {
    width: 264px;
    height: 28px;
    background-color: #eeedf2;
    border: none;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #000;
    padding-left: 8px;
    border-radius: 3px;
    margin-left: 0px;
    margin-top: 20px;
  }

  .loginpageuserididv input::placeholder {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #a3a2a2;
  }

  .loginpagepassworddiv input {
    width: 264px;
    height: 28px;
    background-color: #eeedf2;
    border: none;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #000;
    padding-left: 8px;
    border-radius: 3px;
    margin-top: 20px;
    margin-left: 0px;
  }

  .loginpagepassworddiv input::placeholder {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #a3a2a2;
  }

  .loginbuttonpagediv {
    width: 273px;
    height: 28px;
    background-color: #011ae3;
    color: #fff;
    border: none;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    border-radius: 3px;
    margin-top: 20px;
    margin-left: 0px;
  }

  .gggedindeatilstext {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: #282828;
    margin-left: 0px;
    margin-top: 15px;
    padding-top: 56px;
  }

  .loginpagessforgotpasswordtext {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
    color: #282828;
    margin-left: 0px;
    margin-top: 25px;
  }

  .donthaveaxxcountpoassword {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
    margin-left: 0px;
    margin-top: 13px;
  }

  .donthaveaxxcountpoassword span {
    color: #ea0c0b;
  }

  .formwidthpaddings {
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .registerpageidalreadytaken {
    font-family: Poppins;
    font-size: 9px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: #f44336;
    margin-left: 23px;
    margin-top: 5px;
  }

  /*---OTP Div---*/
  .form-control--otp {
    background: #eeedf2;
    line-height: 2.5;
    height: 3.5rem;
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    padding-left: 2px;
    padding-right: 2px;
    width: 30px;
    height: 30px;
    border: none;
    border-radius: 2px;
    margin-right: 7px;
  }

  @media (min-width: 375px) {
    .form-control--otp {
      background: #eeedf2;
      line-height: 2.5;
      height: 3.5rem;
      font-size: 1.5rem;
      font-weight: bold;
      text-align: center;
      padding-left: 2px;
      padding-right: 2px;
      width: 30px;
      height: 30px;
      border: none;
      border-radius: 2px;
      margin-left: 0px;
      margin-right: 8px;
      margin-top: 0px;
    }
  }

  .input-group-seperator {
    width: 2rem;
    margin-left: 0.75rem;
    margin-right: 0.75rem;
    height: 0.3rem;
    background: #ced4da;
    border-radius: 0.1rem;
  }

  .otpdivmarginleft {
    margin-top: 35px;
    margin-left: 40px;
  }

  .enterotp {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
    color: #a3a2a2;
    margin-left: 132px;
    margin-top: 8px;
  }

  /*---OTP Div---*/

  /* <!---------------------Login Page-------------------------------------> */
}

@media (min-width: 700px) and (max-width: 950px) {
  .indexpagemediaqueryflex {
    display: flex;
    justify-content: center;
  }

  /* .mediaqueryindex {
    width: 320px;
    border: none;
  } */

  /* <!---------------------Login Page-------------------------------------> */

  .loginpageuserididv input {
    width: 500px;
    height: 42px;
    background-color: #eeedf2;
    border: none;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #000;
    padding-left: 20px;
    border-radius: 3px;
    margin-left: 86px;
    margin-top: 33px;
  }

  .loginpageuserididv input::placeholder {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #a3a2a2;
  }

  .loginpagepassworddiv input {
    width: 500px;
    height: 42px;
    background-color: #eeedf2;
    border: none;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #000;
    padding-left: 20px;
    border-radius: 3px;
    margin-top: 28px;
    margin-left: 85px;
  }

  .loginpagepassworddiv input::placeholder {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #a3a2a2;
  }

  .loginbuttonpagediv {
    width: 522px;
    height: 42px;
    background-color: #011ae3;
    color: #fff;
    border: none;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
    border-radius: 3px;
    margin-top: 20px;
    margin-left: 85px;
  }

  .gggedindeatilstext {
    font-family: Poppins;
    font-size: 21px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
    color: #282828;
    margin-left: 0px;
    margin-top: 15px;
    padding-top: 56px;
  }

  .loginpagessforgotpasswordtext {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
    color: #282828;
    margin-left: 0px;
    margin-top: 25px;
  }

  .donthaveaxxcountpoassword {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
    margin-left: 0px;
    margin-top: 20px;
  }

  .donthaveaxxcountpoassword span {
    color: #ea0c0b;
  }

  .formwidthpaddings {
    padding-bottom: 30px;
  }

  .registerpageidalreadytaken {
    font-family: Poppins;
    font-size: 9px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: #f44336;
    margin-left: 23px;
    margin-top: 5px;
  }

  /*---OTP Div---*/
  .form-control--otp {
    background: #eeedf2;
    line-height: 2.5;
    height: 3.5rem;
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    padding-left: 2px;
    padding-right: 2px;
    width: 30px;
    height: 30px;
    border: none;
    border-radius: 2px;
    margin-right: 7px;
  }

  @media (min-width: 375px) {
    .form-control--otp {
      background: #eeedf2;
      line-height: 2.5;
      height: 3.5rem;
      font-size: 1.5rem;
      font-weight: bold;
      text-align: center;
      padding-left: 2px;
      padding-right: 2px;
      width: 30px;
      height: 30px;
      border: none;
      border-radius: 2px;
      margin-left: 0px;
      margin-right: 8px;
      margin-top: 0px;
    }
  }

  .input-group-seperator {
    width: 2rem;
    margin-left: 0.75rem;
    margin-right: 0.75rem;
    height: 0.3rem;
    background: #ced4da;
    border-radius: 0.1rem;
  }

  .otpdivmarginleft {
    margin-top: 35px;
    margin-left: 222px;
  }

  .enterotp {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
    color: #a3a2a2;
    margin-left: 318px;
    margin-top: 8px;
  }

  /*---OTP Div---*/

  /* <!---------------------Login Page-------------------------------------> */
}

@media (min-width: 951px) and (max-width: 1050px) {
  .loginpageuserididv input {
    width: 514px;
    height: 56px;
    background-color: #eeedf2;
    border: none;
    font-family: Poppins;
    font-size: 23px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    color: black;
    padding-left: 20px;
    border-radius: 7px;
    margin-left: 63px;
    margin-top: 36px;
  }

  .loginpageuserididv input::placeholder {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color: #a3a2a2;
  }

  .loginpagepassworddiv input {
    width: 514px;
    height: 56px;
    background-color: #eeedf2;
    border: none;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color: #000;
    padding-left: 20px;
    border-radius: 7px;
    margin-top: 36px;
    margin-left: 63px;
  }

  .loginpagepassworddiv input::placeholder {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color: #a3a2a2;
  }

  .loginbuttonpagediv {
    width: 533px;
    height: 52px;
    background-color: #011ae3;
    color: #fff;
    border: none;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: center;
    border-radius: 7px;
    margin-top: 36px;
    margin-left: 63px;
  }

  .gggedindeatilstext {
    font-family: Poppins;
    font-size: 32px;
    font-weight: 500;
    line-height: 45px;
    letter-spacing: 0em;
    text-align: left;
    color: #282828;
    margin-top: 15px;
    padding-top: 56px;
    text-align: center;
    margin-left: 0px;
  }

  .loginpagessforgotpasswordtext {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #282828;
    margin-left: 0px;
    margin-top: 30px;
    text-align: center;
  }

  .donthaveaxxcountpoassword {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: 0px;
    margin-top: 15px;
    text-align: center;
  }

  .donthaveaxxcountpoassword span {
    color: #ea0c0b;
  }

  .formwidthpaddings {
    padding-bottom: 30px;
  }

  .registerpageidalreadytaken {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #f44336;
    margin-left: 70px;
    margin-top: 10px;
  }

  /*---OTP Div---*/
  .form-control--otp {
    line-height: 2.5;
    height: 3.5rem;
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    padding-left: 2px;
    padding-right: 2px;
    width: 60px;
    height: 60px;
  }

  @media (min-width: 375px) {
    .form-control--otp {
      line-height: 3;
      height: 4.5rem;
      font-size: 2rem;
      font-weight: bold;
      text-align: center;
      max-width: 3.5rem;
      width: 40px;
      height: 40px;
      border: none;
      background-color: #eeedf2;
      border-radius: 5px;
      margin-left: 3px;
      margin-right: 3px;
    }
  }

  .input-group-seperator {
    width: 2rem;
    margin-left: 0.75rem;
    margin-right: 0.75rem;
    height: 0.3rem;
    background: #ced4da;
    border-radius: 0.1rem;
  }

  .otpdivmarginleft {
    margin-top: 78px;
    margin-left: 102px;
  }

  .enterotp {
    font-family: Poppins;
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #a3a2a2;
    margin-left: 230px;
    margin-top: 14px;
  }

  /*---OTP Div---*/
}
@media (min-width: 1051px) and (max-width: 1150px) {
  .loginpageuserididv input {
    width: 398px;
    height: 46px;
    background-color: #eeedf2;
    border: none;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color: #000;
    padding-left: 20px;
    border-radius: 7px;
    margin-left: 63px;
    margin-top: 30px;
  }

  .loginpageuserididv input::placeholder {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color: #a3a2a2;
  }

  .loginpagepassworddiv input {
    width: 398px;
    height: 46px;
    background-color: #eeedf2;
    border: none;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color: #000;
    padding-left: 20px;
    border-radius: 7px;
    margin-top: 28px;
    margin-left: 63px;
  }

  .loginpagepassworddiv input::placeholder {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color: #a3a2a2;
  }

  .loginbuttonpagediv {
    width: 419px;
    height: 46px;
    background-color: #011ae3;
    color: #fff;
    border: none;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
    border-radius: 7px;
    margin-top: 30px;
    margin-left: 63px;
  }

  .gggedindeatilstext {
    font-family: Poppins;
    font-size: 30px;
    font-weight: 500;
    line-height: 45px;
    letter-spacing: 0em;
    text-align: left;
    color: #282828;
    margin-left: 70px;
    margin-top: 15px;
    padding-top: 56px;
  }

  .loginpagessforgotpasswordtext {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #282828;
    margin-left: 205px;
    margin-top: 30px;
  }

  .donthaveaxxcountpoassword {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: 130px;
    margin-top: 15px;
  }

  .donthaveaxxcountpoassword span {
    color: #ea0c0b;
  }

  .formwidthpaddings {
    padding-bottom: 30px;
  }

  .registerpageidalreadytaken {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #f44336;
    margin-left: 70px;
    margin-top: 10px;
  }

  /*---OTP Div---*/
  .form-control--otp {
    line-height: 2.5;
    height: 3.5rem;
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    padding-left: 2px;
    padding-right: 2px;
    width: 60px;
    height: 60px;
  }

  @media (min-width: 375px) {
    .form-control--otp {
      line-height: 3;
      height: 4.5rem;
      font-size: 2rem;
      font-weight: bold;
      text-align: center;
      max-width: 3.5rem;
      width: 40px;
      height: 40px;
      border: none;
      background-color: #eeedf2;
      border-radius: 5px;
      margin-left: 3px;
      margin-right: 3px;
    }
  }

  .input-group-seperator {
    width: 2rem;
    margin-left: 0.75rem;
    margin-right: 0.75rem;
    height: 0.3rem;
    background: #ced4da;
    border-radius: 0.1rem;
  }

  .otpdivmarginleft {
    margin-top: 78px;
    margin-left: 102px;
  }

  .enterotp {
    font-family: Poppins;
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #a3a2a2;
    margin-left: 230px;
    margin-top: 14px;
  }

  /*---OTP Div---*/
}
@media (min-width: 1151px) and (max-width: 1250px) {
  .loginpageuserididv input {
    width: 398px;
    height: 46px;
    background-color: #eeedf2;
    border: none;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color: #000;
    padding-left: 20px;
    border-radius: 7px;
    margin-left: 63px;
    margin-top: 30px;
  }

  .loginpageuserididv input::placeholder {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color: #a3a2a2;
  }

  .loginpagepassworddiv input {
    width: 398px;
    height: 46px;
    background-color: #eeedf2;
    border: none;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color: #000;
    padding-left: 20px;
    border-radius: 7px;
    margin-top: 28px;
    margin-left: 63px;
  }

  .loginpagepassworddiv input::placeholder {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color: #a3a2a2;
  }

  .loginbuttonpagediv {
    width: 419px;
    height: 46px;
    background-color: #011ae3;
    color: #fff;
    border: none;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
    border-radius: 7px;
    margin-top: 30px;
    margin-left: 63px;
  }

  .gggedindeatilstext {
    font-family: Poppins;
    font-size: 30px;
    font-weight: 500;
    line-height: 45px;
    letter-spacing: 0em;
    text-align: left;
    color: #282828;
    margin-left: 70px;
    margin-top: 15px;
    padding-top: 56px;
  }

  .loginpagessforgotpasswordtext {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #282828;
    margin-left: 205px;
    margin-top: 30px;
  }

  .donthaveaxxcountpoassword {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: 130px;
    margin-top: 15px;
  }

  .donthaveaxxcountpoassword span {
    color: #ea0c0b;
  }

  .formwidthpaddings {
    padding-bottom: 30px;
  }

  .registerpageidalreadytaken {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #f44336;
    margin-left: 70px;
    margin-top: 10px;
  }

  /*---OTP Div---*/
  .form-control--otp {
    line-height: 2.5;
    height: 3.5rem;
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    padding-left: 2px;
    padding-right: 2px;
    width: 60px;
    height: 60px;
  }

  @media (min-width: 375px) {
    .form-control--otp {
      line-height: 3;
      height: 4.5rem;
      font-size: 2rem;
      font-weight: bold;
      text-align: center;
      max-width: 3.5rem;
      width: 40px;
      height: 40px;
      border: none;
      background-color: #eeedf2;
      border-radius: 5px;
      margin-left: 3px;
      margin-right: 3px;
    }
  }

  .input-group-seperator {
    width: 2rem;
    margin-left: 0.75rem;
    margin-right: 0.75rem;
    height: 0.3rem;
    background: #ced4da;
    border-radius: 0.1rem;
  }

  .otpdivmarginleft {
    margin-top: 78px;
    margin-left: 102px;
  }

  .enterotp {
    font-family: Poppins;
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #a3a2a2;
    margin-left: 230px;
    margin-top: 14px;
  }

  /*---OTP Div---*/
}
@media (min-width: 1251px) and (max-width: 1350px) {
  .loginpageuserididv input {
    width: 398px;
    height: 46px;
    background-color: #eeedf2;
    border: none;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color: #000;
    padding-left: 20px;
    border-radius: 7px;
    margin-left: 63px;
    margin-top: 30px;
  }

  .loginpageuserididv input::placeholder {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color: #a3a2a2;
  }

  .loginpagepassworddiv input {
    width: 398px;
    height: 46px;
    background-color: #eeedf2;
    border: none;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color: #000;
    padding-left: 20px;
    border-radius: 7px;
    margin-top: 28px;
    margin-left: 63px;
  }

  .loginpagepassworddiv input::placeholder {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color: #a3a2a2;
  }

  .loginbuttonpagediv {
    width: 419px;
    height: 46px;
    background-color: #011ae3;
    color: #fff;
    border: none;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
    border-radius: 7px;
    margin-top: 30px;
    margin-left: 63px;
  }

  .gggedindeatilstext {
    font-family: Poppins;
    font-size: 30px;
    font-weight: 500;
    line-height: 45px;
    letter-spacing: 0em;
    text-align: left;
    color: #282828;
    margin-left: 70px;
    margin-top: 15px;
    padding-top: 56px;
  }

  .loginpagessforgotpasswordtext {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #282828;
    margin-left: 205px;
    margin-top: 30px;
  }

  .donthaveaxxcountpoassword {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: 130px;
    margin-top: 15px;
  }

  .donthaveaxxcountpoassword span {
    color: #ea0c0b;
  }

  .formwidthpaddings {
    padding-bottom: 30px;
  }

  .registerpageidalreadytaken {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #f44336;
    margin-left: 70px;
    margin-top: 10px;
  }

  /*---OTP Div---*/
  .form-control--otp {
    line-height: 2.5;
    height: 3.5rem;
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    padding-left: 2px;
    padding-right: 2px;
    width: 60px;
    height: 60px;
  }

  @media (min-width: 375px) {
    .form-control--otp {
      line-height: 3;
      height: 4.5rem;
      font-size: 2rem;
      font-weight: bold;
      text-align: center;
      max-width: 3.5rem;
      width: 40px;
      height: 40px;
      border: none;
      background-color: #eeedf2;
      border-radius: 5px;
      margin-left: 3px;
      margin-right: 3px;
    }
  }

  .input-group-seperator {
    width: 2rem;
    margin-left: 0.75rem;
    margin-right: 0.75rem;
    height: 0.3rem;
    background: #ced4da;
    border-radius: 0.1rem;
  }

  .otpdivmarginleft {
    margin-top: 78px;
    margin-left: 102px;
  }

  .enterotp {
    font-family: Poppins;
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #a3a2a2;
    margin-left: 230px;
    margin-top: 14px;
  }

  /*---OTP Div---*/
}

/* <!---------------------Login Page (Media Query)-------------------------------------> */

/* <!--------------------My Profile (Media Query)-------------------------------------> */

@media (max-width: 358px) {
  /* <!----------My Profile----------------------> */

  .myprofilephotouploaddiv img {
    width: 95px;
    height: 95px;
    border-radius: 50%;
    object-fit: cover;
  }
  .myprofilefile-input__input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
    top: 0px;
  }

  .myprofilefile-input__label {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    font-size: 14px;
    background-color: #eeedf2;
    top: 399px;
    left: 321px;
    height: 39px;
    width: 35px;
    padding-left: 6px;
    border-radius: 50px;
    border: 5px solid white;
  }

  .myprofilefile-input__label svg {
    height: 16px;
    margin-right: 4px;
  }

  .profilephototattachdiv {
    position: relative;
  }

  .postionabsoluteofprofile {
    position: absolute;
    top: 44px;
    left: 74px;
  }

  .profilephototattachdiv {
    margin-left: 100px;
  }

  .myprofileidalreadytaken {
    font-family: Poppins;
    font-size: 10px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #f44336;
    margin-left: 30px;
    margin-top: 8px;
  }

  .updatechangesbuttondiv {
    font-family: Poppins;
    font-size: 11px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    color: #ffffff;
    width: 230px;
    background-color: #011ae3;
    border: none;
    height: 30px;
    border-radius: 4px;
    margin-left: 45px;
    margin-top: 10px;
  }
  .myprofileforogtopassword {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
    margin-left: 0px;
    margin-top: 20px;
  }

  .myprofilepagerightsidediv {
    padding-bottom: 100px;
    padding-top: 45px;
    margin-top: 14px;
  }

  .myheadingtopics {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    column-gap: 0px;
    padding-left: 30px;
    padding-right: 20px;
    margin-top: 15px;
    flex-direction: column;
  }

  .semicolonhide {
    display: none;
  }
  /* <!----------My Profile----------------------> */
}
@media (min-width: 359px) and (max-width: 700px) {
  /* <!----------My Profile----------------------> */

  .myprofilephotouploaddiv img {
    width: 95px;
    height: 95px;
    border-radius: 50%;
    object-fit: cover;
  }
  .myprofilefile-input__input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
    top: 0px;
  }

  .myprofilefile-input__label {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    font-size: 14px;
    background-color: #eeedf2;
    top: 399px;
    left: 321px;
    height: 39px;
    width: 35px;
    padding-left: 6px;
    border-radius: 50px;
    border: 5px solid white;
  }

  .myprofilefile-input__label svg {
    height: 16px;
    margin-right: 4px;
  }

  .profilephototattachdiv {
    position: relative;
  }

  .postionabsoluteofprofile {
    position: absolute;
    top: 44px;
    left: 74px;
  }

  .profilephototattachdiv {
    margin-left: 139px;
  }

  .myprofileidalreadytaken {
    font-family: Poppins;
    font-size: 10px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #f44336;
    margin-left: 30px;
    margin-top: 8px;
  }

  .updatechangesbuttondiv {
    font-family: Poppins;
    font-size: 11px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    color: #ffffff;
    width: 230px;
    background-color: #011ae3;
    border: none;
    height: 30px;
    border-radius: 4px;
    margin-left: 60px;
    margin-top: 14px;
  }
  .myprofileforogtopassword {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
    margin-left: 0px;
    margin-top: 20px;
  }

  .myprofilepagerightsidediv {
    padding-bottom: 100px;
    padding-top: 45px;
    margin-top: 14px;
  }

  .myheadingtopics {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    column-gap: 0px;
    padding-left: 30px;
    padding-right: 20px;
    margin-top: 15px;
    flex-direction: column;
  }

  .semicolonhide {
    display: none;
  }
  /* <!----------My Profile----------------------> */
}
@media (min-width: 700px) and (max-width: 1000px) {
  /* <!----------My Profile----------------------> */

  .myprofilephotouploaddiv img {
    width: 95px;
    height: 95px;
    border-radius: 50%;
    object-fit: cover;
  }
  .myprofilefile-input__input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
    top: 0px;
  }

  .myprofilefile-input__label {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    font-size: 14px;
    background-color: #eeedf2;
    top: 399px;
    left: 321px;
    height: 39px;
    width: 35px;
    padding-left: 6px;
    border-radius: 50px;
    border: 5px solid white;
  }

  .myprofilefile-input__label svg {
    height: 16px;
    margin-right: 4px;
  }

  .profilephototattachdiv {
    position: relative;
  }

  .postionabsoluteofprofile {
    position: absolute;
    top: 44px;
    left: 74px;
  }

  .profilephototattachdiv {
    margin-left: 311px;
  }

  .myprofileidalreadytaken {
    font-family: Poppins;
    font-size: 10px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #f44336;
    margin-left: 30px;
    margin-top: 8px;
  }

  .updatechangesbuttondiv {
    font-family: Poppins;
    font-size: 11px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    color: #ffffff;
    width: 230px;
    background-color: #011ae3;
    border: none;
    height: 30px;
    border-radius: 4px;
    margin-left: 78px;
    margin-top: 21px;
  }

  .MyPrileUPlaterghgdvbjfdf {
    margin-left: 48px;
    margin-top: 20px;
  }
  .myprofileforogtopassword {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
    margin-left: 0px;
    margin-top: 20px;
  }

  .myprofilepagerightsidediv {
    padding-bottom: 100px;
    padding-top: 45px;
    margin-top: 14px;
  }

  .myheadingtopics {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    column-gap: 0px;
    padding-left: 30px;
    padding-right: 20px;
    margin-top: 15px;
    flex-direction: column;
  }

  .semicolonhide {
    display: none;
  }
  /* <!----------My Profile----------------------> */
}
@media (min-width: 951px) and (max-width: 1050px) {
  .myprofilephotouploaddiv img {
    width: 175px;
    height: 175px;
    border-radius: 50%;
    object-fit: cover;
  }
  .myprofilefile-input__input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
    top: 0px;
  }

  .myprofilefile-input__label {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    font-size: 14px;
    background-color: #eeedf2;
    top: 399px;
    left: 321px;
    height: 51px;
    width: 39px;
    padding-left: 11px;
    border-radius: 50px;
    border: 5px solid white;
  }

  .myprofilefile-input__label svg {
    height: 16px;
    margin-right: 4px;
  }

  .profilephototattachdiv {
    position: relative;
  }

  .postionabsoluteofprofile {
    position: absolute;
    top: 106px;
    left: 134px;
  }

  .profilephototattachdiv {
    margin-left: 190px;
  }

  .myprofileidalreadytaken {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #f44336;
    margin-left: 188px;
    margin-top: 8px;
  }

  .updatechangesbuttondiv {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: center;
    color: #ffffff;
    width: 315px;
    background-color: #011ae3;
    border: none;
    height: 46px;
    border-radius: 7px;
    margin-left: 186px;
    margin-top: 21px;
  }
  .myprofileforogtopassword {
    font-family: Poppins;
    font-size: 15px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
    margin-left: 140px;
    margin-top: 15px;
  }

  .myprofilepagerightsidediv {
    padding-bottom: 100px;
    padding-top: 45px;
    margin-top: 14px;
  }

  .myheadingtopics {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 0px;
    padding-left: 42px;
    padding-right: 54px;
    margin-top: 30px;
  }
}
@media (min-width: 1051px) and (max-width: 1150px) {
  .myprofilephotouploaddiv img {
    width: 175px;
    height: 175px;
    border-radius: 50%;
    object-fit: cover;
  }
  .myprofilefile-input__input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
    top: 0px;
  }

  .myprofilefile-input__label {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    font-size: 14px;
    background-color: #eeedf2;
    top: 399px;
    left: 321px;
    height: 51px;
    width: 39px;
    padding-left: 11px;
    border-radius: 50px;
    border: 5px solid white;
  }

  .myprofilefile-input__label svg {
    height: 16px;
    margin-right: 4px;
  }

  .profilephototattachdiv {
    position: relative;
  }

  .postionabsoluteofprofile {
    position: absolute;
    top: 106px;
    left: 134px;
  }

  .profilephototattachdiv {
    margin-left: 190px;
  }

  .myprofileidalreadytaken {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #f44336;
    margin-left: 188px;
    margin-top: 8px;
  }

  .updatechangesbuttondiv {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: center;
    color: #ffffff;
    width: 315px;
    background-color: #011ae3;
    border: none;
    height: 46px;
    border-radius: 7px;
    margin-left: 186px;
    margin-top: 21px;
  }
  .myprofileforogtopassword {
    font-family: Poppins;
    font-size: 15px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
    margin-left: 140px;
    margin-top: 15px;
  }

  .myprofilepagerightsidediv {
    padding-bottom: 100px;
    padding-top: 45px;
    margin-top: 14px;
  }

  .myheadingtopics {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 0px;
    padding-left: 42px;
    padding-right: 54px;
    margin-top: 30px;
  }
}
@media (min-width: 1151px) and (max-width: 1250px) {
  .myprofilephotouploaddiv img {
    width: 175px;
    height: 175px;
    border-radius: 50%;
    object-fit: cover;
  }
  .myprofilefile-input__input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
    top: 0px;
  }

  .myprofilefile-input__label {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    font-size: 14px;
    background-color: #eeedf2;
    top: 399px;
    left: 321px;
    height: 51px;
    width: 39px;
    padding-left: 11px;
    border-radius: 50px;
    border: 5px solid white;
  }

  .myprofilefile-input__label svg {
    height: 16px;
    margin-right: 4px;
  }

  .profilephototattachdiv {
    position: relative;
  }

  .postionabsoluteofprofile {
    position: absolute;
    top: 106px;
    left: 134px;
  }

  .profilephototattachdiv {
    margin-left: 190px;
  }

  .myprofileidalreadytaken {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #f44336;
    margin-left: 188px;
    margin-top: 8px;
  }

  .updatechangesbuttondiv {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: center;
    color: #ffffff;
    width: 315px;
    background-color: #011ae3;
    border: none;
    height: 46px;
    border-radius: 7px;
    margin-left: 186px;
    margin-top: 21px;
  }
  .myprofileforogtopassword {
    font-family: Poppins;
    font-size: 15px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
    margin-left: 140px;
    margin-top: 15px;
  }

  .myprofilepagerightsidediv {
    padding-bottom: 100px;
    padding-top: 45px;
    margin-top: 14px;
  }

  .myheadingtopics {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 0px;
    padding-left: 42px;
    padding-right: 54px;
    margin-top: 30px;
  }
}
@media (min-width: 1251px) and (max-width: 1350px) {
  .myprofilephotouploaddiv img {
    width: 175px;
    height: 175px;
    border-radius: 50%;
    object-fit: cover;
  }
  .myprofilefile-input__input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
    top: 0px;
  }

  .myprofilefile-input__label {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    font-size: 14px;
    background-color: #eeedf2;
    top: 399px;
    left: 321px;
    height: 51px;
    width: 39px;
    padding-left: 11px;
    border-radius: 50px;
    border: 5px solid white;
  }

  .myprofilefile-input__label svg {
    height: 16px;
    margin-right: 4px;
  }

  .profilephototattachdiv {
    position: relative;
  }

  .postionabsoluteofprofile {
    position: absolute;
    top: 106px;
    left: 134px;
  }

  .profilephototattachdiv {
    margin-left: 190px;
  }

  .myprofileidalreadytaken {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #f44336;
    margin-left: 188px;
    margin-top: 8px;
  }

  .updatechangesbuttondiv {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: center;
    color: #ffffff;
    width: 315px;
    background-color: #011ae3;
    border: none;
    height: 46px;
    border-radius: 7px;
    margin-left: 186px;
    margin-top: 21px;
  }
  .myprofileforogtopassword {
    font-family: Poppins;
    font-size: 15px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
    margin-left: 140px;
    margin-top: 15px;
  }

  .myprofilepagerightsidediv {
    padding-bottom: 100px;
    padding-top: 45px;
    margin-top: 14px;
  }

  .myheadingtopics {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 0px;
    padding-left: 42px;
    padding-right: 54px;
    margin-top: 30px;
  }
}

/* <!--------------------My Profile (Media Query)-------------------------------------> */

/*----------------------------------------------------------------------------------------------------
      ----------------------------------------------Media Queries-------------------------------------------
      --------------------------------------------------------------------------------------------------*/

/*-------Display None-----------*/

/* .welcometorevnitro {
    display: none;
  }
  .revnitrotopicssss {
    display: none;
  }
  .formsandfilters {
    display: none;
  }
  
  .CreateYourPost {
    display: none;
  } */
/*-------Display None-----------*/
/* Please ❤ this if you like it! */

/*------------------------Sort Button---------------*/

.sec-center {
  position: relative;
  z-index: 200;
}

.dark-light:checked + label,
.dark-light:not(:checked) + label {
  position: fixed;
  top: 40px;
  right: 40px;
  z-index: 20000;
  display: block;
  border-radius: 50%;
  width: 46px;
  height: 46px;
  cursor: pointer;
  transition: all 200ms linear;
  box-shadow: 0 0 25px rgba(255, 235, 167, 0.45);
}
.dark-light:checked + label {
  transform: rotate(360deg);
}
.dark-light:checked + label:after,
.dark-light:not(:checked) + label:after {
  position: absolute;
  content: "";
  top: 1px;
  left: 1px;
  overflow: hidden;
  z-index: 2;
  display: block;
  border-radius: 50%;
  width: 44px;
  height: 44px;
  background-color: #102770;
  background-image: url("https://assets.codepen.io/1462889/moon.svg");
  background-size: 20px 20px;
  background-repeat: no-repeat;
  background-position: center;
  transition: all 200ms linear;
  opacity: 0;
}
.dark-light:checked + label:after {
  opacity: 1;
}
.dark-light:checked + label:before,
.dark-light:not(:checked) + label:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 1;
  display: block;
  border-radius: 50%;
  width: 46px;
  height: 46px;
  background-color: #48dbfb;
  background-image: url("https://assets.codepen.io/1462889/sun.svg");
  background-size: 25px 25px;
  background-repeat: no-repeat;
  background-position: center;
  transition: all 200ms linear;
}
.dark-light:checked + label:before {
  background-color: #000;
}
.light-back {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: #fff;
  overflow: hidden;
  background-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/1462889/pat-back.svg");
  background-position: center;
  background-repeat: repeat;
  background-size: 4%;
  height: 100%;
  width: 100%;
  transition: all 200ms linear;
  opacity: 0;
}
.dark-light:checked ~ .light-back {
  opacity: 1;
}
.dropdown:checked + label,
.dropdown:not(:checked) + label {
  display: flex;
  font-family: Poppins;
  font-weight: 500;
  font-size: 14px;
  line-height: -1;
  height: 29px;
  transition: all 200ms linear;
  border-radius: 4px;
  width: 68px;
  letter-spacing: 0px;
  display: -ms-inline-flexbox;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  text-align: center;
  border: none;
  background-color: #2200ff;
  cursor: pointer;
  color: #ffffff;
  box-shadow: 0 12px 35px 0 rgba(255, 235, 167, 0.15);
}
.dark-light:checked ~ .sec-center .for-dropdown {
  background-color: #102770;
  color: #ffeba7;
  box-shadow: 0 12px 35px 0 rgba(16, 39, 112, 0.25);
}
.dropdown:checked + label:before,
.dropdown:not(:checked) + label:before {
  position: fixed;
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  cursor: auto;
  pointer-events: none;
}
.dropdown:checked + label:before {
  pointer-events: auto;
}
.dropdown:not(:checked) + label .uil {
  font-size: 24px;
  margin-left: 10px;
  transition: transform 200ms linear;
}
.dropdown:checked + label .uil {
  transform: rotate(180deg);
  font-size: 24px;
  margin-left: 10px;
  transition: transform 200ms linear;
}
.section-dropdown {
  position: absolute;
  padding: 5px;
  background-color: #2200ff;
  top: 37px;
  left: 1px;
  width: 62px;
  border-radius: 4px;
  display: block;
  box-shadow: 0 14px 35px 0 rgba(9, 9, 12, 0.4);
  z-index: 2;
  opacity: 0;
  pointer-events: none;
  transform: translateY(20px);
  transition: all 200ms linear;
}
.dark-light:checked ~ .sec-center .section-dropdown {
  background-color: #fff;
  box-shadow: 0 14px 35px 0 rgba(9, 9, 12, 0.15);
}
.dropdown:checked ~ .section-dropdown {
  opacity: 1;
  pointer-events: auto;
  transform: translateY(0);
}
.section-dropdown:before {
  position: absolute;
  top: -20px;
  left: 0;
  width: 100%;
  height: 20px;
  content: "";
  display: block;
  z-index: 1;
}
.section-dropdown:after {
  position: absolute;
  top: -7px;
  left: 30px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #111;
  content: "";
  display: block;
  z-index: 2;
  transition: all 200ms linear;
}
.dark-light:checked ~ .sec-center .section-dropdown:after {
  border-bottom: 8px solid #fff;
}

a {
  position: relative;
  color: #fff;
  transition: all 200ms linear;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 12px;
  border-radius: 2px;
  padding: 6px 0;
  padding-left: 3px;
  padding-right: 0px;
  margin: 2px 0;
  text-align: start;
  text-decoration: none;
  display: flex;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  justify-content: space-between;
  -ms-flex-pack: distribute;
}
.dark-light:checked ~ .sec-center .section-dropdown a {
  color: #102770;
}
a:hover {
  color: #102770;
  background-color: #ffeba7;
}
.dark-light:checked ~ .sec-center .section-dropdown a:hover {
  color: #ffeba7;
  background-color: #102770;
}
a .uil {
  font-size: 22px;
}
.dropdown-sub:checked + label,
.dropdown-sub:not(:checked) + label {
  position: relative;
  color: #fff;
  transition: all 200ms linear;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 15px;
  border-radius: 2px;
  padding: 5px 0;
  padding-left: 20px;
  padding-right: 15px;
  text-align: left;
  text-decoration: none;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  justify-content: space-between;
  -ms-flex-pack: distribute;
  cursor: pointer;
}
.dropdown-sub:checked + label .uil,
.dropdown-sub:not(:checked) + label .uil {
  font-size: 22px;
}
.dropdown-sub:not(:checked) + label .uil {
  transition: transform 200ms linear;
}
.dropdown-sub:checked + label .uil {
  transform: rotate(135deg);
  transition: transform 200ms linear;
}
.dropdown-sub:checked + label:hover,
.dropdown-sub:not(:checked) + label:hover {
  color: #102770;
  background-color: #ffeba7;
}
.dark-light:checked ~ .sec-center .section-dropdown .for-dropdown-sub {
  color: #102770;
}
.dark-light:checked ~ .sec-center .section-dropdown .for-dropdown-sub:hover {
  color: #ffeba7;
  background-color: #102770;
}

.section-dropdown-sub {
  position: relative;
  display: block;
  width: 100%;
  pointer-events: none;
  opacity: 0;
  max-height: 0;
  padding-left: 10px;
  padding-right: 3px;
  overflow: hidden;
  transition: all 200ms linear;
}
.dropdown-sub:checked ~ .section-dropdown-sub {
  pointer-events: auto;
  opacity: 1;
  max-height: 999px;
}
.section-dropdown-sub a {
  font-size: 14px;
}
.section-dropdown-sub a .uil {
  font-size: 20px;
}
.logo {
  position: fixed;
  top: 50px;
  left: 40px;
  display: block;
  z-index: 11000000;
  background-color: transparent;
  border-radius: 0;
  padding: 0;
  transition: all 250ms linear;
}
.logo:hover {
  background-color: transparent;
}
.logo img {
  height: 26px;
  width: auto;
  display: block;
  transition: all 200ms linear;
}
.dark-light:checked ~ .logo img {
  filter: brightness(10%);
}

@media screen and (max-width: 991px) {
  .logo {
    top: 30px;
    left: 20px;
  }
  .dark-light:checked + label,
  .dark-light:not(:checked) + label {
    top: 20px;
    right: 20px;
  }
}

/*------------------------Sort Button---------------*/

/*------------------------Notfication SidebARSS---------------*/
body {
  font-family: "Poppins";
  margin: 0px;
  padding: 0px;
  background: #f2f1f8;
}

.nav-container {
  position: relative;
  display: inline-block;
  max-width: 50px;
  max-height: 50px;
  overflow: visible;
  outline: none;
}
.nav-container.is-active .nav-toggle:before,
.nav-container.is-active .nav-toggle:after {
  box-shadow: none;
}
.nav-container.is-active .nav-toggle:before {
  transform: rotate(-45deg);
}
.nav-container.is-active .nav-toggle:after {
  transform: rotate(45deg);
}
.nav-container.is-active .nav-items {
  transform: translate(0, 0);
}
.nav-container .nav-toggle {
  position: relative;
  width: 34px;
  height: 17px;
  margin: 10px;
  z-index: 2;
  margin-bottom: 9px;
}
.nav-container .nav-toggle:hover {
  cursor: pointer;
}
.nav-container .nav-toggle:before,
.nav-container .nav-toggle:after {
  content: "";
  position: absolute;
  top: 18px;
  left: 0;
  transform: translate(0, 0);
  width: 100%;
  height: 4px;
  background: black;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.nav-container .nav-toggle:before {
  box-shadow: 0 10px 0 0 black;
}
.nav-container .nav-toggle:after {
  box-shadow: 0 -10px 0 0 black;
}
.nav-container .nav-items {
  position: absolute;
  top: 0;
  left: 0;
  width: 285px;
  height: auto;
  z-index: 1;
  padding: 40px 20px 20px 10px;
  transition: transform 0.3s ease;
  transform: translate(calc(-100% - 350px), 0);
  /* background: #fff; */
  /* display: grid; */
  /* grid-template-columns: 1fr;
    grid-gap: 2px 0;
    align-content: start; */
  /* box-shadow: 0 0 50px rgba(0, 0, 0, 0.3); */
}
.nav-container .nav-items .nav-item {
  background: transparent;
  padding: 10px;
  transition: background-color 0.3s ease;
}
.nav-container .nav-items .nav-item:hover {
  cursor: pointer;
  background: transparent;
}
/*-------------------my code-----------*/

.leftnavbarboxmobile {
  background-color: #ffffff;
  width: 100%;
  height: auto;
  border-radius: 10px;
  margin-top: 15px;
  box-shadow: 0px 4px 4px 0px #0000000d;
  padding-bottom: 29px;
}

.imageflexleftnavbarmobile {
  display: flex;
  justify-content: center;
  column-gap: 20px;
  flex-direction: column;
  margin-top: 6px;
  align-items: center;
  padding-top: 0px;
}
.usernamenavbarmobile {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
  margin-right: 34px;
}

.usernamenavbarmobile h3 {
  margin-top: 0px;
  margin-bottom: 0px;
}

.idnamenamemobile {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 500;
  line-height: 10px;
  letter-spacing: 0em;
  text-align: center;
  color: #8e8e8e;
  margin-top: 10px;
}
.navigationbuttontopmobile {
  margin-top: 25px;
}
.navigatelinksmobile {
  display: flex;
  align-items: center;
  justify-content: start;
  column-gap: 10px;
  margin-left: 34px;
  margin-top: 10px;
}
.navigatelinksmobile:hover {
  cursor: pointer;
}

.navigatenamesmobile {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 6px;
  margin-bottom: 5px;
}

.navigatenamesmobile:hover {
  /* font-family: Poppins;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: 6px;
    margin-bottom: 5px; */
  color: #f00;
}
.mobileversionnavbarimagesizess {
  position: relative;
}
.mobileversionnavbarimagesizess img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
}
.mobilevrersionnamesize {
  font-size: 21px;
  margin-bottom: 6px;
  margin-top: 6px;
  text-align: center;
}
.mobilevrersionnamesize h3 {
  font-size: 21px;
  margin-bottom: 6px;
  margin-top: 6px;
  text-align: center;
}

.editiconinmobileversionbox img {
  width: 32px;
  height: 32px;
  object-fit: cover;
}
.editiconinmobileversionbox {
  position: absolute;
  top: 51px;
  left: 54px;
}

.belliiconofmobile {
  position: relative;
  margin-right: 27px;
  margin-top: 24px;
}

.belliiconofmobile img {
  width: 21px;
}

.notificationinmobileversionzx {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

/*-------------------my code-----------*/

/*------------------------Notfication SidebARSS---------------*/

/*----------------------------Media Query for SidebAR-----------*/
@media (max-width: 1000px) {
  .leftnavbarbox {
    display: none;
  }
}

@media (min-width: 1001px) {
  .mobileshowndesktophide {
    display: none;
  }
  .indexpagemobilehide {
    display: none;
  }
  .sortbuttondesktophide {
    display: none;
  }
}

/*----------------------------Media Query for SidebAR-----------*/

.DeleteCommentImage {
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
}

#PostPageTextEditorImg img {
  width: 100%;
  height: fit-content;
  margin-top: 10px;
}

.shareoptionsflexdicvv {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.texteditrsfordiv {
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 15px;
}
/*-------------Comments Mentionn area-------*/
.MentioningCommentsdiv {
  margin-left: 94px;
  padding-bottom: 30px;
}

.mentioncommentsflexdicvs {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.imageofthyementionedpersion {
  width: 30px;
  height: 30px;
}
.mentionedcommentionpersonname {
  font-size: 18px;
  margin-left: 12px;
  line-height: 34px;
}
@media (max-width: 1000px) {
  .MentioningCommentsdiv {
    margin-left: 55px;
    padding-bottom: 30px;
  }

  .mentioncommentsflexdicvs {
    display: flex;
    align-items: center;
  }
  .imageofthyementionedpersion {
    width: 20px;
    height: 20px;
  }
  .mentionedcommentionpersonname {
    font-size: 14px;
    margin-left: 12px;
    line-height: 34px;
  }
}
/*-------------Comments Mentionn area-------*/

/* ------------------popup tems and conditions -------------- */
/* MediaQuery For PopupVersion */
@media (max-width: 700px) {
  .polivyareaflxxexs {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  .butonpgthepopupo {
    background-color: #fff;
    border: none;
    padding: 5px;
    padding-left: 9px;
    padding-right: 9px;
    margin-right: 20px;
  }
  .mainbackgorundsgsforall {
    background-color: #f3f2f9;
    padding-bottom: 19px;
  }
  .Withofthepopup {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #f44336;
    text-align: center;
    padding-top: 20px;
  }
  .mainofthecontentoverflows {
    font-family: Poppins;
    font-size: 11px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    background-color: #fff;
    width: 270px;
    height: 130px;
    overflow: scroll;
    padding-bottom: 27px;
    padding-top: 10px;
    padding-left: 11px;
    padding-right: 10px;
    margin-top: 10px;
    border-radius: 7px;
  }
  .toggledatatreds {
    font-family: Poppins;
    font-size: 11px;
    font-weight: 400;
    line-height: 0px;
    letter-spacing: 0em;
    text-align: center;
    color: #f44336;
    margin-left: 0px;
  }
  .popupdasatas {
    width: 300px;
  }
  .popupflexwidth {
    display: flex;
    justify-content: center;
  }
  .tooglexswithchforpopouo {
    margin-left: 14px;
    margin-top: 14px;
    padding-bottom: 10px;
  }
  .disclaimertextpopup {
    font-size: 15px;
    margin-top: 10px;
  }
  .margtintipforumpoptip {
    margin-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .popuppppcontenetss {
    background: none !important;
    border: none !important;
  }
  .popup-content {
    background: none !important;
    border: none !important;
  }

  .VerifyOurEmail {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;
    color: #282828;
  }
  .An6DigitOtPhasbeen {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    color: #afafaf;
  }

  .regiswtrerpagenewpopotip {
    margin-left: 0px;
    margin-top: 10px;
    display: flex;
    justify-content: center;
  }

  .acceptfortheregisterbutton {
    margin-left: 93px;
    background-color: #2200ff;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 0px;
    letter-spacing: 0em;
    text-align: center;
    color: #fff;
    border: none;
    width: 133px;
    height: 28px;
    margin-top: 20px;
    border-radius: 5px;
    padding-bottom: 0;
  }
}
@media (min-width: 700px) and (max-width: 1001px) {
  .polivyareaflxxexs {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  .butonpgthepopupo {
    background-color: #fff;
    border: none;
    padding: 5px;
    padding-left: 9px;
    padding-right: 9px;
    margin-right: 20px;
  }
  .mainbackgorundsgsforall {
    background-color: #f3f2f9;
    padding-bottom: 19px;
  }
  .Withofthepopup {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #f44336;
    text-align: center;
    padding-top: 20px;
  }
  .mainofthecontentoverflows {
    font-family: Poppins;
    font-size: 11px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    background-color: #fff;
    width: 574px;
    height: 130px;
    overflow: scroll;
    padding-bottom: 27px;
    padding-top: 10px;
    padding-left: 11px;
    padding-right: 10px;
    margin-top: 10px;
    border-radius: 7px;
  }
  .toggledatatreds {
    font-family: Poppins;
    font-size: 11px;
    font-weight: 400;
    line-height: 0px;
    letter-spacing: 0em;
    text-align: center;
    color: #f44336;
    margin-left: 0px;
  }
  .popupdasatas {
    width: 600px;
  }
  .popupflexwidth {
    display: flex;
    justify-content: center;
  }
  .tooglexswithchforpopouo {
    margin-left: 14px;
    margin-top: 14px;
    padding-bottom: 10px;
  }
  .disclaimertextpopup {
    font-size: 15px;
    margin-top: 10px;
  }
  .margtintipforumpoptip {
    margin-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .popuppppcontenetss {
    background: none !important;
    border: none !important;
  }
  .popup-content {
    background: none !important;
    border: none !important;
  }

  .VerifyOurEmail {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;
    color: #282828;
  }
  .An6DigitOtPhasbeen {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    color: #afafaf;
  }

  .regiswtrerpagenewpopotip {
    margin-left: 32px;
    margin-top: 10px;
  }

  .acceptfortheregisterbutton {
    margin-left: 93px;
    background-color: #2200ff;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    color: #fff;
    border: none;
    width: 133px;
    height: 36px;
    margin-top: 20px;
    border-radius: 5px;
    padding-bottom: 0;
  }
}
@media (min-width: 1001px) and (max-width: 5000px) {
  .VerifyOurEmail {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;
    color: #282828;
  }
  .An6DigitOtPhasbeen {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    color: #afafaf;
  }

  .regiswtrerpagenewpopotip {
    margin-left: 204px;
    margin-top: 30px;
  }

  .acceptfortheregisterbutton {
    margin-left: 382px;
    background-color: #2200ff;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: #fff;
    border: none;
    width: 133px;
    height: 36px;
    margin-top: 20px;
    border-radius: 5px;
  }

  .polivyareaflxxexs {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  .butonpgthepopupo {
    background-color: #fff;
    border: none;
    padding: 5px;
    padding-left: 9px;
    padding-right: 9px;
    margin-right: 20px;
  }
  .mainbackgorundsgsforall {
    background-color: #f3f2f9;
    padding-bottom: 19px;
  }
  .Withofthepopup {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #f44336;
    text-align: center;
    padding-top: 20px;
  }
  .mainofthecontentoverflows {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    background-color: #fff;
    width: 1075px;
    height: 150px;
    overflow: scroll;
    padding-bottom: 30px;
    padding-top: 30px;
    padding-left: 25px;
    padding-right: 25px;
    margin-top: 30px;
    border-radius: 7px;
  }
  .toggledatatreds {
    font-family: Poppins;
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #f44336;
    margin-left: 10px;
  }
  .popupdasatas {
    width: 1143px;
  }
  .popupflexwidth {
    display: flex;
    justify-content: center;
  }
  .tooglexswithchforpopouo {
    margin-left: 20px;
    margin-top: 4px;
    padding-bottom: 10px;
  }
  .disclaimertextpopup {
    font-size: 16px;
    margin-top: 10px;
  }
  .margtintipforumpoptip {
    margin-top: 10px;
  }
  .popuppppcontenetss {
    background: none !important;
    border: none !important;
  }
  .popup-content {
    background: none !important;
    border: none !important;
  }
}

/* .mainbackgorundsgsforall {
  overflow: scroll;
} */

/* .rightpostbox {
  height: 120vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 20px;
} */

.loginpagessforgotpasswordtext {
  cursor: pointer;
}
.editimageprofilepicsabsolute {
  cursor: pointer;
}

@media (min-width: 1000px) {
  .myprofileforogtopassword {
    cursor: pointer;
  }
}

.Aroowrbuttonhovercursor {
  cursor: pointer;
}

.Accepetednamebedotreregsiter {
  background-color: #011ae3;
  border: none;
  color: #fff;
  height: 25px;
}

.NotificationButtonflexx {
  display: flex;
  align-items: center;
}

.ClearAllNotification {
  height: 30px;
  width: 200px;
  border: none;
  background-color: transparent;
  color: #2200ff;
  border-radius: 5px;
  font-size: 14px;
  border: 2px solid #2200ff;
}
.ClearAllNotification1 {
  height: 30px;
  width: 200px;
  border: none;
  background-color: #fff;
  color: #2200ff;
  border-radius: 5px;
  font-size: 14px;
  margin-left: 10px;
  /* border: 2px solid #fff; */
}

@media (min-width: 700px) and (max-width: 900px) {
  /*------------------------Sort Button---------------*/

  .sec-center {
    position: relative;
    z-index: 200;
  }

  .dark-light:checked + label,
  .dark-light:not(:checked) + label {
    position: fixed;
    top: 40px;
    right: 40px;
    z-index: 20000;
    display: block;
    border-radius: 50%;
    width: 46px;
    height: 46px;
    cursor: pointer;
    transition: all 200ms linear;
    box-shadow: 0 0 25px rgba(255, 235, 167, 0.45);
  }
  .dark-light:checked + label {
    transform: rotate(360deg);
  }
  .dark-light:checked + label:after,
  .dark-light:not(:checked) + label:after {
    position: absolute;
    content: "";
    top: 1px;
    left: 1px;
    overflow: hidden;
    z-index: 2;
    display: block;
    border-radius: 50%;
    width: 44px;
    height: 44px;
    background-color: #102770;
    background-image: url("https://assets.codepen.io/1462889/moon.svg");
    background-size: 20px 20px;
    background-repeat: no-repeat;
    background-position: center;
    transition: all 200ms linear;
    opacity: 0;
  }
  .dark-light:checked + label:after {
    opacity: 1;
  }
  .dark-light:checked + label:before,
  .dark-light:not(:checked) + label:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    overflow: hidden;
    z-index: 1;
    display: block;
    border-radius: 50%;
    width: 46px;
    height: 46px;
    background-color: #48dbfb;
    background-image: url("https://assets.codepen.io/1462889/sun.svg");
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: center;
    transition: all 200ms linear;
  }
  .dark-light:checked + label:before {
    background-color: #000;
  }
  .light-back {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: #fff;
    overflow: hidden;
    background-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/1462889/pat-back.svg");
    background-position: center;
    background-repeat: repeat;
    background-size: 4%;
    height: 100%;
    width: 100%;
    transition: all 200ms linear;
    opacity: 0;
  }
  .dark-light:checked ~ .light-back {
    opacity: 1;
  }
  .dropdown:checked + label,
  .dropdown:not(:checked) + label {
    display: flex;
    font-family: Poppins;
    font-weight: 500;
    font-size: 20px;
    line-height: 30;
    height: 40px;
    transition: all 200ms linear;
    border-radius: 4px;
    width: 102px;
    letter-spacing: 0px;
    display: -ms-inline-flexbox;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
    text-align: center;
    border: none;
    background-color: #2200ff;
    cursor: pointer;
    color: #ffffff;
    box-shadow: 0 12px 35px 0 rgba(255, 235, 167, 0.15);
  }
  .dark-light:checked ~ .sec-center .for-dropdown {
    background-color: #102770;
    color: #ffeba7;
    box-shadow: 0 12px 35px 0 rgba(16, 39, 112, 0.25);
  }
  .dropdown:checked + label:before,
  .dropdown:not(:checked) + label:before {
    position: fixed;
    top: 0;
    left: 0;
    content: "";
    width: 100%;
    height: 100%;
    z-index: -1;
    cursor: auto;
    pointer-events: none;
  }
  .dropdown:checked + label:before {
    pointer-events: auto;
  }
  .dropdown:not(:checked) + label .uil {
    font-size: 24px;
    margin-left: 10px;
    transition: transform 200ms linear;
  }
  .dropdown:checked + label .uil {
    transform: rotate(180deg);
    font-size: 24px;
    margin-left: 10px;
    transition: transform 200ms linear;
  }
  .section-dropdown {
    position: absolute;
    padding: 5px;
    background-color: #2200ff;
    top: 45px;
    left: 1px;
    width: 90px;
    border-radius: 4px;
    display: block;
    box-shadow: 0 14px 35px 0 rgba(9, 9, 12, 0.4);
    z-index: 2;
    opacity: 0;
    pointer-events: none;
    transform: translateY(20px);
    transition: all 200ms linear;
  }
  .dark-light:checked ~ .sec-center .section-dropdown {
    background-color: #fff;
    box-shadow: 0 14px 35px 0 rgba(9, 9, 12, 0.15);
  }
  .dropdown:checked ~ .section-dropdown {
    opacity: 1;
    pointer-events: auto;
    transform: translateY(0);
  }
  .section-dropdown:before {
    position: absolute;
    top: -20px;
    left: 0;
    width: 100%;
    height: 20px;
    content: "";
    display: block;
    z-index: 1;
  }
  .section-dropdown:after {
    position: absolute;
    top: -7px;
    left: 30px;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #111;
    content: "";
    display: block;
    z-index: 2;
    transition: all 200ms linear;
  }
  .dark-light:checked ~ .sec-center .section-dropdown:after {
    border-bottom: 8px solid #fff;
  }

  a {
    position: relative;
    color: #fff;
    transition: all 200ms linear;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 13px;
    border-radius: 2px;
    padding: 6px 0;
    padding-left: 3px;
    padding-right: 0px;
    margin: 2px 0;
    text-align: start;
    text-decoration: none;
    display: flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    justify-content: space-between;
    -ms-flex-pack: distribute;
  }
  .dark-light:checked ~ .sec-center .section-dropdown a {
    color: #102770;
  }
  a:hover {
    color: #102770;
    background-color: #ffeba7;
  }
  .dark-light:checked ~ .sec-center .section-dropdown a:hover {
    color: #ffeba7;
    background-color: #102770;
  }
  a .uil {
    font-size: 22px;
  }
  .dropdown-sub:checked + label,
  .dropdown-sub:not(:checked) + label {
    position: relative;
    color: #fff;
    transition: all 200ms linear;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 15px;
    border-radius: 2px;
    padding: 5px 0;
    padding-left: 20px;
    padding-right: 15px;
    text-align: left;
    text-decoration: none;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    justify-content: space-between;
    -ms-flex-pack: distribute;
    cursor: pointer;
  }
  .dropdown-sub:checked + label .uil,
  .dropdown-sub:not(:checked) + label .uil {
    font-size: 22px;
  }
  .dropdown-sub:not(:checked) + label .uil {
    transition: transform 200ms linear;
  }
  .dropdown-sub:checked + label .uil {
    transform: rotate(135deg);
    transition: transform 200ms linear;
  }
  .dropdown-sub:checked + label:hover,
  .dropdown-sub:not(:checked) + label:hover {
    color: #102770;
    background-color: #ffeba7;
  }
  .dark-light:checked ~ .sec-center .section-dropdown .for-dropdown-sub {
    color: #102770;
  }
  .dark-light:checked ~ .sec-center .section-dropdown .for-dropdown-sub:hover {
    color: #ffeba7;
    background-color: #102770;
  }

  .section-dropdown-sub {
    position: relative;
    display: block;
    width: 100%;
    pointer-events: none;
    opacity: 0;
    max-height: 0;
    padding-left: 10px;
    padding-right: 3px;
    overflow: hidden;
    transition: all 200ms linear;
  }
  .dropdown-sub:checked ~ .section-dropdown-sub {
    pointer-events: auto;
    opacity: 1;
    max-height: 999px;
  }
  .section-dropdown-sub a {
    font-size: 14px;
  }
  .section-dropdown-sub a .uil {
    font-size: 20px;
  }
  .logo {
    position: fixed;
    top: 50px;
    left: 40px;
    display: block;
    z-index: 11000000;
    background-color: transparent;
    border-radius: 0;
    padding: 0;
    transition: all 250ms linear;
  }
  .logo:hover {
    background-color: transparent;
  }
  .logo img {
    height: 26px;
    width: auto;
    display: block;
    transition: all 200ms linear;
  }
  .dark-light:checked ~ .logo img {
    filter: brightness(10%);
  }

  @media screen and (max-width: 991px) {
    .logo {
      top: 30px;
      left: 20px;
    }
    .dark-light:checked + label,
    .dark-light:not(:checked) + label {
      top: 20px;
      right: 20px;
    }
  }

  /*------------------------Sort Button---------------*/
}

@media (max-width: 700px) {
  .NotificationButtonflexx {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 3px;
  }

  .ClearAllNotification {
    height: 30px;
    width: 100px;
    border: none;
    background-color: transparent;
    color: #2200ff;
    border-radius: 4px;
    font-size: 9px;
    border: 2px solid #2200ff;
  }
  .ClearAllNotification1 {
    height: 30px;
    width: 100px;
    border: none;
    background-color: #fff;
    color: #2200ff;
    border-radius: 4px;
    font-size: 11px;
    margin-left: 10px;
    /* border: 2px solid #fff; */
  }
}

@media (min-width: 700px) and (max-width: 1200px) {
  .NotificationButtonflexx {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 3px;
  }

  .ClearAllNotification {
    height: 36px;
    width: 200px;
    border: none;
    background-color: transparent;
    color: #2200ff;
    border-radius: 4px;
    font-size: 14px;
    border: 2px solid #2200ff;
  }
  .ClearAllNotification1 {
    height: 36px;
    width: 200px;
    border: none;
    background-color: #fff;
    color: #2200ff;
    border-radius: 4px;
    font-size: 14px;
    margin-left: 10px;
    /* border: 2px solid #fff; */
  }
}

@media (min-width: 1100px) {
  .Lateudffbdfshfsffg {
    margin-left: 306px;
    margin-bottom: 30px;
  }
}
@media (max-width: 900px) {
  .Lateudffbdfshfsffg {
    margin-left: 20px;
    margin-bottom: 30px;
  }
}

@media (max-width: 1000px) {
  .Hareiohu324783247874y3 {
    font-size: 14px;
  }
  .share-modal {
    padding-left: 18px;
    padding-top: 9px;
  }
}

.Rewpoiee89349 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Reportdivimageidsji3 {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 6px;
  margin-right: 100px;
}
.r5r3f63fg3 {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #ff0000;
  height: 24px;
}
.imdgwg34344234 {
  height: 24px;
}

@media (min-width: 359px) and (max-width: 700px) {
  .Rewpoiee89349 {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .Reportdivimageidsji3 {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 3px;
    margin-right: 15px;
  }
  .r5r3f63fg3 {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    color: #ff0000;
    height: 11px;
  }
  .imdgwg34344234 {
    height: 17px;
  }
  .imdgwg34344234 img {
    width: 12px;
  }
}

@media (max-width: 359px) {
  .Rewpoiee89349 {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .Reportdivimageidsji3 {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 3px;
    margin-right: 15px;
  }
  .r5r3f63fg3 {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    color: #ff0000;
    height: 11px;
  }
  .imdgwg34344234 {
    height: 17px;
  }
  .imdgwg34344234 img {
    width: 12px;
  }
}

body.active-modal {
  overflow-y: hidden;
}

.modal-content h2 {
  font-weight: 500;
}

.btn-modal {
  padding: 10px 20px;
  display: block;
  margin: 100px auto 0;
  font-size: 18px;
}

.modal,
.overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
}

.overlay {
  background: rgba(49, 49, 49, 0.9);
}
.modal-content {
  position: absolute;
  top: 41%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background: #ffff;
  padding: 14px 28px;
  border-radius: 3px;
  width: auto;
  z-index: 8;
  height: 128px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
}

/* .overlay {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
 
  z-index: 10; 
} */

/* .modal {
  z-index: 6; 
  background: rgba(49, 49, 49, 0.9);
} */

/* .modal-content {
  position: fixed; 
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background: #fff; 
  padding: 14px 28px;
  border-radius: 3px;
  width: 403px;
  height: 150px;
  padding-left: 80px;
  padding-top: 30px;
} */

.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 7px;
  width: 20px;
  margin-top: 10px;
}

.lOGOUTbUTTONmOBILEVFRUHIDNFGIJG {
  width: 110px;
  height: 30px;
  border-radius: 5px;
  color: #fff;
  font-size: 15px;
  background-color: #2200ff;
  border: none;
  margin-left: 0px;
  margin-top: 0px;
}

.nOLogoutbutttreinxdnbutton {
  width: 110px;
  height: 30px;
  border-radius: 5px;
  color: #000;
  font-size: 15px;
  background-color: #f2f1f8;
  border: none;
  margin-left: 0px;
}

.tox .tox-editor-container {
  z-index: 0 !important;
}

@media (max-width: 1000px) {
  body.active-modal {
    overflow-y: hidden;
  }

  .modal-content h2 {
    font-weight: 500;
    font-size: 18px;
    text-align: center;
  }

  .btn-modal {
    padding: 10px 20px;
    display: block;
    margin: 100px auto 0;
    font-size: 18px;
  }

  .modal,
  .overlay {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    z-index: 7;
  }

  .overlay {
    background: rgba(49, 49, 49, 0.9);
  }
  .modal-content {
    position: absolute;
    top: 41%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #ffff;
    padding: 14px 28px;
    border-radius: 3px;
    width: 100%;
    z-index: 8;
    height: auto;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  /* .overlay {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: fixed;
   
    z-index: 10; 
  } */

  /* .modal {
    z-index: 6; 
    background: rgba(49, 49, 49, 0.9);
  } */

  /* .modal-content {
    position: fixed; 
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #fff; 
    padding: 14px 28px;
    border-radius: 3px;
    width: 403px;
    height: 150px;
    padding-left: 80px;
    padding-top: 30px;
  } */

  .close-modal {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px 7px;
    width: 20px;
    margin-top: 10px;
  }

  .lOGOUTbUTTONmOBILEVFRUHIDNFGIJG {
    width: 85px;
    height: 27px;
    border-radius: 3px;
    color: #fff;
    font-size: 13px;
    background-color: #2200ff;
    border: none;
    margin-left: 0px;
    margin-top: 0px;
  }

  .nOLogoutbutttreinxdnbutton {
    width: 85px;
    height: 27px;
    border-radius: 3px;
    color: #000;
    font-size: 13px;
    background-color: #f2f1f8;
    border: none;
    margin-left: 0px;
  }

  .tox .tox-editor-container {
    z-index: 0 !important;
  }
}

@media (max-width: 600px) {
  .nOTmOBILESpOPUPS {
    display: none;
  }
}

@media (min-width: 1000px) {
  .MobilePOpupS {
    display: none;
  }
}

.PtAGFLEXFORTHEFOREJHIODHJID {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 20px;
}

.toast-custom-style {
}
